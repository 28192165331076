var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"downloadCourseFirst"},[_vm._m(0),_c('div',{staticClass:"course_list"},[_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('li',[_c('div',{staticClass:"centerWidth"},[_vm._m(9),_c('p',{staticClass:"last_btn"},[_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.goAppStore}},[_vm._v(" 跳转App Store ")])]),_vm._m(10)])]),_vm._m(11)]),_vm._m(12)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"htd_tit"},[_c('h1',[_vm._v("創建新賬號下載hyperlinkVPN")]),_c('p',[_vm._v(" 僅需 1 分鐘，創建您的全新專屬“香港 Apple ID 賬號”，下載hyperlinkVPN 或者各種海外應用會更加方便！ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("01")]),_vm._v(" 進入 Apple 的創建 Apple ID 網站： "),_c('a',{attrs:{"href":"https://appleid.apple.com/account","target":"_blank"}},[_vm._v("https://appleid.apple.com/account")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-01.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("02")]),_c('span',{staticClass:"tit"},[_vm._v("根據提示輸入您的個人相關信息，註意在國家和地區請選擇“香港”，另外需註意兩點：")])]),_c('p',{staticClass:"annotation_p"},[_c('span',[_vm._v(" 郵箱：需要準備一個從未註冊過 Apple ID 的 "),_c('span',{staticClass:"red"},[_vm._v("“全新真實”")]),_vm._v(" 郵箱用來收取驗證碼；")])]),_c('p',{staticClass:"annotation_p"},[_c('span',[_vm._v("手機號：支持 "),_c('span',{staticClass:"red"},[_vm._v(" “中國大陸” ")]),_vm._v(" 的手機驗證；")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-02.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("03")]),_c('span',{staticClass:"tit"},[_vm._v("當您輸入所有的信息後，頁面會跳轉到 "),_c('span',{staticClass:"red"},[_vm._v("“郵箱驗證”")]),_vm._v(" ，您需要查詢您的郵箱，並將驗證 碼輸入在這裏。")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-03.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("04")]),_c('span',{staticClass:"tit"},[_vm._v("當您完成郵箱驗證後，頁面會跳轉到 "),_c('span',{staticClass:"red"},[_vm._v("“手機號驗證”")]),_vm._v(" 。您需要查看您註冊用的手機號碼 信息，將驗證碼輸入在這裏。")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-04.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("05")]),_c('span',{staticClass:"tit"},[_vm._v("當您成功輸入完以上信息後，頁面將跳轉到 "),_c('span',{staticClass:"red"},[_vm._v("“登錄和安全頁面”")]),_vm._v(" ，恭喜您，您的"),_c('span',{staticClass:"red"},[_vm._v("“香港 Apple ID”")]),_vm._v(" 已經註冊成功了。")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-05.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("06")]),_c('span',{staticClass:"tit"},[_vm._v("打開 App Store ，點擊 "),_c('span',{staticClass:"red"},[_vm._v("“右上角圖標”")]),_vm._v("； ")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-06.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("07")]),_c('span',{staticClass:"tit"},[_vm._v("選擇"),_c('span',{staticClass:"red"},[_vm._v("“退出登錄”")]),_vm._v(" ，記得一定要滑倒頁面底部才能看到 “退出登錄” 按鈕。 ")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-07.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("08")]),_c('span',{staticClass:"tit"},[_vm._v(" 在 App Store 中登錄您 "),_c('span',{staticClass:"red"},[_vm._v("“剛剛註冊的香港 Apple ID”")]),_vm._v(" ，當您輸入完 ID 和密碼之後， 請點擊下方的 "),_c('span',{staticClass:"red"},[_vm._v("“登錄”")]),_vm._v(" 按鈕，不要點擊右上角的 “完成” 按鈕。 ")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-08.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('span',{staticClass:"num center"},[_vm._v("最后一步")]),_c('span',{staticClass:"tit"},[_vm._v(" 當您發現商店內容字體變為"),_c('span',{staticClass:"red"},[_vm._v("繁體字")]),_vm._v(" 後證明您已經切換到香港地區了。此時 您可以點擊下方按鈕直接下載"),_c('span',{staticClass:"red"},[_vm._v("hyperlinkVPN")]),_vm._v(" ，或在商店中自行搜索 "),_c('span',{staticClass:"red"},[_vm._v("hyperlinkVPN")]),_vm._v("進行下載。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-09.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num center"},[_vm._v("温馨提示：")]),_c('span',{staticClass:"tit"},[_vm._v("新的 Apple ID 在首次下載軟件時，會要求設定 Apple ID 的一些信息， 如圖中所示，請點擊"),_c('span',{staticClass:"red"},[_vm._v("“檢查”")]),_vm._v(" ，然後按照提示逐步操作即可，需要註意 的是"),_c('span',{staticClass:"red"},[_vm._v("付款方式不要做任何選擇")]),_vm._v(" ，其它的內容可隨意填寫。 ")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-10.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/image/downloadCourseFirst/01-11.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"down_foot centerWidth"},[_c('p',{staticClass:"hint_p"},[_vm._v(" 下載完成hyperlinkVPN後，您可以隨時把 Apple ID "),_c('span',{staticClass:"red"},[_vm._v("切換回原來的中國大陸賬號")]),_vm._v(" ，當然您也可以在香港商店中下載各種海外熱門應用，hyperlinkVPN全部都能支持！ ")]),_c('p',{staticClass:"blue"},[_vm._v(" 如果您在下載過程中有遇到任何問題，請您隨時聯系在線客服，我們會有專業的客服團隊幫助您解決任何問題。 ")])])
}]

export { render, staticRenderFns }