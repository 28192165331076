var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(
      _vm.$route.path != '/login' &&
      _vm.$route.path != '/succeed' &&
      _vm.$route.path != '/register'
    )?_c('HeaderBar'):_vm._e(),(_vm.isRouterAlive)?_c('router-view'):_vm._e(),(
      _vm.$route.path != '/login' &&
      _vm.$route.path != '/memberCenter' &&
      _vm.$route.path != '/equipment' &&
      _vm.$route.path != '/purchaseHistory' &&
      _vm.$route.path != '/succeed' &&
      _vm.$route.path != '/register' &&
      _vm.$route.path != '/terminalRevenue'
    )?_c('FooterBar'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }