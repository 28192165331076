<template>
  <div>
    <div class="system">
      <div class="centerWidth">
        <ul>
          <li>
            <div class="top_pic" @click="equipmentChange('Android')">
              <img
                v-if="equipmentKey === 'Android'"
                src="../../../assets/image/index/icon-download-Android-on.png"
                alt=""
              />
              <img
                v-if="equipmentKey != 'Android'"
                src="../../../assets/image/index/icon-download-Android-off.png"
                alt=""
              />
              <p>{{ $t("lang.macDownload.Android_Download") }}</p>
            </div>
          </li>
          <li>
            <div class="top_pic" @click="equipmentChange('ios')">
              <img
                v-if="equipmentKey === 'ios'"
                src="../../../assets/image/index/icon-download-iOS-on.png"
                alt=""
              />
              <img
                v-if="equipmentKey != 'ios'"
                src="../../../assets/image/index/icon-download-iOS-off.png"
                alt=""
              />
              <p>{{ $t("lang.macDownload.iOS_Download") }}</p>
            </div>
          </li>
          <li>
            <div class="top_pic" @click="equipmentChange('mac')">
              <img
                v-if="equipmentKey === 'mac'"
                src="../../../assets/image/index/icon-download-Mac-on.png"
                alt=""
              />
              <img
                v-if="equipmentKey != 'mac'"
                src="../../../assets/image/index/icon-download-Mac-off.png"
                alt=""
              />
              <p>{{ $t("lang.macDownload.Mac_Download") }}</p>
            </div>
          </li>
          <li>
            <div class="top_pic" @click="equipmentChange('windows')">
              <img
                v-if="equipmentKey === 'windows'"
                src="../../../assets/image/index/icon-download-win-on.png"
                alt=""
              />
              <img
                v-if="equipmentKey != 'windows'"
                src="../../../assets/image/index/icon-download-win-off.png"
                alt=""
              />
              <P>{{ $t("lang.macDownload.Windows_Download") }}</P>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="mac_download">
      <div class="centerWidth">
        <div class="row ban_row" v-show="equipmentKey == 'Android'">
          <div class="col-md-6 left_description_download">
            <div class="description_con">
              <h1 class="app_h1">
                {{ $t("lang.macDownload.android_text.android_h1") }}
              </h1>
              <p class="app_p">
                <img
                  src="../../../assets/image/index/icon-home-Check.png"
                  alt=""
                />
                {{ $t("lang.macDownload.android_text.android_p1") }}
              </p>
              <p class="app_p">
                <img
                  src="../../../assets/image/index/icon-home-Check.png"
                  alt=""
                />
                {{ $t("lang.macDownload.android_text.android_p2") }}
              </p>
            </div>
            <div class="download_btn">
              <button
                type="button"
                class="btn free_btn btn-warning"
                @click="downloadApp('Androiddownload', '')"
              >
                {{ $t("lang.common.freeDownload") }}
              </button>
              <button type="button" class="btn outline_btn btn-outline-warning">
                <div class="qrCode">
                  <!-- <img src="../../../assets/image/index/ewm.png" alt="" /> -->
                  <img :src="versionData['android-qr']" alt="" />
                  <p>{{ $t("lang.macDownload.android_text.qrCode_text") }}</p>
                </div>
                {{ $t("lang.macDownload.android_text.android_code_down") }}
              </button>
            </div>
          </div>
          <div class="col-md-6 right_picture">
            <img
              v-if="$i18n.locale == 'zh-CN'"
              src="../../../assets/image/index/download-Android.png"
              alt=""
            />
            <img
              v-else
              src="../../../assets/image/index/download-Android-EN.png"
              alt=""
            />
          </div>
        </div>
        <div class="row ban_row" v-show="equipmentKey == 'ios'">
          <div class="col-md-6 left_description_download">
            <div class="description_con">
              <h1 class="app_h1">
                {{ $t("lang.macDownload.ios_text.ios_h1") }}
              </h1>
              <p class="app_p">
                <img
                  src="../../../assets/image/index/icon-home-Check.png"
                  alt=""
                />
                {{ $t("lang.macDownload.ios_text.ios_p1") }}
              </p>
              <p class="app_p">
                <img
                  src="../../../assets/image/index/icon-home-Check.png"
                  alt=""
                />
                {{ $t("lang.macDownload.ios_text.ios_p2") }}
              </p>
            </div>
            <div class="download_btn">
              <button
                type="button"
                class="btn btn-warning"
                @click="downloadApp('iosdownload', '')"
              >
                {{ $t("lang.macDownload.ios_text.ios_download") }}
              </button>
            </div>
          </div>
          <div class="col-md-6 right_picture">
            <img
              v-if="$i18n.locale == 'zh-CN'"
              src="../../../assets/image/index/download-iOS.png"
              alt=""
            />
            <img
              v-else
              src="../../../assets/image/index/download-ios-EN.png"
              alt=""
            />
          </div>
        </div>
        <div class="row ban_row" v-show="equipmentKey == 'mac'">
          <div class="col-md-6 left_description_download">
            <div class="description_con">
              <h1 class="app_h1">
                {{ $t("lang.macDownload.mac_text.mac_h1") }}
              </h1>
              <p class="app_p">
                <img
                  src="../../../assets/image/index/icon-home-Check.png"
                  alt=""
                />
                {{ $t("lang.macDownload.mac_text.mac_p1") }}
              </p>
              <p class="app_p">
                <img
                  src="../../../assets/image/index/icon-home-Check.png"
                  alt=""
                />
                {{ $t("lang.macDownload.mac_text.mac_p2") }}
              </p>
            </div>
            <div class="download_btn">
              <button
                type="button"
                class="btn btn-warning"
                @click="downloadApp('macdownload', 'amd64')"
              >
                {{ $t("lang.common.macAmdDownload") }}
              </button>
              <button
                type="button"
                class="btn btn-warning"
                @click="downloadApp('macdownload', 'arm64')"
                style="margin-left: 10px"
              >
                {{ $t("lang.common.macArmDownload") }}
              </button>
            </div>
          </div>
          <div class="col-md-6 right_picture">
            <img
              v-if="$i18n.locale == 'zh-CN'"
              src="../../../assets/image/index/download-Mac.png"
              alt=""
            />
            <img
              v-else
              src="../../../assets/image/index/download-Mac-EN.png"
              alt=""
            />
          </div>
        </div>
        <div class="row ban_row" v-show="equipmentKey == 'windows'">
          <div class="col-md-6 left_description_download">
            <div class="description_con">
              <h1 class="app_h1">
                {{ $t("lang.macDownload.win_text.win_h1") }}
              </h1>
              <p class="app_p">
                <img
                  src="../../../assets/image/index/icon-home-Check.png"
                  alt=""
                />
                {{ $t("lang.macDownload.win_text.win_p1") }}
              </p>
              <p class="app_p">
                <img
                  src="../../../assets/image/index/icon-home-Check.png"
                  alt=""
                />
                {{ $t("lang.macDownload.win_text.win_p2") }}
              </p>
            </div>
            <div class="download_btn">
              <button
                type="button"
                class="btn btn-warning"
                @click="downloadApp('windownload', 'amd64')"
              >
                {{ $t("lang.common.downloadNow") }}
              </button>
              <!--              <button-->
              <!--                type="button"-->
              <!--                class="btn btn-warning"-->
              <!--                @click="downloadApp('windownload','arm64')"-->
              <!--                style="margin-left: 10px;"-->
              <!--              >-->
              <!--                {{ $t("lang.common.downloadNow") }}-arm64-->
              <!--              </button>-->
            </div>
          </div>
          <div class="col-md-6 right_picture">
            <img
              v-if="$i18n.locale == 'zh-CN'"
              src="../../../assets/image/index/download-win.png"
              alt=""
            />
            <img
              v-else
              src="../../../assets/image/index/download-win-EN.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      equipmentKey: "Android",
      versionData: "",
    };
  },
  methods: {
    getversionNew() {
      this.$api.versionNew().then((res) => {
        if (res.data.code == "0") {
          this.versionData = res.data.data;
        }
      });
    },
    equipmentChange(key) {
      this.equipmentKey = key;
    },
    downloadApp(key, kernel) {
      if (key == "Androiddownload") {
        // window.open(
        //   "https://download.hyperlinkvpn.com/download/android/HyperlinkVPN-1.1.2.apk"
        // );
        window.open(this.versionData.android);
      } else if (key == "iosdownload") {
        if (this.$i18n.locale == "zh-CN") {
          this.$router.push("/downloadExplain");
        } else {
          window.open(
            "https://apps.apple.com/us/app/hyperlinkvpn/id1579801595"
          );
        }
      } else if (key === "macdownload") {
        if (kernel === "arm64") {
          // window.open(
          //   "https://download.hyperlinkvpn.com/download/darwin/arm64/HyperlinkVPN-1.0.1.dmg"
          // );
          window.open(this.versionData["darwin-arm64"]);
        } else {
          // window.open(
          //   "https://download.hyperlinkvpn.com/download/darwin/amd64/HyperlinkVPN-1.0.1.dmg"
          // );
          window.open(this.versionData["darwin-amd64"]);
        }
      } else if (key === "windownload") {
        window.open(this.versionData["windows-amd64"]);
        // window.open(
        //     "https://download.hyperlinkvpn.com/download/windows/amd64/HyperlinkVPN-1.0.1.exe"
        //   );
      }
    },
  },
  mounted() {
    this.getversionNew();
  },
};
</script>

<style lang="less" scoped>
.mac_download {
  background-image: url(../../../assets/image/index/download-bg.png);
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid #ccc;
  margin: 0 20px;
  padding-bottom: 20px;
  margin-top: 50px;
  .centerWidth {
    border-top: 1px solid #ccc;
  }
  .ban_row {
    position: relative;
  }

  .left_description_download {
    margin-top: 7%;

    .description_con {
      h1 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 34px;
      }

      p {
        font-size: 26px;
        line-height: 50px;
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
    }

    .download_btn {
      position: relative;
      top: 100px;

      button {
        width: auto;
        color: #fff;
        font-size: 24px;
      }
      a {
        text-decoration: none;
        color: #fff;
      }
      .free_btn {
        margin-right: 10px;
      }

      .outline_btn {
        color: #ffc107;
        border-width: 2px;
        position: relative;
        width: auto;

        .qrCode {
          position: absolute;
          bottom: 69px;
          right: -40px;
          width: 240px;
          background: #fff;
          border: 4px solid #ede9e9;
          padding: 20px 20px 0;
          border-radius: 20px;
          display: none;

          img {
            width: 90%;
          }

          p {
            font-size: 16px;
            text-align: center;
            color: #000;
            font-weight: bold;
            padding: 10px 20px;
          }
        }
      }

      button:hover,
      button:focus {
        background: rgba(239, 121, 12, 1);
        box-shadow: none;
      }

      .outline_btn:hover,
      .outline_btn:focus {
        color: #fff;
      }

      .outline_btn:hover {
        .qrCode {
          display: block;
        }
      }
    }

    .ios_download_btn {
      top: 0;
    }
  }

  .right_picture {
    margin-top: 5%;
    text-align: center;

    img {
      width: 620px;
    }
  }
}
.system {
  margin-top: 200px;

  ul {
    display: flex;
    justify-content: space-between;
    // width: 80%;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 0;
    li {
      .top_pic {
        cursor: pointer;

        img {
          width: 40px;
        }
      }

      p {
        font-weight: bold;
        font-size: 16px;
        margin: 0;
        margin-left: 20px;
      }
    }
  }
}
@media screen and(min-width: 992px) {
  .system ul {
    li {
      .top_pic {
        display: flex;
        align-items: center;
      }
    }
  }
}
@media screen and(max-width: 820px) {
  .system {
    margin-top: 100px !important;
    margin-bottom: 20px;
    ul {
      padding: 0 !important;
      li {
        .top_pic {
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
