<template>
  <div class="home" ref="home">
    <!-- banner -->
    <banner-index />
    <!-- 為什麽選擇 hyperlink VPN -->
    <div id="select-hyperlink"></div>
    <select-hyperlink />
    <!-- 下載 Android版 應用 -->
    <div id="MacDownload"></div>
    <MacDownload />

    <!-- 我们的价格 -->
    <!-- <div id="PriceIndex"></div> -->
    <!-- <PriceIndex /> -->

    <!-- 用户评价 -->
    <div id="UserEvaluation"></div>
    <UserEvaluation />
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
      center
      class="home_dialog"
      :class="$i18n.locale == 'zh-CN' ? '' : 'en_home_dialog'"
      :close-on-click-modal="false"
    >
      <div>
        <!-- <span>免費體驗時長 24 小时</span> -->
        <div class="rec_box">
          <p class="title">
            {{ $t("lang.Your_friend") }}
            <span class="origin">{{ shareUserName }}</span>
          </p>
          <p class="n_left">{{ $t("lang.Your_friend2") }}</p>

          <div class="card_box">
            <img
              class="bg-experience"
              src="@/assets/image/memberCenter/grade/bg-experience.png"
              alt=""
            />
            <img
              class="Stars"
              src="@/assets/image/memberCenter/grade/Stars.png"
              alt=""
            />
            <p class="Free_time">
              {{ $t("lang.hours_experience") }}
              <span class="free_num">24</span> {{ $t("lang.hours_1") }}
            </p>
            <div class="bot_free">
              <h4>{{ $t("lang.Exclusive") }}</h4>
              <p>
                {{ $t("lang.Successful") }}
                <br />
                {{ $t("lang.menubar_hours") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goregister">{{
          $t("lang.login.register")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="experienceCardFlag"
      width="30%"
      :show-close="false"
      center
      class="home_Card_dialog"
      :class="$i18n.locale == 'en-US' ? 'en_home_Card_dialog' : ''"
      :close-on-click-modal="!isExist ? true : false"
    >
      <div>
        <!-- <span>免費體驗時長 24 小时</span> -->
        <div class="card_box">
          <div class="title">
            <p>
              {{ $t("lang.Your_friend") }}<span>{{ shareUserName }}</span>
            </p>
            <p>{{ $t("lang.gives_15") }}</p>
          </div>
          <div class="card_pic">
            <!-- <img
              class="bg-experience pc_block"
              src="@/assets/image/memberCenter/grade/bg-card.png"
              alt=""
            /> -->
            <div class="card_15">
              <!-- pc_block -->
              <img
                class="bg-experience bg_pic"
                src="@/assets/image/memberCenter/grade/bg.png"
                alt=""
              />
              <!-- <img
                class="card_blank"
                src="@/assets/image/memberCenter/grade/card.png"
                alt=""
              /> -->
              <div class="card_tit">
                <img
                  class="card_blank"
                  src="@/assets/image/memberCenter/grade/card.png"
                  alt=""
                />
                <p class="vip15">{{ $t("lang.VIP_15") }}</p>
                <div class="card_day">
                  <p>
                    <span>15</span>
                    {{ $t("lang.common.day") }}
                  </p>
                </div>
              </div>
            </div>
            <!-- <img
              class="bg-experience app_block app_bg"
              src="@/assets/image/memberCenter/grade/VIP.png"
              alt=""
            /> -->
            <p class="pic_bot" v-if="isExist">
              <span>
                <img src="@/assets/image/memberCenter/grade/Check.png" alt="" />
                {{ $t("lang.No_Geographical") }}</span
              >
              <span>
                <img src="@/assets/image/memberCenter/grade/Check.png" alt="" />
                {{ $t("lang.Virtual_IP_Access") }}</span
              >
            </p>
            <p class="already_received" v-if="!isExist">
              {{ $t("lang.collected") }}
            </p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getmember" v-if="isExist">{{
          $t("lang.get")
        }}</el-button>
        <el-button type="primary" v-if="!isExist" @click="goMembers">{{
          $t("lang.More_Benefits")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import BannerIndex from "./children/bannerIndex.vue";
import SelectHyperlink from "./children/selectHyperlink.vue";
import MacDownload from "./children/macDownload.vue";
// import PriceIndex from "./children/priceIndex.vue";
import UserEvaluation from "./children/userEvaluation.vue";
export default {
  name: "HomeView",
  data() {
    return {
      dialogVisible: false,
      experienceCardFlag: false,
      isExist: false,
      shareUserName: "",
      shareEmail: "",
      alreadyReceived: true,
    };
  },
  components: {
    BannerIndex,
    SelectHyperlink,
    MacDownload,
    // PriceIndex,
    UserEvaluation,
  },
  methods: {
    checkCoupon() {
      let email = this.$route.query.email;
      this.$api.checkCoupon({ email: email }).then((res) => {
        if (res.data.code == "0") {
          this.shareUserName = res.data.data.userName;
          // this.experienceCardFlag = res.data.data.isExist;
          this.isExist = res.data.data.isExist;
        } else if (res.data.code == "2017") {
          this.$notify({
            type: "danger",
            message: res.data.message,
            color: "#F22F33",
            background: "#FFE8E8",
          });
        } else {
          this.$notify({
            title: this.$t("lang.alert.Error"),
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    getmember() {
      if (this.$route.query.email) {
        sessionStorage.setItem("shareEmail", this.$route.query.email);
      }
      let email = this.$route.query.email;
      // this.experienceCardFlag = false
      let token = sessionStorage.getItem("token");
      if (token) {
        this.$api.getshare({ email: email }).then((res) => {
          if (res.data.code == "0") {
            // this.experienceCardFlag = res.data.data.isExist;
            // this.isExist = res.data.data.isExist;
            // this.dialogVisible
            // this.alreadyReceived = false;
            sessionStorage.setItem("alreadyReceived", "no");
            sessionStorage.removeItem("shareEmail");
            this.$notify({
              title: this.$t("lang.alert.succeed"),
              type: "success",
              message: this.$t("lang.successfully"),
            });
          } else if (res.data.code == "2017") {
            // this.alreadyReceived = false;
            sessionStorage.setItem("alreadyReceived", "no");
            sessionStorage.removeItem("shareEmail");
            this.$notify({
              title: this.$t("lang.alert.warning"),
              type: "warning",
              message: res.data.message,
            });
          } else if (res.data.code == "2018") {
            this.$notify({
              title: this.$t("lang.alert.warning"),
              type: "warning",
              message: res.data.message,
            });
          } else {
            this.$notify({
              title: this.$t("lang.alert.Error"),
              type: "error",
              message: res.data.message,
            });
          }
          this.experienceCardFlag = false;
        });
      } else {
        this.$notify({
          title: this.$t("lang.alert.warning"),
          type: "warning",
          message: this.$t("lang.Account_no"),
        });
        this.$router.push("/login?email=" + this.$route.query.email);
      }
    },
    goMembers() {
      if (sessionStorage.getItem("token")) {
        this.$router.push("/memberCenter");
      } else {
        this.$router.push("/login?email=" + this.$route.query.email);
      }
    },
    goregister() {
      // sessionStorage.setItem("registerEmail", this.shareEmail);
      this.$router.push(
        "/register?shareUserName=" +
          this.shareUserName +
          "&shareEmail=" +
          this.shareEmail
      );
    },
  },
  mounted() {
    if (sessionStorage.getItem("alreadyReceived")) {
      this.alreadyReceived = sessionStorage.getItem("alreadyReceived");
    }
    if (this.$route.query.email && this.alreadyReceived != "no") {
      this.checkCoupon();
      this.experienceCardFlag = true;
      // sessionStorage.setItem("shareEmail", this.$route.query.email);
    }
    if (this.$route.query.shareUserName && this.$route.query.shareEmail) {
      this.shareUserName = this.$route.query.shareUserName;
      this.shareEmail = this.$route.query.shareEmail;
    }
    if (this.shareUserName) {
      this.dialogVisible = true;
    }
    if (this.$route.hash == "#MacDownload") {
      // this.$router.push("/#MacDownload")
      location.href = "#MacDownload";
      // this.reload()
    }
   
    // this.$children.navBar("MacDownload");
    // let token = sessionStorage.getItem("token");
    // if (token) {
    // }
  },
};
</script>
<style lang="less">
@import url("./index.less");
</style>
