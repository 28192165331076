<template>
  <div>
    <div class="succeed_box">
      <div class="success_ico_box">
        <img
          class="success_ico"
          src="@/assets/image/Payment_succeeded.png"
          alt=""
        />
        <p class="payment_success">
          {{ $t("lang.memberCenter.paymentSuccess") }}
        </p>
      </div>
      <p class="message">
        {{ $t("lang.memberCenter.emailTo") }} {{ userData.userEmail }}
      </p>
      <p class="message">
        {{ $t("lang.memberCenter.daseven_daysys") }}
        <span style="color: #000; font-weight: bold">{{
          $t("lang.memberCenter.Online_service")
        }}</span>
        <span>{{ $t("lang.memberCenter.apply_for") }}</span>
      </p>
    </div>
    <div class="go_home">
      <!-- <P>{{ $t("lang.memberCenter.refund") }}</P> -->
      <button @click="goHome">
        {{ $t("lang.memberCenter.Back_to_Home") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userData: {},
    };
  },
  methods: {
    goHome() {
      // let token = localStorage.getItem("tokenKey");
      // if (!token) {
      //   let ran = navigator.userAgent;
      //   let isAndroid =
      //     ran.indexOf("Android") > -1 || ran.indexOf("Linux") > -1;
      //   let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      //   if (isAndroid) {
      //     // 安卓代码块
      //     // window.Android.updateVipEndTime();
      //     window.Android.gotoVipInfo();
      //   } else if (isIOS) {
      //     // ios代码块
      //     window.prompt("gotoVipInfo");
      //   }
      // } else {
      // this.$router.push("/");
      // if (
      //   sessionStorage.getItem("returnAddress") != undefined &&
      //   sessionStorage.getItem("returnAddress") != null &&
      //   sessionStorage.getItem("returnAddress") != ""
      // ) {
      //   window.location.href =
      //     sessionStorage.getItem("returnAddress") + "#/myaccount";
      // } else {
      // window.location.href = "https://hyperlinkvpn.com/#/memberCenter";
      this.$router.push("/memberCenter")
      // }
      // window.location.href = "http://192.168.31.8:8081";
      // }
      // window.Android.gotoVipInfo();
    },
  },
  created() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
  },
};
</script>

<style lang="less" scoped>
.succeed_box {
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  .success_ico_box {
    margin-bottom: 38px;
  }
  p {
    margin: 0;
  }
  .success_ico {
    width: 72px;
  }
  .payment_success {
    font-size: 26px;
    color: rgb(70, 187, 68);
  }
  .message {
    font-size: 13px;
    margin-bottom: 5px;
    color: #666;
    width: 80%;
    margin: 5px auto;
    line-height: 25px;
  }
}
.go_home {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;

  p {
    font-size: 12px;
    color: #ccc;
    text-align: center;
    margin: 0;
  }
  button {
    width: 200px;
    background: #f7a403;
    border: 0;
    color: #fff;
    padding: 12px 0px;
    border-radius: 30px;
  }
}
</style>
