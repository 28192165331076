<template>
  <div class="download_page">
    <div class="centerWidth">
      <h1 class="ios_h1">iOS版本下載教程</h1>
      <div class="down_method">
        <p class="section_p">
          如果沒有非中國大陸地區 Apple ID，請按照下面方法操作:
        </p>
        <div class="row down_list_row">
          <router-link
            to="/downloadExplain/downloadCourseFirst"
            ta
            class="col-sm-6 dow_sm"
            tag="a"
            target="_blank"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <span class="num">01</span> 創建新賬號（強烈推薦）
                </h5>
                <p class="down_p">創建一個全新的“Apple ID”（美國/香港）</p>
              </div>
            </div>
          </router-link>
          <router-link
            to="/downloadExplain/downloadCourseSecond"
            class="col-sm-6 dow_sm"
            tag="a"
            target="_blank"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <span class="num">02</span> 修改賬號地區（推薦）
                </h5>
                <p class="down_p">修改當前的“Apple ID”到香港</p>
              </div>
            </div>
          </router-link>
          <router-link
            to="/downloadExplain/downloadCourseThirdly"
            class="col-sm-6 dow_sm"
            tag="a"
            target="_blank"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <span class="num">03</span> 使用公開賬號
                </h5>
                <p class="down_p">使用我們提供的香港公開賬號下載hyperlinkVPN</p>
              </div>
            </div>
          </router-link>
          <router-link
            to="/downloadExplain/commonProblem"
            class="col-sm-6 dow_sm"
            tag="a"
            target="_blank"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">常見問題</h5>
                <p class="down_p">下載過程中若遇見疑難雜症，到這裡看看吧</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="down_method">
        <div class="go_app_store">
          <p class="section_p">有非中國大陸的 Apple ID？</p>
          <p class="down_tit">
            在香港，美國和新加坡地區的 App Store 上搜索 “hyperlink VPN” 免費下載
          </p>
          <button
            type="button"
            class="btn btn-warning down_btn"
            @click="goAppStore"
          >
            跳转App Store
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goAppStore() {
      window.open("https://apps.apple.com/us/app/hyperlinkvpn/id1579801595");
    },
  },
  mounted() {
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="less" scoped>
.download_page {
  padding: 70px 0 180px;
  .ios_h1 {
    font-size: 50px;
    text-align: center;
    margin-bottom: 100px;
    font-weight: bold;
  }
  .down_method {
    .section_p {
      font-weight: 500;
      font-size: 32px;
    }
    .section_p::before {
      content: "";
      width: 8px;
      height: 30px;
      background: #f7a403;
      display: inline-block;
      position: relative;
      top: 3px;
      margin-right: 8px;
    }
    .down_list_row {
      padding: 52px 20px;
      .dow_sm {
        margin-bottom: 20px;
        cursor: pointer;
        color: #000;
        .card {
          border-radius: 20px;
          .card-body {
            padding: 40px;
            .card-title {
              font-size: 26px;
              font-weight: bold;
              .num {
                color: rgb(247, 164, 3);
                font-size: 28px;
                font-style: italic;
              }
            }
            .down_p {
              font-size: 18px;
              margin-top: 24px;
            }
          }
        }
      }
    }
    .go_app_store {
      .down_tit {
        font-size: 18px;
        margin: 28px 20px;
      }
      .down_btn {
        font-size: 24px;
        color: #fff;
        padding: 15px 54px;
        margin: 0px 20px;
        a {
          color: #fff;
        }
      }
      .down_btn:hover,
      .down_btn:focus {
        background: rgba(239, 121, 12, 1);
        box-shadow: none;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .download_page {
    padding: 40px 0 58px;
    .ios_h1 {
      font-size: 20px;
      margin-bottom: 33px;
    }
    .down_method {
      .section_p {
        font-size: 14px;
        margin: 0 20px;
        font-weight: bold;
      }
      .section_p::before {
        width: 4px;
        height: 18px;
      }
      .down_list_row {
        .dow_sm {
          .card {
            .card-body {
              padding: 20px;
              .card-title {
                font-size: 18px;
              }
              .down_p {
                font-size: 14px;
              }
            }
          }
        }
      }
      .go_app_store {
        .down_tit {
          font-size: 14px;
          margin: 28px 30px;
        }
        .down_btn {
          padding: 8px 15px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>