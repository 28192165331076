var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"downloadCourseSecond"},[_vm._m(0),_c('div',{staticClass:"course_list"},[_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('li',[_c('div',{staticClass:"centerWidth"},[_vm._m(9),_c('p',{staticClass:"last_btn"},[_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.goAppStore}},[_vm._v(" 跳转App Store ")])]),_vm._m(10)])]),_vm._m(11)]),_vm._m(12)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"htd_tit centerWidth"},[_c('h1',[_vm._v("修改賬號地區下載hyperlinkVPN")]),_c('p',[_vm._v(" 僅需 1 分鐘，將您的 Apple ID 切換到香港地區下載hyperlinkVPN， "),_c('span',{staticClass:"red"},[_vm._v("請註意，若您的賬號中存在訂閱內容，請您選擇其他方式！")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("01")]),_vm._v(" 登錄進入 Apple 官網 "),_c('a',{attrs:{"href":" https://appleid.apple.com","target":"_blank"}},[_vm._v(" https://appleid.apple.com")]),_vm._v(" ， 點擊 "),_c('span',{staticClass:"red"},[_vm._v(" “登錄”")]),_c('span',{staticStyle:{"margin-left":"40px"}},[_vm._v(" ，輸入自己的 Apple ID。")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-01.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("02")]),_c('span',{staticClass:"tit"},[_vm._v("登錄成功後點擊右上角下拉菜單中的 "),_c('span',{staticClass:"red"},[_vm._v("“個人信息”")]),_vm._v(" 選項。")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-02.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("03")]),_c('span',{staticClass:"tit"},[_vm._v("在個人信息頁面點擊選擇 "),_c('span',{staticClass:"red"},[_vm._v("“國家或地區”")]),_vm._v(" 選項。")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-03.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("04")]),_c('span',{staticClass:"tit"},[_vm._v("在國家或地區頁面選擇 "),_c('span',{staticClass:"red"},[_vm._v("“更改國家或地區”")]),_vm._v("。 若提示無法更改國家或地區，請使 用其他方式下載。")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-04.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("05")]),_c('span',{staticClass:"tit"},[_vm._v("此時您已經進入到付款方式頁面，請按照下述描述填寫相關信息： ")])]),_c('p',{staticClass:"annotation_p"},[_c('span',[_vm._v(" 國家或地區：香港。")])]),_c('p',{staticClass:"annotation_p"},[_c('span',[_vm._v(" 付款方式：無。")])]),_c('p',{staticClass:"annotation_p"},[_c('span',[_vm._v(" 賬單寄送地址：姓氏，名字，街道地址可以隨意填寫。")])]),_c('p',{staticClass:"annotation_p"},[_c('span',[_vm._v(" 電話號碼：852 + 任意 8 位數字。")])]),_c('p',{staticClass:"annotation_p"},[_c('span',[_vm._v(" 配送地址：姓氏，名字，街道地址可以隨意填寫，國家或地區選擇香港。")])]),_c('p',{staticClass:"annotation_last_p"},[_c('span',[_vm._v("填寫完畢後，最後點擊最底部的 “更新” 按鈕即可。 "),_c('span',{staticClass:"red"},[_vm._v(" 若付款方式無法選擇 “無”，請使用其他方式下載。")])])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-05.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("06")]),_c('span',{staticClass:"tit"},[_vm._v("打開 App Store ，點擊"),_c('span',{staticClass:"red"},[_vm._v("“右上角圖標”")]),_vm._v("； ")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-06.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("07")]),_c('span',{staticClass:"tit"},[_vm._v("選擇"),_c('span',{staticClass:"red"},[_vm._v("“退出登錄”")]),_vm._v(" 按鈕，記得一定要滑到頁面底部才能看到這個按鈕。 ")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-07.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num"},[_vm._v("08")]),_c('span',{staticClass:"tit"},[_vm._v(" 在 App Store 中重新登錄您的 Apple ID，輸入 ID 和密碼之後，請點擊下方 "),_c('span',{staticClass:"red"},[_vm._v("“登錄”")]),_vm._v(" 按鈕，不要點擊右上角的完成。 ")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-08.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('span',{staticClass:"num center"},[_vm._v("最后一步")]),_c('span',{staticClass:"tit"},[_vm._v(" 當您發現商店內容字體變為"),_c('span',{staticClass:"red"},[_vm._v("繁體字")]),_vm._v(" 後證明您已經切換到香港地區了。此時 您可以點擊下方按鈕直接下載"),_c('span',{staticClass:"red"},[_vm._v("hyperlinkVPN")]),_vm._v(" ，或在商店中自行搜索 "),_c('span',{staticClass:"red"},[_vm._v("hyperlinkVPN")]),_vm._v("進行下載。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-09.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"centerWidth"},[_c('h4',[_c('span',{staticClass:"num center"},[_vm._v("温馨提示：")]),_c('span',{staticClass:"tit"},[_vm._v("新的 Apple ID 在首次下載軟件時，會要求設定 Apple ID 的一些信息， 如圖中所示，請點擊"),_c('span',{staticClass:"red"},[_vm._v("“檢查”")]),_vm._v(" ，然後按照提示逐步操作即可，需要註意 的是"),_c('span',{staticClass:"red"},[_vm._v("付款方式不要做任何選擇")]),_vm._v(" ，其它的內容可隨意填寫。 ")])]),_c('div',{staticClass:"course_pic"},[_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-10.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/image/downloadCourseSecond/02-11.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"down_foot centerWidth"},[_c('p',{staticClass:"hint_p"},[_vm._v(" 下載完成hyperlinkVPN後，您可以隨時把 Apple ID "),_c('span',{staticClass:"red"},[_vm._v("切換回原來的中國大陸賬號")]),_vm._v(" ，當然您也可以在香港商店中下載各種海外熱門應用，hyperlinkVPN全部都能支持！ ")]),_c('p',{staticClass:"blue"},[_vm._v(" 如果您在下載過程中有遇到任何問題，請您隨時聯系在線客服，我們會有專業的客服團隊幫助您解決任何問題。 ")])])
}]

export { render, staticRenderFns }