export const lang = {
  // 公共
  common: {
    downloadNow: "立即下載",
    freeDownload: "免費下載",
    day: '天',
    hours: "小時",
    Less_than_an_hour: "剩餘不足1小時",
    surplus: "剩餘",
    Hot: "推薦",
    days: "天",
    month: "月",
    macAmdDownload: "立即下載-Intel版本",
    macArmDownload: "立即下載-M1版本",

  },
  // 头部文字
  navBar: {
    Home: "首頁",
    Advantage: "產品優勢",
    Download: "下載",
    Price: "價格",
    Evaluate: "用戶評價",
  },
  login: {
    Sign_in: '登錄',
    welcome: '連接世界探索新鮮事...',
    mailbox: '郵箱',
    password: '密碼',
    register: '立即註冊',
    forgetPassword: '忘記密碼',
    register_hyperlink: '註冊hyperlink賬戶',
    client_side_register: '暫時不支持在網站上註冊賬戶，請您在客戶端中進行註冊，如果沒有客戶端請返回官網首頁下載',
    go_home: '返回首頁',
    gologin: '去登錄',
    Have_account: '已有賬戶，',
    client_side_password: ' 您可以在客户端的账户管理中查看自己的用户名及密码，如果没有客户端请先返回官网首页下载。',
    login_successfully: "登錄成功"


  },
  banner: {
    bannerTile: "連接世界探索新鮮事..",
    bannerP0: "突破地域限制，獲取您喜愛的節目和應用程序",
    bannerP1: "虛擬 IP 訪問，告別審查和限制",
  },
  selectHyperlink: {
    whyChoose: "為什麽選擇 hyperlink VPN",
    Choose_tit1: "解除地域限制",
    Choose_text1:
      "在任何地區都能流暢使用Google、 Facebook、YouTube、NETFLIX等服務",
    Choose_tit2: "隱匿行蹤",
    Choose_text2:
      " 虛擬 IP 匿名訪問互聯網，加密連接後的所有操作都會是 100% 匿名且不可追溯的，因此您的私人數據將受到有力的保護",
    Choose_tit3: "在任何設備上使用",
    Choose_text3:
      "iOS、Android、Windows、Mac設備支持，連接 VPN 只需一個開關即可，如同用電飯煲煮飯一樣簡單。",
    Choose_tit4: "超快專線",
    Choose_text4:
      "5個國家100臺服務器，所有服務器網絡都執行了專利加速技術，更有超快 IPLC 專線， 不限帶寬，無限流量，讓您可以流暢快速地瀏覽與傳輸。",
    Choose_tit5: "真人客服，永不失聯",
    Choose_text5:
      "提供 24 小時在線服務，100%真人客服與網絡工程師隨時待命，為您上網體驗保駕護航，永不失聯的 VIP 服務。",
    Choose_tit6: "保護您的數據",
    Choose_text6:
      "您的所有互聯網訪問都可以通過我們最高級別的安全 ECC 加密技術得到保護。我們不會跟蹤、收集或共享您的私人數據。",
  },
  macDownload: {
    android_text: {
      android_h1: "下載 Android版 應用",
      android_p1: "Android 上快速安全的訪問互聯網",
      android_p2: "適適用於 Android 6.0或更高版本",
      android_code_down: "掃碼下載",
      qrCode_text: "打開手機掃描二維碼 立即下載APP",
    },
    ios_text: {
      ios_h1: "下載 ios 版應用",
      ios_p1: "iOS 上快速安全的訪問互聯網",
      ios_p2: "適用於 iOS 11或更高版本",
      ios_p3: "App Store 不支持部分地區下載hyperlinkVPN，",
      ios_p4: "請修改您的 Apple 賬戶地區後，再搜索 ",
      ios_p5: "“ hyperlinkVPN ” 下載應用",
      ios_download: "免費下載",
    },
    mac_text: {
      mac_h1: "下載 Mac 版應用",
      mac_p1: "Mac 上快速安全的訪問互聯網",
      mac_p2: "適用於 macOS Sierra 10.12或更高版本",
    },
    win_text: {
      win_h1: "下載 Windows 版應用",
      win_p1: "Windows 上快速安全的訪問互聯網",
      win_p2: "適用於 Win7 /Win8 /Win10 /Win11",
    },
    Android_Download: "Android下載",
    iOS_Download: "iOS下載",
    Mac_Download: "Mac下載",
    Windows_Download: "Windows下載",
  },
  Price: {
    myPrice: "我們的價格",
    day: "天",
    month: "個月",
    months: "個月",
    just: "只需",
    save: "節省",
    preday: "每天",
    premonth: "每月",
    guarantee: "七天無理由退款",
    price_fot: {
      fot1: "全平臺支持",
      fot2: "支持3臺設備",
      fot3: "4k視頻、無限流量",
      fot4: "7*24小時客服",
      fot5: "10個國家地區的超高速服務器",
    },
  },
  userEvaluation: {
    userEvaluationTile: "用戶評價",
  },
  memberCenter: {
    sign_out: '退出登錄',
    RenewMembership: '續費會員',
    Device_management: '設備管理',
    paymentMethod: '支付方式',
    Validity: '有效期：',
    due_date: '有效期：',
    days_remaining: '天',
    Expires_on_October: '到期',
    Total_order: '訂單總額',
    payment: '付款',
    have_expired: '已過期',
    recommend: "推薦",
    paymentSuccess: "支付成功",
    emailTo: "賬單已發至",
    daseven_daysys: "如需7天無理由退款請在 ",
    Online_service: " 在綫客服 ",
    apply_for: "申請",
    Back_to_Home: "查看我的賬戶",
    refund: "注：7天套餐不支持7天退款。",
    expire: '到期',
    No_Discount: '暫未獲得優惠',
    First_time: "首次訂閱可領2張15天VIP分享券",
    discounts: '已獲得58%優惠',
    repurchase1: "再購買",
    repurchase2: "個月會員可升級",
    repurchase3: "個月會員可升級",
    discounts62: '已獲得62%優惠',
    discounts65: '已獲得65%優惠',
    Reached_max_level: "已達到最高等級",
    Member_Price: "會員價格",
    VIP: "尊貴的Lv",
    VIP2: '用戶，已為您優惠',
    VIP3: '',
    low_price: '低至',
    noe_Month_VIP: '1個月VIP會員',
    Month_VIP_15: '15天VIP會員',
    nonparticipation: "該會員不參與送分享券活動",


    old_user: '老用戶回饋價，僅限LV',
    old_user2: '會員訂閱使用',
    Share_Coupon: '送分享券',
    give_coupon: '首次订阅额外送2张15天VIP好友分享券！',
    Total_order_price: '訂單總價：',
    Subscribe_VIP: '訂閱VIP會員',
    unpaid: '您有筆會員訂單待支付',
    oldpayDataDay: 'VIP會員，合計費用',
    oldpayDataDay2: '15天VIP會員，合計費用',
    Cancel: '取消',
    Payment: '支付',
    Save: '已優惠',
    My_VIP_Share: '我的VIP分享券',
    give_friends: '贈送好友15天VIP會員',
    Share: '分享',
    Congratulations: '訂閱成功！',
    Get_share: '恭喜您獲得分享券',
    zhang: '張',
    share_look: '隨後可在福利獎勵中查看使用',

    payment_failure: "訂單支付失敗，請重新支付",

    Order_Price_Conversion: "訂單價格換算",
    Not_the_dollar: "檢測到您所在的地區常用貨幣非美元",
    membership1: "會員體驗套餐限時領取",
    membership2: "",
    day3vip: "天VIP會員",
    not_refund: "暫不支持退款服務",
    not_Chinese: "!暫不支持非中國大陸用戶",

  },
  equipment: {
    my_equipment: "我的設備",
    Remove: "下線",
    no_equipment: "暫無設備",
    Current_equipment: "當前設備",
    Offline_equipment: "已發送驗證碼至您的郵箱，驗證後下線設備",
    verification_code: "驗證碼已發送至",
    resend: "重發",
    cancel: "取 消",
    Offline_equipment_text: "下綫設備",
    offline_successfully: "設備下綫成功",
    Please_correct_code: "請輸入正確的驗證碼",
    Please_code: "請輸入驗證碼",

  },
  purchaseHistory: {
    Purchase: "最近購買記錄",
    Purchase_Time: "購買時間："
  },
  terminalRevenue: {
    Invitation: "邀請碼：",
    Recommended: "推薦人數",
    Accumulated_income: "累計收益",
    RMB: "元",
    people: "人",
    start_date: "開始日期",
    ending_date: "結束日期",
    to: "至",
    one_week: "最近一周",
    one_month: "最近一個月",
    three_months: "最近三個月",

  },
  InvitationLookup: {
    PleaseCode: "請輸入邀請碼",
    search:"搜索"

  },
  footerBar: {
    About_Us: "關於我們",
    Privacy_Policy: "隱私政策",
    Terms: "服務條款",
    Facebook: "Facebook",
    Twitter: "Twitter",
    //下載
    download: "下載",
    // 帮助中心
    help: "幫助中心",
    // 支付方式
    Payment: "支付方式",
    // 最底部
    footer: "© 2022 HyperlinkVPN. 保留所有權.",
  },
  alert: {
    setMeal: "請選擇訂單套餐",
    payment: "請選擇支付方式",
    not_return: "支付未返回,請聯係相關人員！",
    network_anomaly: "服務異常！",
    succeed: '成功',
    Error: '錯誤',
    warning: '警告',
    Cancel_Successful: '訂單取消成功',
    Service_error: "服務錯誤！",
    Must_not_be_empty: "賬號或密碼不得爲空！",
    Exit_successfully: "退出成功",
    network_anomaly1: "網絡異常，請查看網絡設置!",
    Login_invalidation: "登錄失效，請重新登錄"

  },
  langSel: {
    languageChoice: "語言選擇",
    lang_Device_management: "設備管理",
    Mymember: "我的會員",
    sign_out: '退出登錄',
    sets: "台"

  },
  Your_friend: '您的好友',
  Your_friend2: '向您推荐hyperlinkVPN',
  hours_experience: '免費體驗時長',
  hours_1: '小時',
  Exclusive: '送您新人專享會員',
  Successful: '新用戶註冊成功送您24小時免費體驗',
  menubar_hours: '會員時長',
  gives_15: '送你15天會員，快來一起體驗吧！',
  No_Geographical: '突破地域限制',
  Virtual_IP_Access: '虚拟IP访问',
  get: '立即领取',
  collected: "該券已被領取完",
  More_Benefits: '查看更多福利',
  register: '註冊',
  register_text: '歡迎創建Hyperlink賬號',

  signup_main_01: '確認密碼',
  signup_main_02: '與上面密碼保持一致',
  signup_main_03: '我已閱讀並同意遵守',
  signup_main_04: '《服務條款》',
  signup_main_05: '邀請碼（選填）',


  login_main_07: "必須含有字母和數字且超過8個字符，允許特殊字符：!@#$%^&*-_=+.",
  login_main_08: '郵箱或密碼不得為空！',

  login_main_06: "郵箱格式不正確",

  emailCode_main_0: "郵件發送成功",
  emailCode_main_01: "發送驗證碼", //
  emailCode_main_02: "已發送驗證碼至您的郵箱，當前驗證碼截止11:30前輸入有效",
  emailCode_main_03: "'請點擊“發送”獲取新驗證碼'",
  emailCode_main_04: "驗證碼已經失效，請點擊“發送”獲取驗證碼",
  emailCode_main_05: "驗證碼輸入有誤請查看重輸，當前驗證碼截止11:30前輸入有效! 或點擊“發送”獲取新驗證碼",
  emailCode_main_06: "特殊提醒",
  emailCode_main_07: "沒有收到郵件？",
  emailCode_main_08: "1.檢查您郵箱中的垃圾箱；",
  emailCode_main_09: "2.若任然沒有找到郵件，請嘗試重新“獲取驗證碼。",
  emailCode_main_10: "請先勾選服務條款",
  emailCode_main_11: "驗證碼不得爲空",
  emailCode_main_12: "註冊成功！",
  emailCode_main_13: "驗證碼獲取失敗，稍後請嘗試重新獲取驗證碼",
  emailCode_main_14: "服務條款",
  emailCode_main_15: "註冊需要您閱讀並同意遵守《服務條款》",
  emailCode_main_16: "同意",
  emailCode_main_17: "取消",
  emailCode_main_18: "註冊成功後",
  emailCode_main_19: "免費體驗24小時",


  Sharing_friends_tit: "已復制粘貼板，可以去分享給好友了!",
  Account_no: "賬號未登錄，請先完成登錄後領取！",
  successfully: '福利券領取成功',
  email_format: "符合郵箱格式",

  characters_8: "含有字母和數字且超過8個字符",
  Return_Login: "返回登錄",

  VIP_15: "VIP会员15天体验卡",

  step: "步驟",
  go_back: '返回',
  verify: "驗證",
  code_5: "請及時查閱郵箱，輸入5位驗證碼",
  login_main_code: "驗證碼",
  resend: "重發",
  yes: "確認",
  consent: "同意",
  disagree: "不同意，退出",
  Downloading_APP: '下載APP'
};
