<template>
  <div class="equipment">
    <LeftMember :userData="userData" />
    <div class="right_equipment_management">
      <h3>{{ $t("lang.equipment.my_equipment") }}</h3>
      <div class="equi_box">
        <!-- <p v-if="deviceList.length <= 0" class="no_equipment">
          {{ $t("lang.equipment.no_equipment") }}
        </p> -->
        <ul>
          <li>
            <div>
              <img
                v-if="mobileInfos.platform == 'window'"
                src="../../assets/image/memberCenter/computer.jpg"
                alt=""
              />
              <img
                v-else
                src="../../assets/image/memberCenter/phone.jpg"
                alt=""
              />
              <!-- computer.jpg -->
              <span
                >{{ mobileInfos.device }}
                <span class="blue"
                  >（{{ $t("lang.equipment.Current_equipment") }}）</span
                ></span
              >
            </div>
          </li>
          <li v-for="(item, i) in deviceList" :key="i">
            <div>
              <img v-if="item.equipmentType=='windows' || item.equipmentType=='darwin'" src="../../assets/image/memberCenter/computer.jpg" alt="" />
              <img v-else src="../../assets/image/memberCenter/phone.jpg" alt="" />
              <span>{{ item.equipmentName }}</span>
            </div>
            <!-- @click="getcode(item.equipmentToken, item.equipmentName)" -->
            <div
              class="out_btn"
              v-if="item.equipmentToken != token"
              @click="getcode(item.equipmentToken, item.equipmentName)"
            >
              <span>
                {{ $t("lang.equipment.Remove") }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="reSendEmail">
      <el-dialog
        title=""
        :show-close="false"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
        :destroy-on-close="true"
        :close-on-click-modal="false"
      >
        <h5 class="email_tit">
          {{ $t("lang.equipment.Offline_equipment") }}
          {{ equipmentName }}
        </h5>
        <p>
          {{ $t("lang.equipment.verification_code")
          }}<span class="blue">{{ userEmail }}</span>
        </p>
        <p class="code_box">
          <input
            type="text"
            class="form-control code_inpt"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            :placeholder="$t('lang.equipment.Please_code')"
            :maxlength="5"
            v-model="verifyCode"
            :style="error_msg != -'' ? 'border-color:red' : ''"
          />
          <span class="second" v-show="!isShow">{{ count }}s</span>
          <a
            class="getcode"
            href="javascript:;"
            v-show="isShow"
            @click="getcode(equipmentToken, equipmentName)"
            >{{ $t("lang.equipment.resend") }}</a
          >
        </p>
        <p style="color: red; margin: 0" v-if="error_msg != -''">
          {{ error_msg }}
        </p>
        <p slot="footer" class="dialog-footer">
          <el-button class="cancel" @click="handleClose">{{
            $t("lang.equipment.cancel")
          }}</el-button>
          <el-button
            slot="reference"
            type="primary"
            @click="tapeout"
            class="tape_out"
            >{{ $t("lang.equipment.Offline_equipment_text") }}</el-button
          >
        </p>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import LeftMember from "@/components/LeftMember.vue";
import { mobileInfo } from "@/utils/common";
export default {
  components: { LeftMember },
  data() {
    return {
      userData: JSON.parse(sessionStorage.getItem("userData")),
      deviceList: [],
      dialogVisible: false,
      // isShow: false,
      count: 0,
      equipmentName: "",
      userEmail: "",
      settime: "",
      isShow: false,
      verifyCode: "",
      equipmentToken: "",
      error_msg: "",
      token: "",
      mobileInfos: "",
    };
  },
  mounted() {
    this.getdeviceList();
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
    }
    this.mobileInfos = mobileInfo();
  },
  methods: {
    getdeviceList() {
      this.$api.deviceList().then((res) => {
        this.token = localStorage.getItem("token");
        localStorage.setItem("deviceList_length", res.data.data.devices.length);
        this.deviceList = res.data.data.devices;
      });
    },
    handleClose() {
      this.dialogVisible = false;
      clearInterval(this.settime);
      this.verifyCode = "";
      this.error_msg = "";
    },
    // 下线
    offline() {
      // this.equipmentToken = equipmentToken;
      this.$api.offline({ equipmentToken: this.equipmentToken }).then((res) => {
        if (res.data.code == "0") {
          this.$notify({
            title: this.$t("lang.alert.succeed"),
            message: res.data.message,
            type: "success",
          });
          this.getdeviceList();
          this.handleClose();
        } else {
          this.$notify({
            title: this.$t("lang.alert.Error"),
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    //获取验证码
    getcode(equipmentToken, equipmentName) {
      if (equipmentToken) {
        this.equipmentToken = equipmentToken;
      }
      if (equipmentName) {
        this.equipmentName = equipmentName;
      }
      this.userEmail = JSON.parse(sessionStorage.getItem("userData")).userEmail;
      // this.offline();
      this.dialogVisible = true;
      this.$api
        .reSendEmail({
          userEmail: this.userEmail,
          verifyType: "offline",
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.codeTime();
          }
        });

      // this.isShow = false; //倒计时
    },
    // 校验验证码
    tapeout() {
      if (this.verifyCode != "") {
        this.$api
          .verifyEmail({
            verifyCode: this.verifyCode.toUpperCase(),
            userEmail: this.userEmail,
            verifyType: "offline",
          })
          .then((res) => {
            if (res.data.code == "0") {
              this.offline();
            } else {
              this.error_msg = res.data.message;
            }
          });
      } else {
        this.error_msg = this.$t("lang.equipment.Please_correct_code");
      }
    },
    // 倒计时
    codeTime() {
      this.isShow = false; //倒计时
      this.count = 60; //赋值3秒
      this.settime = setInterval(() => {
        this.count--; //递减
        if (this.count <= 0) {
          this.count = 60;
          this.isShow = true; //倒计时

          clearInterval(this.settime);
        }
      }, 1000); //1000毫秒后执行
    },
  },
};
</script>

<style lang="less" scoped>
.equipment {
  height: calc(100% - 80px);
  border-top: 1px solid #ccc;

  .right_equipment_management {
    width: calc(100% - 354px);
    display: inline-block;
    vertical-align: top;
    padding: 0 48px;
    position: relative;
    left: 354px;
    h3 {
      padding: 52px 0 30px 0;
      font-weight: bold;
    }
    .equi_box {
      .no_equipment {
        padding: 20px 0;
        border-bottom: 1px solid #ccc;
        color: #999;
      }
      ul {
        background: rgb(250, 250, 253);
        padding: 57px 24px 65px;
        li {
          display: flex;
          justify-content: space-between;
          padding: 30px 0;
          border-bottom: 1px solid #ccc;
          img {
            width: 20px;
            margin-right: 10px;
          }
          .out_btn {
            border: 0;
            background: transparent;
            padding: 7px 13px;
            border: 1px solid #ccc;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
  ::v-deep .reSendEmail {
    // position: fixed;
    // top: 0;
    .email_tit {
      font-weight: bold;
      color: #000;
      word-break: break-word;
    }
    .el-dialog {
      width: 520px;
      .code_box {
        position: relative;
        margin: 0;
        .code_inpt {
          border: 0;
          border-bottom: 1px solid #ccc;
          border-radius: 0;
          padding: 10px 0;
          outline: none;
        }
        .code_inpt:focus {
          box-shadow: none;
        }
        .second {
          position: absolute;
          top: 0;
          right: 0;
          border: 1px solid #ccc;
          width: 86px;
          padding: 7px 20px;
          text-align: center;
          display: inline-block;
          border-radius: 20px;
          cursor: pointer;
        }
        .getcode {
          position: absolute;
          top: 0;
          right: 0;
          padding: 7px 20px;
          width: 86px;
          text-align: center;
          display: inline-block;
          color: rgb(247, 164, 3);
          border: 1px solid rgb(247, 164, 3);
          border-radius: 20px;
        }
      }
      .dialog-footer {
        text-align: center;
        button {
          width: 30%;
          color: #000;
        }
        .cancel:focus,
        .cancel:hover {
          background: #ffeecdc7;
          border-color: rgb(247, 164, 3);
        }
        .tape_out {
          background: rgb(247, 164, 3);
          border-color: rgb(247, 164, 3);
          color: #fff;
        }
      }
    }
  }
}

//移动端
@media screen and(max-width: 820px) {
  .equipment {
    .right_equipment_management {
      width: 100%;
      padding: 0;
      left: 0;
      h3 {
        padding: 20px 18px 0;
        font-weight: bold;
      }
      .equi_box {
        ul {
          background: #fff;
          padding: 20px 24px;
          li {
          }
        }
      }
    }
    ::v-deep .reSendEmail {
      .el-dialog {
        width: 90% !important;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0 !important;
        .el-dialog__footer {
          padding: 0;
          button {
            margin: 0;
            width: 50%;
            border: 0;
            border-radius: 0;
            border-top: 1px solid #e5e5e5;
            padding: 16px 0;
          }
          .tape_out {
            background: transparent !important;
            border-color: #e5e5e5;
            color: rgb(247, 164, 3);
            border-left: 1px solid #e5e5e5;
          }
        }
      }
    }
  }
}
::v-deep .el-button--primary {
  background: rgb(247, 164, 3) !important ;
}
</style>