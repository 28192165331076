<template>
  <div class="terms">
    <div class="hed_tit">
      <div class="centerWidth">
        <h1>隱私政策</h1>
      </div>
    </div>
    <div class="terms_list">
      <div class="centerWidth">
        <ul>
          <li>
            <p>
              我們希望您了解我們收集哪些信息，我們不收集哪些信息，以及我們如何收集、使用和存儲信息。我們不收集您的活動日誌，包括不記錄瀏覽歷史、流量目的地、數據內容或
              DNS 查詢。我們也從不存儲連接日誌，這意味著不會記錄您的 IP
              地址、您的外發 VPN IP 地址、連接時間戳或會話持續時間。
              我們收集數據的指導原則是僅收集運營所需的最少數據。我們設計的系統沒有關於我們客戶的敏感數據；即使在被迫的情況下，我們也無法提供我們不擁有的數據。
            </p>
            <p>本隱私政策將幫助您了解HyperlinkVPN如何收集、使用和存儲信息。</p>
          </li>
          <li>
            <h2>1.一般信息</h2>
            <p>HyperlinkVPN 收集三種類型的信息：</p>

            <p>
              1. 與您的帳戶相關的個人信息。收集此信息是為了管理您的 HyperlinkVPN
              訂閱。
            </p>
            <p>
              2. 聚合應用程序和 VPN 連接匯總統計。HyperlinkVPN
              收集有關使用情況的最少信息，以保持出色的客戶支持和服務質量。
            </p>
            <p>
              3.
              匿名應用診斷包括崩潰報告。應用程序診斷數據，包括崩潰報告、可用性診斷和
              VPN 連接診斷，都是匿名的，不能與單個 HyperlinkVPN
              用戶關聯。此功能類似於“發送錯誤報告”選項。用戶可以在任何
              HyperlinkVPN App 的設置菜單中指定是否將這些數據發送給我們。
            </p>
          </li>
          <li>
            <h2>2.個人信息</h2>
            <p>
              HyperlinkVPN
              收集您直接通過註冊提供給我們的個人信息。我們要求您提供個人信息，例如電子郵件地址，以便建立
              HyperlinkVPN
              帳戶，以便我們可以向您發送電子郵件、向您回復您發起的支持查詢。
            </p>
            <p>HyperlinkVPN 使用您的電子郵件地址的原因如下：</p>
            <ul class="terms_ul">
              <li>提供指向我們網站的鏈接，包括密碼重置電子郵件。</li>
              <li>向您發送更新和公告。</li>
              <li>與您就您的 VPN 服務進行交流或回復您的交流。</li>
              <li>發送營銷信息，您可以按選擇不接收營銷電子郵件。</li>
            </ul>
            <p>
              HyperlinkVPN
              僅將您的個人信息用於本隱私政策中列出的目的，我們不會將您的個人信息出售給第三方。
            </p>
          </li>
          <li>
            <h2>3.聚合應用程序和 VPN 連接摘要統計信息</h2>
            <p>
              我們確保從不記錄瀏覽歷史、流量目的地、數據內容、IP 地址或 DNS
              查詢。所以：
            </p>
            <ul class="terms_ul">
              <li>我們不知道哪個用戶曾經訪問過特定的網站或服務。</li>
              <li>
                我們不知道哪個用戶在特定時間連接到 VPN 或他們使用了哪個 VPN
                服務器 IP 地址。
              </li>
              <li>我們不知道用戶計算機的原始 IP 地址集。</li>
            </ul>
            <p>
              如果有人試圖根據上述任何一項強製 HyperlinkVPN
              發布用戶信息，我們將無法提供此信息，因為數據不存在。
            </p>
            <p>
              為了保持出色的客戶支持和服務質量，HyperlinkVPN 會收集與您的 VPN
              使用相關的以下信息。
            </p>
          </li>
          <li>
            <h2>4.應用程序和應用程序版本</h2>
            <p>
              我們收集與您已激活的應用程序和應用程序版本相關的信息。了解您當前的應用程序版本可以讓我們的支持團隊與您一起解決技術問題。
            </p>
          </li>

          <li>
            <h2>5.連接成功</h2>
            <p>
              我們收集有關您是否在特定日期成功建立 VPN
              連接，這些最少的信息可幫助我們提供技術支持，例如識別連接問題、提供有關如何最好地使用我們的服務的針對特定國家/地區的建議，並使
              HyperlinkVPN 工程師能夠識別和修復網絡問題。
            </p>
          </li>
          <li>
            <h2>6.傳輸的數據總量（以 MB 為單位）</h2>

            <p>
              我們收集有關用戶傳輸的數據總量的信息。雖然我們提供無限製的數據傳輸，但如果我們發現單個用戶推送的流量超過其他數百個用戶的總和，從而影響其他
              HyperlinkVPN 用戶的服務質量，我們可能會聯系該用戶以進行解釋。
            </p>
          </li>

          <li>
            <h2>7.匿名應用診斷，包括崩潰報告（可由用戶關閉）</h2>
            <p>
              在您允許的情況下，我們會收集匿名應用診斷數據，其中包括崩潰報告、可用性診斷和
              VPN
              連接診斷。我們在網絡運營工具中使用這些數據來幫助優化網絡速度，並讓我們確定與特定應用程序、VPN服務器或ISP相關的問題和需要改進的領域。我們收到的信息是完全匿名的，我們不存儲哪個用戶發送了哪些數據，也不存儲用戶
              IP 地址。
            </p>
            <p>
              如果您選擇與 HyperlinkVPN 共享此信息，我們將收集以下匿名信息：
            </p>
            <ul class="terms_ul">
              <li>有關 VPN 連接嘗試是否失敗以及如何失敗的診斷信息。</li>
              <li>速度測試數據。</li>
              <li>
                應用程序診斷，包括崩潰報告和可用性診斷。這些由這些第三方以匿名形式處理。
              </li>
            </ul>
          </li>
          <li>
            <h2>8.管轄權和適用法律</h2>
            <p>
              HyperlinkVPN 的核心使命是保護您的信息隱私。HyperlinkVPN
              的總部和註冊營業地位於新加坡。
            </p>
          </li>
          <li>
            <h2>9.存儲與電子郵件、反饋相關的信息</h2>
            <p>
              HyperlinkVPN
              會記錄您通過我們的服務提交給我們的任何投訴、問題、建議等。根據您聯系
              HyperlinkVPN
              的方式，我們可能會收集您的電子郵件地址和您提供給我們的任何其他信息。擁有完整的通信記錄使我們的員工能夠提供最佳的客戶支持體驗。
            </p>
          </li>
          <li>
            <h2>10.保護您信息的安全措施</h2>
            <p>
              HyperlinkVPN使用先進的程序和技術等安全措施，以防止任何信息丟失、誤用、未經授權的訪問、披露或修改。但我們同時需要了解世界上沒有任何數據安全措施可以提供
              100% 的保護，不過我們將盡全力保障保障用戶隱私的安全性。
            </p>
          </li>

          <li>
            <h2>11.Cookie 和移動標識符</h2>
            <p>
              cookie 是一個小文本文件，用於存儲有關您訪問本網站的信息。Cookie
              幫助我們提供某些功能，例如網站登錄和語言設置，從而讓 HyperlinkVPN
              優化和改善網站的用戶體驗。隨著我們不斷更新和改進我們的網站，我們使用的
              cookie 可能會隨時間變化。 100%
              的保護，不過我們將盡全力保障保障用戶隱私的安全性。
            </p>
            <p>
              移動標識符是由 Android 或 iOS
              設備提供的標識符。它不包含您的姓名或電子郵件地址。HyperlinkVPN
              使用移動標識符生成與營銷渠道和廣告合作夥伴相關的統計數據，用戶通過這些渠道了解和註冊
              HyperlinkVPN 移動應用程序。
            </p>
          </li>
          <li>
            <h2>12.同意和年齡限製</h2>
            <p>
              使用本應用程序、軟件或服務，即表示您同意按照我們的服務條款和隱私政策中的說明處理您的信息。
              100% 的保護，不過我們將盡全力保障保障用戶隱私的安全性。
            </p>
            <p>
              服務面向 18
              歲及以上的成年人。如果您認為您的孩子向我們提供了信息，請立即通知我們。
            </p>
          </li>

          <li>
            <h2>13.隱私政策的變更</h2>
            <p>
              根據適用的隱私法律和原則，我們可能會不時更改我們的隱私政策，恕不另行通知您。您繼續使用本網站或服務即表示您接受我們的隱私政策。
            </p>
          </li>
          <li>
            <h2>14.如何聯系HyperlinkVPN</h2>
            <p>
              如果您對我們的隱私政策以及我們如何處理您的信息有任何疑問，請隨時致電HyperlinkVPN官網電子郵箱地址。
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style>
</style>