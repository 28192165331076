<template>
  <div class="commonProblem">
    <div class="htd_tit centerWidth">
      <h1>iOS 下載常見問題</h1>
    </div>
    <div class="problem_list">
      <ul>
        <li>
          <div class="centerWidth">
            <h4 class="problem_tit">APP不可用</h4>
            <p class="problem_text">
              遇到這種情況請首先檢查您登錄的 Apple ID 地區是否處於
              “中國大陸”；或者是否剛切換過地區，Apple 可能還沒完成信息同步；
            </p>
            <p class="red">
              解決辦法：重新退出登錄 “香港 Apple ID”，再進行下載。
            </p>
            <div class="problem_pic">
              <img
                src="../../assets/image/commonProblem/question-01.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4 class="problem_tit">驗證失敗</h4>
            <p class="problem_text">
              這種錯誤場景會出現在您使用過“Apple ID A”
              下載hyperlinkVPN，然後使用“Apple ID B”進行版本更新；
            </p>
            <p class="red">解決辦法：刪除舊版本，重新安裝新版本。</p>
            <div class="problem_pic">
              <img
                src="../../assets/image/commonProblem/question-02.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4 class="problem_tit">現在不能購買此項目</h4>
            <p class="problem_text">
              遇到此情況可能說明您的手機中還存在hyperlinkVPN
              的“歷史舊版本”，它會與 App Store版本產生沖突。
            </p>
            <p class="red">解決辦法：刪除舊版本，重新安裝新版本。</p>
            <div class="problem_pic">
              <img
                src="../../assets/image/commonProblem/question-03.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4 class="problem_tit">此項目已不再提供</h4>
            <p class="problem_text">
              遇到此情況可能說明您的手機中還存在hyperlinkVPN
              的“歷史舊版本”，它會與 App Store版本產生沖突。
            </p>
            <p class="red">解決辦法：刪除舊版本，重新安裝新版本。</p>
            <div class="problem_pic">
              <img
                src="../../assets/image/commonProblem/question-04.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4 class="problem_tit">不再提供“hyperlinkVPN”</h4>
            <p class="problem_text">
              遇到此情況可能說明您的手機中還存在hyperlinkVPN
              的“歷史舊版本”，它會與 App Store版本產生沖突。
            </p>
            <p class="red">解決辦法：刪除舊版本，重新安裝新版本。</p>
            <div class="problem_pic">
              <img
                src="../../assets/image/commonProblem/question-05.png"
                alt=""
              />
            </div>
          </div>
        </li>
      </ul>
      <div class="down_foot centerWidth">
        <p class="blue">
          如果您在下載過程中有遇到任何問題，請您隨時聯系在線客服，我們會有專業的客服團隊幫助您解決任何問題。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.commonProblem {
  .red {
    color: rgb(245, 75, 75);
  }
  .htd_tit {
    text-align: center;
    h1 {
      font-size: 50px;
      margin: 80px 0 38px;
    }
    p {
      font-size: 20px;
      color: rgb(139, 139, 139);
      padding: 0 30px;
      text-align: left;
    }
  }
  .problem_list {
    ul {
      li {
        .problem_tit {
          font-size: 40px;
        }
        .problem_tit::before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 45px;
          background: #f54b4b;
          margin-right: 10px;
          position: relative;
          top: 6px;
        }
        p {
          font-size: 30px;
        }
        .problem_text {
          margin: 48px 0;
          color: rgb(139, 139, 139);
        }
        .problem_pic {
          text-align: center;
          padding: 115px 0;
          img {
            width: 360px;
            vertical-align: top;
            margin-right: 60px;
          }
        }
      }
    }
    .down_foot {
      text-align: center;
      font-size: 30px;
      line-height: 40px;
      .blue {
        color: rgb(58, 161, 255);
        font-weight: bold;
        margin-top: 30px;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .commonProblem {
    .htd_tit {
      h1 {
        font-weight: bold;
        font-size: 20px;
        margin: 40px 0 11px;
      }
      p {
        font-size: 12px;
      }
    }
    .problem_list {
      ul {
        padding: 0 20px;
        li {
          .problem_tit {
            font-size: 14px;
            font-weight: bold;
          }
          .problem_text {
            margin: 24px 0;
          }
          .problem_tit::before {
            width: 5px;
            height: 20px;
          }
          p {
            font-size: 12px;
          }
          .red {
            font-weight: bold;
          }
          .problem_pic {
            padding: 24px 0 10px;

            img {
              width: 80%;
              margin: 0;
            }
          }
        }
      }
      .down_foot {
        font-size: 12px;
        line-height: 20px;
        padding: 0 30px;
        .blue {
          color: rgb(58, 161, 255);
          font-weight: bold;
          margin-top: 0;
        }
      }
    }
  }
}
</style>