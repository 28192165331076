<template>
  <div>
    <!-- 中文服务隐私 -->
    <IndexZH v-if="$i18n.locale == 'zh-CN'" />
    <!-- 英文服务隐私 -->
    <IndexEN v-else />
  </div>
</template>
  
  <script>
import IndexZH from "./termsLang/termsZH.vue";
import IndexEN from "./termsLang/termsEN.vue";
export default { components: { IndexZH, IndexEN } };
</script>
  
  <style lang="less">
@import url("@/assets/css/clauseComm.less");

// .terms {
//   .hed_tit {
//     padding: 140px 0;
//     background: rgba(19, 27, 49, 1);
//     h1 {
//       font-size: 90px;
//       font-weight: bold;
//       color: #fff;
//       text-align: center;
//     }
//   }
//   .terms_list {
//     padding: 160px 0;
//     line-height: 40px;
//     p {
//       font-size: 20px;
//       margin: 0;
//     }
//     ul {
//       padding: 0;
//       li {
//         margin: 48px 0;
//         h2 {
//           font-size: 40px;
//           font-weight: bold;
//           margin-bottom: 24px;
//         }
//         .terms_ul {
//           li {
//             margin: 0;
//             list-style: inside;
//             margin-left: 10px;
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 820px) {
//   .terms {
//     .hed_tit {
//       padding: 18px 0;
//       h1 {
//         font-size: 18px;
//       }
//     }
//     .terms_list {
//       padding: 0px 38px;
//       line-height: 25px;
//       p {
//         font-size: 12px;
//       }
//       ul {
//         li {
//           margin: 40px 0;
//           h2 {
//             font-size: 16px;
//             margin-bottom: 6px;
//           }
//           .terms_ul {
//             li {
//               font-size: 12px;
//               margin: 0;
//             }
//           }
//         }
//         li:first-child {
//           margin: 30px 0;
//         }
//       }
//     }
//   }
// }
</style>