import Vue from "vue";
import VueRouter from "vue-router";
import home from "../pages/home";
import selectHyperlink from "../pages/home/children/selectHyperlink";
import macDownload from "../pages/home/children/macDownload";
// import priceIndex from "../pages/home/children/priceIndex";
import userEvaluation from "../pages/home/children/userEvaluation";

import downloadExplain from "../pages/downloadExplain";
import downloadCourseFirst from "../pages/iosDownloadCourse/downloadCourseFirst"
import downloadCourseSecond from "../pages/iosDownloadCourse/downloadCourseSecond"
import downloadCourseThirdly from "../pages/iosDownloadCourse/downloadCourseThirdly"
import commonProblem from "../pages/iosDownloadCourse/commonProblem"
import serviceTerms from "../pages/serviceTerms"
import privacyPolicy from "../pages/privacyPolicy"
import login from "../pages/login"
import memberCenter from "../pages/memberCenter"
import equipment from "../pages/equipment"
import succeed from "@/pages/succeed"
import register from "@/pages/register"
import purchaseHistory from "@/pages/purchaseHistory"
import terminalRevenue from "@/pages/terminalRevenue"
import InvitationLookup from "@/pages/InvitationLookup"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
    children: [
      {

        path: "/select-hyperlink",
        name: "selectHyperlink",
        component: selectHyperlink,
      },
      {
        path: "/MacDownload",
        name: "macDownload",
        component: macDownload,
      },
      // {
      //   path: "/priceIndex",
      //   name: "priceIndex",
      //   component: priceIndex,
      // },
      {
        path: "/UserEvaluation",
        name: "userEvaluation",
        component: userEvaluation,

      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/register",
    name: "register",
    component: register,
  },
  {
    path: "/memberCenter",
    name: "memberCenter",
    component: memberCenter,
  },
  {
    path: "/equipment",
    name: "equipment",
    component: equipment,
  },
  {
    path: "/downloadExplain",
    name: "downloadExplain",
    component: downloadExplain,
  },
  {
    path: "/downloadExplain/downloadCourseFirst",
    name: "downloadCourseFirst",
    component: downloadCourseFirst,
  }, {
    path: "/downloadExplain/downloadCourseSecond",
    name: "downloadCourseSecond",
    component: downloadCourseSecond,
  },
  {
    path: "/downloadExplain/downloadCourseThirdly",
    name: "downloadCourseThirdly",
    component: downloadCourseThirdly,
  },
  {
    path: "/downloadExplain/commonProblem",
    name: "commonProblem",
    component: commonProblem,
  },
  {
    path: "/serviceTerms",
    name: "serviceTerms",
    component: serviceTerms,
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: privacyPolicy,
  },
  {
    path: "/succeed",
    name: "succeed",
    component: succeed,
  },
  {
    path: "/purchaseHistory",
    name: "purchaseHistory",
    component: purchaseHistory,
  }, {
    path: "/terminalRevenue",
    name: "terminalRevenue",
    component: terminalRevenue,
  }, {
    path: "/InvitationLookup",
    name: "InvitationLookup",
    component: InvitationLookup,
  },


];
const router = new VueRouter({
  mode: "history",
  // mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
