<template>
  <div class="terms">
    <div class="hed_tit">
      <div class="centerWidth">
        <h1>服務條款</h1>
      </div>
    </div>
    <div class="terms_list">
      <div class="centerWidth">
        <ul>
          <li>
            <p>
              本服務條款文件概述了HyperlinkVPN提供的 VPN
              服務的使用條款和條件。這些條款還管轄 HyperlinkVPN
              內容的使用和訪問，其中包括 HyperlinkVPN應用程序和 HyperlinkVPN
              提供的任何軟件。
            </p>
            <p>
              同意這些條款，即表示您也同意 <span class="blue">隱私政策</span> 。
            </p>
          </li>
          <li>
            <h2>1.驗收接受</h2>
            <p>
              訪問內容或服務，即表示您代表您個體或者虛擬個體同意完全遵守這些條款並受其法律約束。這些條款構成您與
              HyperlinkVPN
              之間具有法律約束力的協議。如果您不同意本條款的任何部分，則不得使用我們的服務。
            </p>
            <p>
              創建帳戶以使用我們的服務，即表示您已年滿十八 (18)
              歲或您是有效的法律實體，並且您提供的註冊信息準確完整。
            </p>
          </li>
          <li>
            <h2>2.隱私政策</h2>
            <p>
              HyperlinkVPN 致力於保護您的隱私，不會收集或記錄連接到我們 VPN
              的訂閱者的瀏覽歷史記錄、流量目的地、數據內容或 DNS
              查詢。在您註冊期間，我們可能會收集一些敏感的個人信息，例如您的電子郵件地址。我們僅收集網站和服務必要的傳送信息。
            </p>
          </li>
          <li>
            <h2>3.服務條款變更</h2>
            <p>
              HyperlinkVPN
              可能會不時更新條款，恕不另行通知。如果您在這些更改生效後繼續使用
              HyperlinkVPN
              的服務、內容、站點、應用程序或軟件，即表示您同意修訂後的條款，同時接受我們的隱私政策。
            </p>
          </li>
          <li>
            <h2>4.語言和內容翻譯</h2>
            <p>
              我們所有的內容最初都是用英語編寫的。我們的內容的任何翻譯都是在盡最大努力的基礎上完成的。我們無法保證翻譯內容的準確性。如果翻譯的內容與英文內容有任何差異，以英文內容為準。
            </p>
          </li>

          <li>
            <h2>5.可接受的使用政策</h2>
            <p>
              HyperlinkVPN
              服務可從多個國家/地區進行訪問，因此您有責任評估使用本網站、應用程序、軟件或服務是否符合當地法律法規。每當您使用本網站、應用程序、軟件或服務時，您都應遵守這些條款和適用的法律、法規和政策。
            </p>
            <p>
              對您的 HyperlinkVPN
              帳戶信息保密是您的責任。您應對您帳戶下的所有活動負責。如果您發現或懷疑有人在未經您授權的情況下訪問了您的帳戶，建議您立即通知我們，以便我們撤銷您的帳戶憑據並發布新的憑據。
            </p>
            <p>
              HyperlinkVPN
              旨在為我們所有的用戶提供最好的服務。從這個意義上說，我們要求您不要濫用我們的內容或服務。濫用是指違反條款或適用法律法規的任何使用、訪問或幹擾內容或服務。
            </p>
            <p>
              為了保護服務不被濫用或用於傷害他人，HyperlinkVPN
              保留采取適當措施的權利。如果您濫用服務，HyperlinkVPN
              可以終止您的帳戶，而無需做任何解釋。
            </p>
            <p>在使用我們的服務時，您同意不會：</p>
            <ul class="terms_ul">
              <li>通過服務發送或傳輸未經請求的廣告或內容（即“垃圾郵件”）。</li>
              <li>
                通過服務發送、發布或傳輸任何非法、仇恨、威脅、侮辱或誹謗的內容；侵犯知識產權；侵犯隱私；或煽動暴力。
              </li>
              <li>
                上傳、下載、發布、復製或分發任何受版權或任何其他專有權利保護的內容，而沒有事先獲得版權所有者的許可。
              </li>
              <li>
                上傳、下載、發布、復製或分發任何包含對未成年人的性或露骨描述的內容。
              </li>
              <li>從事任何限製或禁止其他訂閱者使用或享受服務的行為。</li>
              <li>
                試圖在未經適當授權的情況下訪問、探測或連接到計算設備（即任何形式的“黑客攻擊”）。
              </li>
              <li>
                嘗試編譯、利用或分發 HyperlinkVPN 與服務一起運營的 IP 地址列表。
              </li>
              <li>將服務用於合法目的以外的任何用途。</li>
            </ul>
          </li>
          <li>
            <h2>6.免責聲明</h2>

            <p>
              我們將努力防止網站和服務中斷。然而，這些是在“原樣”和“可用”的基礎上提供的，我們不保證，無論是明示還是暗示，通過本網站或服務提供的任何材料或信息的準確性，或其適用性任何特定目的。我們不保證服務將滿足您的要求，或者服務不會中斷、及時、安全或無錯誤，或者缺陷（如果有）將得到糾正。您承認您自行承擔訪問本網站和服務的風險並自行決定。
            </p>
            <p>
              VPN
              服務覆蓋範圍、速度、服務器位置和質量可能會有所不同。HyperlinkVPN
              將始終嘗試使服務可用。但是服務可能會因我們無法控製的各種因素而無法使用，對於由於服務、通信服務或網絡的中斷或性能問題而導致的數據、消息或頁面丟失、未交付、延遲或誤導，我們概不負責。我們可以自行決定施加使用或服務限製、暫停服務、終止
              VPN
              帳戶或阻止某些類型的使用，以保護訂閱者或服務。不保證所接收數據的準確性和及時性；可能會出現延誤或遺漏。
            </p>
            <p>
              HyperlinkVPN
              保留調查我們認為違反這些條款的事項的權利。我們可以，但沒有義務，在沒有通知的情況下，自行決定刪除、阻止、過濾或以任何方式限製我們認為實際或可能違反這些條款中規定的限製的任何材料或信息，以及任何其他可能使
              HyperlinkVPN
              或我們的客戶承擔責任的活動。對於我們未能阻止此類材料或信息通過服務傳輸和/或傳輸到您的計算設備，HyperlinkVPN
              不承擔任何和所有責任。
            </p>
          </li>

          <li>
            <h2>7.責任限製</h2>
            <p>
              對於您遭受的任何損失或損害，HyperlinkVPN
              不對任何訂閱者或其他個人承擔任何形式的責任。在任何情況下，HyperlinkVPN、成員、管理人員或員工均不對任何直接、特殊、間接、後果性或附帶損害或任何其他類型的損失或損害承擔責任，即使他們有被告知其可能性。上述規定不適用於適用法律禁止的範圍。
            </p>
          </li>
          <li>
            <h2>8.適用法律</h2>
            <p>
              本協議應受新加坡法律管轄並據其解釋，不包括其管轄法律沖突的規則。
            </p>
          </li>
          <li>
            <h2>9.最後條款</h2>
            <p>
              如果本協議中的任何條款被認定為無效或不可執行，則該條款應以符合適用法律的方式進行解釋，以反映該條款的原始意圖，而本協議的其余條款仍應完全有效。未能行使或執行任何權利或本協議的規定不構成對此類權利或規定的放棄。
            </p>
          </li>
          <li>
            <h2>10.聯系我們</h2>
            <p>
              如果您對我們的隱私政策以及我們如何處理您的信息有任何疑問，請隨時通過Hyperlink的官方電子郵件地址與我們聯系。
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>