export const lang = {
  // 公共
  common: {
    downloadNow: "Download",
    freeDownload: "Download",
    day: 'day',
    days: 'days',
    hours: "hours",
    Less_than_an_hour: "Less than 1 hour remaining",
    surplus: "remaining",
    Hot: "Hot",
    macAmdDownload: "Download-Intel",
    macArmDownload: "Download-M1",

  },
  // 头部文字
  navBar: {
    Home: "Home",
    Advantage: "Advantage",
    Download: "Download",
    Price: "Price",
    Evaluate: "Evaluate",
  },
  login: {
    Sign_in: 'Sign In',
    welcome: 'Connect the world and explore new things',
    mailbox: 'Email',
    password: 'Password',
    register: 'Register now',
    forgetPassword: 'Forget the password',
    register_hyperlink: 'Register hyperlink account',
    client_side_register: 'Registering an account on the website is temporarily not supported.Please register in the client.If there  is no client, please return to the homepage of the  official website to download.',
    go_home: 'Home',
    Have_account: 'Existing Account',
    gologin: ' Sign In',
    client_side_password: 'It is temporarily not supported to retrieve the  password on the website. Please operate in the  client. If there is no client, please return to the  official website homepage to download.',
    login_successfully: "Login Successfully"


  },
  banner: {
    bannerTile: "Start exploring your world!",
    bannerP0:
      "Break through geographical restrictions and get your favorite programs and Applications",
    bannerP1: "Virtual IP access, farewell to censorship and restrictions",
  },
  selectHyperlink: {
    whyChoose: "Why choose HyperlinkVPN?",
    Choose_tit1: "Unblock GEO restrictions",
    Choose_text1:
      "By bypassing geo-restrictions, you can binge  Google、 Facebook、YouTube、NETFLIX+  and access your favourite content freely.",
    Choose_tit2: "Hide IP address",
    Choose_text2:
      "  All your online activity is 100% anonymous and untraceable with encrypted connections. Your personal data will be securely protected.",
    Choose_tit3: "Protect all your devices",
    Choose_text3:
      "You can enjoy exquisite and user-friendly clients for Windows, Mac, iOS, Android. It's just a click. Securing your internet connection is as simple as making your morning coffee.",

    Choose_tit4: "Ultra-fast servers",
    Choose_text4:
      "Choose from 50+ servers in 10 countries.All of the servers have implemented patented acceleration technology. What’s more, ultra-fast IPLC servers are provided to you. ",
    Choose_tit5: "Best CS, Always Online",
    Choose_text5:
      "Hyperlink provides 24-hour online customer service, 100+ live network engineers are on call at any time to escort your online experience, and truly provide a VIP service.",
    Choose_tit6: "Protect your data",
    Choose_text6:
      "All of your Internet access can be protected through our highest level of secure ECC encryption technology. We don't track, collect, Or share your private data.",
  },
  macDownload: {
    android_text: {
      android_h1: "Download the hyperlink on Android",
      android_p1: "Fast and secure Internet access on Android",
      android_p2: "For Android 6.0+",
      android_code_down: "Scan code to download",
      qrCode_text: "Open the mobile phone to scan QR code Download app now",
    },
    ios_text: {
      ios_h1: "Download the hyperlink on ios",
      ios_p1: "Fast and secure Internet access on ios",
      ios_p2: "For ios 11+",
      ios_download: "Download App",
    },
    mac_text: {
      mac_h1: "Download the hyperlink on Mac",
      mac_p1: "Fast and secure Internet access on MAC",
      mac_p2: "For MacOS Sierra 10.12+",
    },
    win_text: {
      win_h1: "Download the hyperlink on Windows",
      win_p1: "Fast and secure Internet access on windows",
      win_p2: "Support Win7 /Win8 /Win10 /Win11",
    },
    Android_Download: "Android Download",
    iOS_Download: "iOS Download",
    Mac_Download: "Mac Download",
    Windows_Download: "Windows Download",
  },
  Price: {
    myPrice: "Price",
    day: "days",
    month: "Month",
    months: "Months",
    just: "just",
    save: "Save",
    preday: "pre day",
    premonth: "pre month",
    guarantee: "7-day money-back guarantee.",
    price_fot: {
      fot1: "Full platform support",
      fot2: "Support 3 devices",
      fot3: "4K video, unlimited traffic",
      fot4: "7 * 24-hour customer service",
      fot5: "Ultra high speed servers in 10 countries and regions",
    },
  },
  userEvaluation: {
    userEvaluationTile: "User Evaluation",
  },
  memberCenter: {
    sign_out: 'Sign Out',
    RenewMembership: 'Renew Membership',
    Device_management: 'Device Management',
    paymentMethod: 'Payment Method',
    Validity: 'Validity：',
    due_date: 'Validity：',
    days_remaining: 'days remaining',
    Expires_on_October: 'Expires on October',
    Total_order: 'Total Order',
    payment: 'Payment',
    have_expired: 'Have Expired',
    recommend: 'Hot',
    paymentSuccess: "Payment Succeeded",
    emailTo: "Bill sent to",
    daseven_daysys: "you need 7 days for refund without reason, please apply at ",
    Online_service: "Online Service",
    apply_for: "",
    Back_to_Home: "View My Account",
    refund: "Note: 7-day packages do not support 7-day refunds.",
    expire: 'Expires on',
    No_Discount: 'No Discount',
    First_time: "First time subscribe,you will get 2-15Days Vip",
    discounts: '58% off',
    repurchase1: "",
    repurchase2: "months to next level",
    repurchase3: "month to next level",
    discounts62: '62% off',
    discounts65: '65% off',
    Reached_max_level: "Reached max level",
    Member_Price: "Member Price",
    VIP: "VIP Lv",
    VIP2: 'user,',
    VIP3: 'off for you',
    low_price: ' As low as',
    noe_Month_VIP: '1 Month VIP',
    Month_VIP_15: '15 days VIP',
    nonparticipation: "This member does not participate in the activity of sending sharing coupons",

    old_user: 'Old user rebate, only available to LV',
    old_user2: '',
    Share_Coupon: 'Share Coupon',
    give_coupon: 'First time subscribe，you will get2-15Days Vip！',
    Total_order_price: 'Total order price',
    Subscribe_VIP: 'Subscribe  VIP',
    unpaid: 'You have a member order to pay',
    oldpayDataDay: ' VIP member, total fee',
    oldpayDataDay2: '15 days VIP membership, total cost',
    Cancel: 'Cancel',
    Payment: 'Payment',
    Save: 'Save',
    My_VIP_Share: 'My VIP Share',
    give_friends: '15 day VIP for friends',
    Share: 'Share',
    Congratulations: 'Congratulations!',
    Get_share: ' Get 2 sharing tickets Can be used in welfare rewards',
    zhang: '',
    share_look: '',
    payment_failure: "Order payment failed, please pay again",

    Order_Price_Conversion: "Order Price Conversion",
    Not_the_dollar: "It is detected that the common currency in your region is not USD",

    membership1: "Limited time for",
    membership2: "member experience package",
    day3vip: " days VIP",
    not_refund: "Refund not supported",
    not_Chinese: "!Non - Chinese mainland users are not currently supported",
  },
  equipment: {
    my_equipment: "My Device",
    Remove: "Remove",
    no_equipment: "No Equipment",
    Current_equipment: "Current Equipment",
    Offline_equipment: "The verification code has been sent to your email. After verification, the device is offline",
    verification_code: "The verification code has been sent to",
    resend: "Resend",
    cancel: "Cancel",
    Offline_equipment_text: "Offline",
    offline_successfully: "The device is offline successfully.",
    Please_correct_code: "Please enter the correct verification code",
    Please_code: "Please enter the verification code",

  },
  purchaseHistory: {
    Purchase: "Recent Purchase Records",
    Purchase_Time: "Purchase Time："
  },
  terminalRevenue: {
    Invitation: "Invitation code：",
    Recommended: "Recommended",
    Accumulated_income: "Accumulated income",
    RMB:"RMB",
    people:" People",
    start_date:"Start Date",
    ending_date:"Ending Date",
    to:"To",
    one_week:"One Week",
    one_month:"One Month",
    three_months:"Three Months",

  },
  InvitationLookup: {
    PleaseCode: "Please enter the invitation code",
    search:"Search"

  },
  footerBar: {
    // 关于我们
    About_Us: "About Us",
    Privacy_Policy: "Privacy Policy",
    Terms: "Terms of Service",
    Facebook: "Facebook",
    Twitter: "Twitter",
    //下载
    download: "Download",
    // 帮助中心
    help: "Help Center",

    // 支付方式
    Payment: "Payment Method",
    // 最底部
    footer: "© 2022 HyperlinkVPN. All rights reserved.",
  },
  alert: {
    setMeal: "Please select order package",
    payment: "Please select the method of payment",
    not_return: "Payment not returned, please contact the relevant person!",
    network_anomaly: "Service is abnormal!",
    succeed: 'Succeed',
    Error: 'Error',
    warning: 'Warning',
    Cancel_Successful: 'Cancel Successful',
    Service_error: "Service error",
    Must_not_be_empty: "The account or password must not be empty!",
    Exit_successfully: "Exit successfully",
    network_anomaly1: "The network is disconnected, please check the network settings!",
    Login_invalidation: "Login invalid, please log in again"


  },
  langSel: {
    languageChoice: "Language Choice",
    lang_Device_management: "Device Management",
    Mymember: "Member Payment",
    sign_out: 'Sign Out',
    sets: "sets"

  },
  Your_friend: 'Your friend',
  Your_friend2: 'recommends hyperlinkVPN to you',
  hours_experience: '24 hours of free experience',
  hours_1: 'hours',
  Exclusive: 'Exclusive members for new comers',
  Successful: "Successful registration of new users will give you 24-hour free membership experience",
  menubar_hours: '',
  gives_15: "gives you 15 days of membership, come and experience together!",
  No_Geographical: 'No Geographical Restrictions',
  Virtual_IP_Access: 'Virtual IP Access',
  get: 'Get',
  collected: "The coupon has been collected",
  More_Benefits: 'More Benefits',
  register: 'Sign up',
  register_text: 'Create a Hyperlink account',

  signup_main_01: 'Confirm password',
  signup_main_02: 'Enter the same password',
  signup_main_03: 'I have read and agreed to comply',
  signup_main_04: 'Terms of service',
  signup_main_05: 'Invitation code (Optional)',

  login_main_07: "Must contain a letter and a number, and be minimum of 8 charactors,Special characters are allowed:! @ # $% ^ & * - _ = +.",
  login_main_08: 'Email or password must not be empty!',

  login_main_06: "The email format is incorrect!",

  emailCode_main_0: "The email was sent successfully",
  emailCode_main_01: "Resend", //
  emailCode_main_02: "The verification code has been sent to your email which is valid before 11:30",
  emailCode_main_03: "Click \"Send\" to get a new verification code",
  emailCode_main_04: "The verification code has expired, please click \"Send\" to get the verification code",
  emailCode_main_05: "Input error, please check again, the code is valid before 11:30 ! Or click \"Send\" to get a new verification code again.",
  emailCode_main_06: "Special remind",
  emailCode_main_07: "Did not receive mail?",
  emailCode_main_08: "1. Check the trash bin in your mailbox.",
  emailCode_main_09: "2. If the email is still not found, please try to resend.",
  emailCode_main_10: "Please check the Terms of Service first",
  emailCode_main_11: "The verification code must not be empty",
  emailCode_main_12: "Register successfully!",
  emailCode_main_13: "The verification code has expired, please try to get the verification code again.",
  emailCode_main_14: 'Terms of Service',
  emailCode_main_15: 'Registration requires you to read and agree to abide by the "Terms of Service"',
  emailCode_main_16: "Agree",
  emailCode_main_17: "Cancel",
  emailCode_main_18: "Free experience for 24h after successful registration",
  emailCode_main_19: "",


  Sharing_friends_tit: "The pasteboard has been copied, you can share it with your friends!",
  Account_no: "The account is not logged in, please complete the login before claiming!",
  successfully: 'Coupons received successfully',
  email_format: "Email",
  characters_8: "Password",
  Return_Login: "Return to login",

  VIP_15: "VIP 15-day experience card",

  step: "Step",
  go_back: 'Back',
  verify: "Verify",
  code_5: ' Please check your email in time and enter the 5-digit verification code',
  login_main_code: "Verification Code",
  resend: "Resend",
  yes: "Confirm",
  consent: "Consent",
  disagree: "Don't agree, quit",
  Downloading_APP: 'Download App'


};
