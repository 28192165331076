<template>
  <div class="downloadCourseFirst">
    <!-- <div class="centerWidth"> -->
    <div class="htd_tit">
      <h1>創建新賬號下載hyperlinkVPN</h1>
      <p>
        僅需 1 分鐘，創建您的全新專屬“香港 Apple ID 賬號”，下載hyperlinkVPN
        或者各種海外應用會更加方便！
      </p>
    </div>
    <div class="course_list">
      <ul>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">01</span> 進入 Apple 的創建 Apple ID 網站：
              <a href="https://appleid.apple.com/account" target="_blank"
                >https://appleid.apple.com/account</a
              >
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-01.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">02</span>
              <span class="tit"
                >根據提示輸入您的個人相關信息，註意在國家和地區請選擇“香港”，另外需註意兩點：</span
              >
            </h4>
            <p class="annotation_p">
              <span>
                郵箱：需要準備一個從未註冊過 Apple ID 的
                <span class="red">“全新真實”</span> 郵箱用來收取驗證碼；</span
              >
            </p>
            <p class="annotation_p">
              <span
                >手機號：支持
                <span class="red"> “中國大陸” </span> 的手機驗證；</span
              >
            </p>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-02.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">03</span>
              <span class="tit"
                >當您輸入所有的信息後，頁面會跳轉到
                <span class="red">“郵箱驗證”</span>
                ，您需要查詢您的郵箱，並將驗證 碼輸入在這裏。</span
              >
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-03.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">04</span>
              <span class="tit"
                >當您完成郵箱驗證後，頁面會跳轉到
                <span class="red">“手機號驗證”</span>
                。您需要查看您註冊用的手機號碼 信息，將驗證碼輸入在這裏。</span
              >
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-04.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">05</span>
              <span class="tit"
                >當您成功輸入完以上信息後，頁面將跳轉到
                <span class="red">“登錄和安全頁面”</span>
                ，恭喜您，您的<span class="red">“香港 Apple ID”</span>
                已經註冊成功了。</span
              >
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-05.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">06</span>
              <span class="tit"
                >打開 App Store ，點擊 <span class="red">“右上角圖標”</span>；
              </span>
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-06.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">07</span>
              <span class="tit"
                >選擇<span class="red">“退出登錄”</span>
                ，記得一定要滑倒頁面底部才能看到 “退出登錄” 按鈕。
              </span>
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-07.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">08</span>
              <span class="tit">
                在 App Store 中登錄您
                <span class="red">“剛剛註冊的香港 Apple ID”</span>
                ，當您輸入完 ID 和密碼之後， 請點擊下方的
                <span class="red">“登錄”</span>
                按鈕，不要點擊右上角的 “完成” 按鈕。
              </span>
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-08.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num center">最后一步</span>

              <span class="tit">
                當您發現商店內容字體變為<span class="red">繁體字</span>
                後證明您已經切換到香港地區了。此時
                您可以點擊下方按鈕直接下載<span class="red">hyperlinkVPN</span>
                ，或在商店中自行搜索
                <span class="red">hyperlinkVPN</span>進行下載。
              </span>
            </h4>
            <p class="last_btn">
              <button type="button" class="btn btn-warning" @click="goAppStore">
                跳转App Store
              </button>
            </p>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-09.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num center">温馨提示：</span>

              <span class="tit"
                >新的 Apple ID 在首次下載軟件時，會要求設定 Apple ID
                的一些信息， 如圖中所示，請點擊<span class="red">“檢查”</span>
                ，然後按照提示逐步操作即可，需要註意 的是<span class="red"
                  >付款方式不要做任何選擇</span
                >
                ，其它的內容可隨意填寫。
              </span>
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseFirst/01-10.png"
                alt=""
              />
              <img
                src="../../assets/image/downloadCourseFirst/01-11.png"
                alt=""
              />
            </div>
          </div>
        </li>
      </ul>
      <div class="down_foot centerWidth">
        <p class="hint_p">
          下載完成hyperlinkVPN後，您可以隨時把 Apple ID
          <span class="red">切換回原來的中國大陸賬號</span>
          ，當然您也可以在香港商店中下載各種海外熱門應用，hyperlinkVPN全部都能支持！
        </p>
        <p class="blue">
          如果您在下載過程中有遇到任何問題，請您隨時聯系在線客服，我們會有專業的客服團隊幫助您解決任何問題。
        </p>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    goAppStore() {
      window.open("https://apps.apple.com/us/app/hyperlinkvpn/id1579801595");
    },
  },
};
</script>

<style lang="less" scoped>
.downloadCourseFirst {
  .red {
    color: rgb(245, 75, 75);
  }
  .center {
    display: block;
    text-align: center;
    margin: 20px 0 0;
  }
  .htd_tit {
    text-align: center;
    h1 {
      font-size: 50px;
      margin: 80px 0 38px;
    }
    p {
      font-size: 20px;
      color: rgb(139, 139, 139);
    }
  }
  .course_list {
    margin-top: 155px;
    ul {
      padding: 0;
      li {
        padding: 40px 20px;
        h4 {
          font-size: 28px;
          font-weight: 500;
          .num {
            color: rgb(245, 75, 75);
            font-style: italic;
            font-size: 50px;
            font-weight: bold;
            margin-right: 10px;
          }
        }
        .annotation_p {
          font-size: 20px;
          color: #000;
        }
        .annotation_p::before {
          content: "";
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #000;
          display: inline-block;
          margin-right: 5px;
        }
        .last_btn {
          text-align: center;
          margin-top: 70px;
          button {
            color: #fff;
            padding: 15px 54px;
            font-size: 26px;
            background: rgba(247, 164, 3, 1);
          }
          button:hover,
          button:focus {
            background: rgba(239, 121, 12, 1);
            box-shadow: none;
          }
        }
        .course_pic {
          text-align: center;
          padding: 115px 0;
          img {
            width: 360px;
            vertical-align: top;
            margin-right: 60px;
          }
        }
      }
      li:nth-of-type(even) {
        background: rgba(251, 252, 253, 1);
      }
    }
    .down_foot {
      text-align: center;
      font-size: 30px;
      line-height: 40px;
      .hint_p {
      }
      .blue {
        color: rgb(58, 161, 255);
        font-weight: bold;
        margin-top: 30px;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .downloadCourseFirst {
    .htd_tit {
      h1 {
        font-weight: bold;
        font-size: 20px;
        margin: 40px 0 11px;
      }
      p {
        font-size: 12px;
        padding: 0 30px;
      }
    }
    .course_list {
      margin-top: 40px;
      ul {
        li {
          h4 {
            font-size: 14px;
            line-height: 26px;
            font-weight: bold;
            margin-bottom: 31px;
            .num {
              font-size: 24px;
            }
            .tit {
              display: inline-block;
              width: 90%;
              vertical-align: top;
              padding-left: 10px;
            }
            a {
              margin-left: 30px;
              color: rgb(58, 161, 255);
            }
          }
          .annotation_p {
            font-size: 12px;
            padding: 0 10px;
            span {
              display: inline-block;
              width: 90%;
              vertical-align: top;
              padding-left: 5px;
            }
          }
          .last_btn {
            margin-top: 40px;
            button {
              font-size: 14px;
              padding: 10px 30px;
            }
          }
          .annotation_p::before {
            width: 5px;
            height: 5px;
          }
          .course_pic {
            padding: 22px 0;

            img {
              width: 80%;
              margin: 0;
            }
          }
          .course_pic:nth-child(2) img {
            margin-top: 20px;
          }
        }
      }
      .down_foot {
        font-size: 12px;
        line-height: 20px;
        padding: 0 30px;
        .hint_p {
        }
        .blue {
          color: rgb(58, 161, 255);
          font-weight: bold;
          margin-top: 0;
        }
      }
    }
  }
}
</style>