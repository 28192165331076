<template>
  <div class="user_evaluation">
    <div class="centerWidth">
      <h1 class="evaluate_tit">
        {{ $t("lang.userEvaluation.userEvaluationTile") }}
      </h1>
      <div class="row">
        <div
          class="col-sm-4 user_list"
          v-for="item in $i18n.locale == 'zh-CN'
            ? user_zh_evaluation_list
            : user_en_evaluation_list"
          :key="item.id"
        >
          <div class="card">
            <div class="card-body">
              <p class="card-title">
                <img class="user_head" :src="item.user_head" alt="" />
                <span class="username">{{ item.username }}</span>
              </p>
              <p class="card-text">{{ item.evaluate }}</p>
              <p class="ipad_star star">
                <img :src="item.starsPic" alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 中文数据
      user_zh_evaluation_list: [
        {
          id: 1,
          user_head: require("../../../assets/image/index/headPortrait/t1.png"),
          username: "FSWF",
          evaluate: "用了1年多了，非常穩定，非常好用",
          // starsPic: "Evaluate-stars4.png",
          starsPic: require("../../../assets/image/index/Evaluate-stars.png"),
        },
        {
          id: 2,
          user_head: require("../../../assets/image/index/headPortrait/t2.png"),
          username: "PAnnaCoTt",
          evaluate:
            "OK，感覺總算找到一個能用的VPN了，而且全部支持中文，容易操作！！",
          starsPic: require("../../../assets/image/index/Evaluate-stars.png"),
        },
        {
          id: 3,
          user_head: require("../../../assets/image/index/headPortrait/t3.png"),
          username: "上冬-",
          evaluate:
            "好棒，對於我這個需要經常科學上網的人簡直就是救星，看1080p的YouTube視頻毫無壓力，也不限速，非常好。",
          starsPic: require("../../../assets/image/index/Evaluate-stars4.5.png"),
        },
        {
          id: 4,
          user_head: require("../../../assets/image/index/headPortrait/t4.png"),
          username: "一直是旺仔",
          evaluate:
            "竟然還沒有流量限制！！！每天都離不開google和YouTube，最最最大的福利是tumblr看視頻一點都不卡，老司機懂的！",
          starsPic: require("../../../assets/image/index/Evaluate-stars4.png"),
        },
        {
          id: 5,
          user_head: require("../../../assets/image/index/headPortrait/t5.png"),
          username: "SEVEN7",
          evaluate:
            "超鏈這個軟件非常良心，軟件操作簡單、鏈接速度穩定，看視頻打遊戲都很好用，偶爾上ins找我的男神錶白，太爽了！！",
          starsPic: require("../../../assets/image/index/Evaluate-stars.png"),
        },
        {
          id: 6,
          user_head: require("../../../assets/image/index/headPortrait/t6.png"),
          username: "immeryl",
          evaluate:
            "相對於榜首的各大軟件，價格相對而言算是便宜。下載後就像看見新大陸一樣，十分好用，希望可以繼續保留住這塊凈土和小確幸，直到陽光照到妳們~",
          starsPic: require("../../../assets/image/index/Evaluate-stars.png"),
        },
      ],
      // 英文数据
      user_en_evaluation_list: [
        {
          id: 1,
          user_head: require("../../../assets/image/index/headPortrait/t1_en.png"),
          username: "Ay0gg",
          evaluate:
            "Extremely powerful! Mom no longer have to worry I cant see the limit TV series!",
          starsPic: require("../../../assets/image/index/Evaluate-stars.png"),
        },
        {
          id: 2,
          user_head: require("../../../assets/image/index/headPortrait/t2_en.png"),
          username: "promise",
          evaluate:
            "The best vpn i have ever seen. An it does work. I love it.",
          starsPic: require("../../../assets/image/index/Evaluate-stars4.5.png"),
        },
        {
          id: 3,
          user_head: require("../../../assets/image/index/headPortrait/t3_en.png"),
          username: "_MorningDAYTOY_",
          evaluate: "Good performance and to much speed",
          starsPic: require("../../../assets/image/index/Evaluate-stars.png"),
        },
        {
          id: 4,
          user_head: require("../../../assets/image/index/headPortrait/t4_en.png"),
          username: "MihhhhhhhreAy",
          evaluate:
            "I am really happy to this app because it's fast ,safe and secure and it's very easy think this is the first best VPN ever and forever",
          starsPic: require("../../../assets/image/index/Evaluate-stars.png"),
        },
        {
          id: 5,
          user_head: require("../../../assets/image/index/headPortrait/t5_en.png"),
          username: "lucyandtom",
          evaluate:
            "SUPEEER EASY TO USE!!! Thank you so much for creating this app. Love it!!!!",
          starsPic: require("../../../assets/image/index/Evaluate-stars4.png"),
        },
        {
          id: 6,
          user_head: require("../../../assets/image/index/headPortrait/t6_en.png"),
          username: "Auuuudrey",
          evaluate:
            "Never gave me any technical issues some infirst used it. Great VPN. No complaints.",
          starsPic: require("../../../assets/image/index/Evaluate-stars.png"),
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.user_evaluation {
  padding: 100px 0;

  .evaluate_tit {
    text-align: center;
    margin-bottom: 79px;
    font-size: 40px;
    font-weight: bold;
  }

  .user_list {
    margin-bottom: 20px;

    .card {
      border-radius: 30px;

      .card-body {
        padding: 48px 39px;
        height: 330px;

        .card-title {
          // width: 50%;
          border-bottom: 1px solid #ccc;
          display: flex;
          align-items: center;
          padding: 5px 0;

          .user_head {
            width: 38px;
            border-radius: 20px;
          }

          .username {
            font-size: 22px;
            color: rgb(139, 139, 139);
            margin-left: 10px;
          }
        }

        .card-text {
          margin-top: 33px;
          font-size: 18px;
          color: #000;
          font-weight: bold;
          overflow-y: scroll;
          max-height: 140px;
        }

        .star {
          position: absolute;
          bottom: 0;
          left: 39px;

          img {
            width: 170px;
          }
        }
      }
    }
  }
}
</style>
