<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <HeaderBar
      v-if="
        $route.path != '/login' &&
        $route.path != '/succeed' &&
        $route.path != '/register'
      "
    ></HeaderBar>
    <router-view v-if="isRouterAlive" />
    <FooterBar
      v-if="
        $route.path != '/login' &&
        $route.path != '/memberCenter' &&
        $route.path != '/equipment' &&
        $route.path != '/purchaseHistory' &&
        $route.path != '/succeed' &&
        $route.path != '/register' &&
        $route.path != '/terminalRevenue'
      "
    ></FooterBar>
  </div>
</template>
<script>
import HeaderBar from "./components/common/HeaderBar.vue";
import FooterBar from "./components/common/FooterBar.vue";
export default {
  components: {
    HeaderBar,
    FooterBar,
  },
  provide() {
    // 父组件中返回要传给下级的数据
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
  mounted() {
    if (
      this.$route.name == "selectHyperlink" ||
      this.$route.name == "macDownload" ||
      this.$route.name == "userEvaluation"
    ) {
      this.$router.push("/#" + this.$route.name);
    }
  },
};
</script>
