<template>
  <div class="banner">
    <div class="centerWidth">
      <!-- <p class="Share_friends" v-if="shareUserName">
        您的好友 <span>{{ shareUserName }}</span> 向您推荐hyperlinkVPN
      </p> -->
      <div class="row ban_row">
        <div class="col-md-6 left_description_download">
          <div class="description_con">
            <h1 class="app_h1">{{ $t("lang.banner.bannerTile") }}</h1>
            <p class="app_p">
              <img
                src="../../../assets/image/index/icon-home-Check.png"
                alt=""
              />
              {{ $t("lang.banner.bannerP0") }}
            </p>
            <p class="app_p">
              <img
                src="../../../assets/image/index/icon-home-Check.png"
                alt=""
              />
              {{ $t("lang.banner.bannerP1") }}
            </p>
          </div>
          <div class="download_btn">
            <button type="button" class="btn btn-warning">
              <!-- <router-link to="/register">{{
                $t("lang.login.register")
              }}</router-link> -->
              <a href="#MacDownload">{{ $t("lang.common.downloadNow") }}</a>
            </button>
          </div>
        </div>
        <div class="col-md-6 right_picture">
          <img src="../../../assets/image/index/home-picture.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shareUserName: "",
    };
  },
  mounted() {
    this.shareUserName = this.$route.query.shareUserName;
  },
};
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  // height: 1055px;
  background-image: url(../../../assets/image/index/home-bg.png);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  .Share_friends {
    text-align: center;
    font-size: 14px;
    margin: 0;
    position: relative;
    top: 22px;
    width: 100%;
    span {
      color: rgb(247, 164, 3);
    }
  }
  .ban_row {
    position: relative;
  }

  .left_description_download {
    margin-top: 10%;
    padding: 0;

    .description_con {
      h1 {
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 34px;
      }

      p {
        font-size: 26px;
        line-height: 50px;
        // display: flex;
        // align-items: center;

        img {
          width: 30px;
          height: 30px;
          margin-right: 5px;
          vertical-align: sub;
        }
      }
    }

    .download_btn {
      button {
        color: #fff;
        font-size: 24px;
        padding: 0;
        a {
          color: #fff;
          text-decoration: none;
          display: inline-block;
          padding: 10px 30px;
        }
      }

      button:hover,
      button:focus {
        background: rgba(239, 121, 12, 1);
        box-shadow: none;
      }
    }
  }

  .right_picture {
    margin-top: 8%;
    position: relative;
    left: 8%;

    img {
      width: 505px;
      // height: 637px;
    }
  }
}
</style>
