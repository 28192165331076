import request from "@/utils/request"
let headers = {
    'language': localStorage.getItem("lang") || 'zh-CN'
}
export function login(params = {}) {
    return request({
        method: 'post',
        url: '/api/sign/web/login',
        data: params,

        headers: {
            'Content-Type': 'application/json',
            ...headers
        },

    })
}
export function logout() {
    return request({
        method: 'post',
        url: '/api/sign/web/logout',
        headers: {
            // 'Authorization': localStorage.getItem("token"),
            ...headers

        },
        // data: params
    })
}
export function search() {
    return request({
        method: 'post',
        url: '/api/node/search',
        headers: {
            // 'Authorization': localStorage.getItem("token"),
            ...headers

        },
    })
}
// 注册
export function register(params = {}) {
    return request({
        method: 'post',
        url: '/api/user/check',
        headers: {
            // 'Authorization': localStorage.getItem("token"),
            ...headers

        },
        data: params
    })
}
// 分享注册
export function shareRegister(params = {}) {
    return request({
        method: 'post',
        url: '/api/user/share/register',
        headers: {
            ...headers

        },
        data: params
    })
}
//正常注册
export function verifyregister(params) {
    return request({
        method: 'post',
        url: '/api/user/register',
        headers: headers,
        data: params
    })
}

// //注冊郵件驗證--发送邮箱验证码
// export function reSendEmail(params) {
//     return request({
//         method: 'post',
//         url: '/api/user/reSendEmail',
//         headers: {
//             'Authorization': localStorage.getItem("token"),
//             'language': localStorage.getItem("lang") || '	zh-CN'
//         },
//         data: params
//     })
// }