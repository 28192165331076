<template>
  <div class="register_page">
    <div class="login_flex centerWidth">
      <div class="left_login_pic">
        <img src="../../assets/image/login/left_reg_pic.png" alt="" />
        <div
          class="reg_tit"
          :class="$i18n.locale == 'zh-CN' ? 'reg_tit_zh' : ''"
        >
          <p>{{ $t("lang.emailCode_main_18") }}</p>
          <p>{{ $t("lang.emailCode_main_19") }}</p>
        </div>
      </div>
      <div class="right_login_sub" v-if="canResend && !agreement_flag">
        <div class="app_top_step">
          <span>{{ $t("lang.step") }} <span class="red">1</span>/2</span>
          <!-- <span @click="goback" class="go_login">{{ $t("lang.go_back") }}</span> -->
          <!-- <router-link
            class="go_login"
            :to="
              '/login?shareUserName=' +
              $route.query.shareUserName +
              '&shareEmail=' +
              $route.query.shareEmail
            "
            tag="span"
          >
            {{ $t("lang.login.Sign_in") }}</router-link
          > -->
          <span class="go_login" @click="gologin">{{
            $t("lang.login.Sign_in")
          }}</span>
        </div>
        <router-link class="top_log" tag="div" to="/">
          <img class="pc_log" src="../../assets/image/top-logo.jpg" alt="" />
          <img class="app_log" src="../../assets/image//register.png" alt="" />
        </router-link>
        <div class="login_tit">
          <h1>{{ $t("lang.register") }}</h1>
          <p>{{ $t("lang.register_text") }}</p>
        </div>
        <div class="form_login">
          <div class="mb-3 form_li">
            <label for="exampleFormControlInput1" class="form-label">{{
              $t("lang.login.mailbox")
            }}</label>
            <input
              type="text"
              class="form-control login_input"
              :class="email_validate === false ? 'email_validate_style' : ''"
              id="exampleFormControlInput1"
              :placeholder="$t('lang.email_format')"
              v-model="userEmail"
              @blur="emailBlur"
            />
          </div>
          <div class="mb-3 form_li">
            <label for="exampleFormControlTextarea1" class="form-label">{{
              $t("lang.login.password")
            }}</label>
            <p class="password_p">
              <input
                :type="password_type ? 'password' : 'text'"
                class="form-control login_input"
                :class="pass_validate === false ? 'pass_validate_style' : ''"
                id="exampleFormControlInput1"
                :placeholder="$t('lang.characters_8')"
                :maxlength="50"
                @blur="passBlur"
                @focus="surePasswordBlur"
                v-model="userPassword"
              />
              <img
                class="eye"
                src="@/assets/image/login/eye-Close.png"
                alt=""
                v-show="password_type"
                @click="passChange"
              />
              <img
                class="eye"
                v-show="!password_type"
                src="@/assets/image/login/eye-open.png"
                alt=""
                @click="passChange"
              />
            </p>
          </div>
          <div class="mb-3 form_li">
            <label for="exampleFormControlTextarea1" class="form-label">{{
              $t("lang.signup_main_01")
            }}</label>
            <p class="password_p">
              <input
                :type="sure_password_type ? 'password' : 'text'"
                class="form-control login_input"
                :class="
                  sure_pass_validate === false ? 'sure_pass_validate_style' : ''
                "
                :placeholder="$t('lang.signup_main_01')"
                id="exampleFormControlInput1"
                :maxlength="50"
                v-model="surePassword"
                @focus="surePasswordBlur"
                @blur="surePasswordBlur"
              />
              <img
                class="eye"
                src="@/assets/image/login/eye-Close.png"
                alt=""
                v-show="sure_password_type"
                @click="surepassChange"
              />
              <img
                class="eye"
                v-show="!sure_password_type"
                src="@/assets/image/login/eye-open.png"
                alt=""
                @click="surepassChange"
              />
            </p>
          </div>
          <div class="mb-3 form_li">
            <label for="exampleFormControlTextarea1" class="form-label">{{
              $t("lang.signup_main_05")
            }}</label>
            <p class="password_p">
              <input
                type="text"
                class="form-control login_input"
                placeholder=""
                id="exampleFormControlInput1"
                :maxlength="50"
                v-model="invitationCode"
              />
            </p>
          </div>
          <div
            class="submit"
            :class="$i18n.locale == 'zh-CN' ? '' : 'en_submit'"
          >
            <el-checkbox-group v-model="agreement_check">
              <el-checkbox label="">
                <template>
                  <P class="service_p"
                    >{{ $t("lang.signup_main_03")
                    }}<span @click="agreementChange">
                      {{ $t("lang.signup_main_04") }}</span
                    ></P
                  >
                </template>
              </el-checkbox>
            </el-checkbox-group>
            <button
              :class="
                email_validate &&
                pass_validate &&
                sure_pass_validate &&
                agreement_check
                  ? ''
                  : 'btn_ccc'
              "
              type="button"
              class="btn btn-warning"
              @click="registerSub"
            >
              {{ $t("lang.register") }}
            </button>
          </div>
          <div class="register">
            <!-- <router-link
              :to="
                '/login?shareUserName=' +
                $route.query.shareUserName +
                '&shareEmail=' +
                $route.query.shareEmail
              "
              tag="span"
            >
              {{ $t("lang.Return_Login") }}</router-link
            > -->
            <span @click="gologin">{{ $t("lang.Return_Login") }}</span>
          </div>
        </div>
      </div>
      <div
        class="right_login_sub resendEmail_sub"
        v-if="!canResend && !agreement_flag"
      >
        <div class="app_top_step">
          <span>{{ $t("lang.step") }} <span class="red">2</span>/2</span>
          <span @click="goreg" class="go_login">{{
            this.$t("lang.go_back")
          }}</span>
        </div>

        <router-link class="top_log" tag="div" to="/">
          <img class="pc_log" src="../../assets/image/top-logo.jpg" alt="" />
          <img class="app_log" src="../../assets/image//register.png" alt="" />
        </router-link>
        <div class="login_tit">
          <h1>{{ $t("lang.verify") }}</h1>
          <p>{{ $t("lang.code_5") }}</p>
        </div>
        <div class="form_login">
          <div class="mb-3 form_li">
            <label for="exampleFormControlInput1" class="form-label">{{
              $t("lang.login_main_code")
            }}</label>
            <input
              type="text"
              class="form-control login_input"
              id="exampleFormControlInput1"
              placeholder=""
              :maxlength="5"
              v-model="verifyCode"
            />
            <span class="timeEnd" v-if="timeValue != 0"> {{ timeValue }}</span>
            <span class="timeEnd timeEnd_origin" v-else @click="resendEmail">{{
              $t("lang.resend")
            }}</span>
          </div>
          <P class="reg_timeEnd">{{ reminder }}</P>
          <div class="submit resendEmailSubmit">
            <!-- <el-checkbox-group v-model="agreement_check">
              <el-checkbox label="">
                <template> -->

            <!-- </template>
              </el-checkbox>
            </el-checkbox-group> -->
            <button
              :class="verifyCode ? '' : 'btn_ccc'"
              type="button"
              class="btn btn-warning"
              @click="loginSubmit"
            >
              {{
                verifyCode == "" && timeValue == 60 ? btnTitle : $t("lang.yes")
              }}
            </button>
            <!-- @click="loginSubmit" -->
          </div>
          <div class="fot_email">
            <p>{{ $t("lang.emailCode_main_07") }}</p>
            <p>{{ $t("lang.emailCode_main_08") }}</p>
            <p>{{ $t("lang.emailCode_main_09") }}</p>
          </div>
        </div>
      </div>
      <div class="right_login_sub agreement_box" v-if="agreement_flag">
        <div class="agreement_content">
          <router-link class="top_log none" tag="div" to="/">
            <img class="pc_log" src="../../assets/image/top-logo.jpg" alt="" />
            <img
              class="app_log"
              src="../../assets/image//register.png"
              alt=""
            />
          </router-link>
          <!-- <div class="login_tit">
            <h1>用户协议与隐私政策</h1>
          </div>
          <div class="agreement_text">
            <p class="tit_p">欢迎大家来到Hyperlink。</p>
            <p>本服务条款文件概述了HyperlinkVPN提供的服务的使用条款和条件。</p>
            <p>
              同意这些条款，即表示您也<span class="blank">同意隐私政策。</span>
            </p>
            <p class="tit_p"><span class="blank">1. 验收</span></p>
            <p>
              访问内容或服务，即表示您代表您个体或者虚拟个体同意完全遵守这些条款并受其法律约束。这些条款构成您与
              HyperlinkVPN
              之间具有法律约束力的协议。如果您不同意本条款的任何部分，则不得使用我们的服务。
              创建帐户以使用我们的服务，即表示您已年满十八 (18)
              岁或您是有效的法律实体，并且您提供的注册信息准确完整。
            </p>
            <p class="tit_p"><span class="blank">2. 隐私政策</span></p>
            <p>
              HyperlinkVPN 致力于保护您的隐私，不会收集或记录连接到我们 VPN
              的订阅者的浏览历史记录、流量目的地、数据内容或 DNS
              查询。在您注册期间，我们可能会收集一些敏感的个人信息，例如您的电子邮件地址。我们仅收集网站和服务必要的传送信息。
              创建帐户以使用我们的服务，即表示您已年满十八 (18)
              岁或您是有效的法律实体，并且您提供的注册
            </p>
          </div> -->
          <div class="agreement">
            <!-- 中文服务隐私 -->
            <IndexZH v-if="$i18n.locale == 'zh-CN'" />
            <!-- 英文服务隐私 -->
            <IndexEN v-else />
          </div>
          <div class="fot_btn">
            <button class="yes" @click="agreementCheckd">
              {{ $t("lang.consent") }}
            </button>
            <button class="no" @click="agreementCheckdNo">
              {{ $t("lang.disagree") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="login_dialog">
      <el-dialog
        :title="$t('lang.login.register_hyperlink')"
        :visible.sync="registerFlag"
        width="622px"
        center
        :show-close="false"
      >
        <p class="register_hint">
          {{ $t("lang.login.client_side_register") }}
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="gohome" class="gohomebtn">{{
            $t("lang.login.go_home")
          }}</el-button>
          <p class="login_p">
            {{ $t("lang.login.Have_account")
            }}<span @click="registerFlag = false">
              {{ $t("lang.login.gologin") }}</span
            >
          </p>
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('lang.login.forgetPassword')"
        :visible.sync="forgetPassword"
        width="622px"
        center
        :show-close="false"
      >
        <p class="register_hint">
          {{ $t("lang.login.client_side_password") }}
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="gohome" class="gohomebtn">{{
            $t("lang.login.go_home")
          }}</el-button>
          <p class="login_p">
            {{ $t("lang.login.Have_account")
            }}<span @click="forgetPassword = false">
              {{ $t("lang.login.gologin") }}</span
            >
          </p>
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('lang.emailCode_main_14')"
        :visible.sync="emailCodedialog"
        class="terms_dialog"
      >
        <span>{{ $t("lang.emailCode_main_15") }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="terms_cancel" @click="emailCodedialog = false">{{
            $t("lang.emailCode_main_17")
          }}</el-button>
          <el-button class="agree_btn" type="primary" @click="agreeTerms">{{
            $t("lang.emailCode_main_16")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import {login} from '@/api/http'
import IndexZH from "../serviceTerms/termsLang/termsZH.vue";
import IndexEN from "../serviceTerms/termsLang/termsEN.vue";
export default {
  data() {
    return {
      // z1x2c3v4826@163.com
      // qqqq1111
      userEmail: "",
      userPassword: "",
      surePassword: "",
      verifyCode: "",
      password_type: true,
      sure_password_type: true,
      registerFlag: false,
      forgetPassword: false,
      agreement_check: false,
      email_validate: "",
      pass_validate: "",
      sure_pass_validate: "",
      timeEnd: "", // 超时时间
      timeValue: 60,
      after10Values: "",
      reminder: "",
      isLoad: false,
      fromPage: "",
      canResend: true,
      isSubmit: false,
      regLoading: false,
      btnTitle: this.$t("lang.emailCode_main_01"),
      agreement_flag: false,
      shareUserName: "",
      shareEmail: "",
      timer: "",
      emailCodedialog: false,
      invitationCode: "",
    };
  },
  components: { IndexZH, IndexEN },
  watch: {
    userPassword(value, oldval) {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          this.userPassword = oldval;
        }
      }
    },
    userEmail(value, oldval) {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          this.userEmail = oldval;
        }
      }
    },
  },
  methods: {
    gologin() {
      if (this.$route.query.email) {
        this.$router.push("/login?email=" + this.$route.query.email);
      } else if (this.$route.query.shareEmail) {
        this.$router.push(
          "/login?shareEmail=" +
            this.$route.query.shareEmail +
            "&shareUserName=" +
            this.$route.query.shareUserName
        );
      } else if (this.$route.query.register) {
        this.$router.push("/login");
      }
    },
    agreementCheckd() {
      this.agreement_flag = false;
      this.agreement_check = true;
    },
    agreementCheckdNo() {
      this.agreement_flag = false;
      this.agreement_check = false;
    },

    goback() {
      // ?shareEmail=1310111704%40qq.com&shareUserName=1310111704
      this.$router.go(-1);
      // if (this.$route.query.shareEmail) {
      //   this.$router.push(
      //     "/?shareEmail=1310111704%40qq.com&shareUserName=1310111704"
      //   );
      // } else {
      //   this.$router.go(-1);
      // }
    },
    goreg() {
      this.canResend = true;
      clearTimeout(this.timer);
    },
    agreementChange() {
      this.agreement_flag = true;
    },
    emailBlur() {
      if (this.userEmail) {
        this.email_validate =
          /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/gi.test(
            this.userEmail
          );
        if (!this.email_validate) {
          this.$notify({
            title: this.$t("lang.alert.warning"),
            message: this.$t("lang.login_main_06"),
            type: "warning",
          });
        }
      }
    },
    passBlur() {
      if (this.userPassword) {
        this.pass_validate =
          /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*-_=+.\x22]{8,50}$/gi.test(
            this.userPassword
          );
        if (!this.pass_validate) {
          this.$notify({
            title: this.$t("lang.alert.warning"),
            message: this.$t("lang.login_main_07"),
            type: "warning",
          });
        } else if (this.surePassword == this.userPassword) {
          this.sure_pass_validate = true;
        }
      }
    },
    surePasswordBlur() {
      if (this.surePassword) {
        if (this.surePassword != this.userPassword) {
          this.sure_pass_validate = false;
        } else {
          this.sure_pass_validate = true;
        }
      }
    },
    registerSub() {
      if (!this.userEmail || !this.userPassword || !this.surePassword) {
        this.$notify({
          title: this.$t("lang.alert.warning"),
          type: "warning",
          message: this.$t("lang.login_main_08"),
        });
        return false;
      }
      if (!this.pass_validate) {
        this.$notify({
          title: this.$t("lang.alert.warning"),
          message: this.$t("lang.login_main_07"),
          type: "warning",
        });
        return false;
      }
      if (this.agreement_check && this.email_validate) {
        if (this.surePassword === this.userPassword) {
          this.$api
            .register({
              userEmail: this.userEmail,
              userPassword: this.userPassword,
              surePassword: this.surePassword,
              verifyType: "register",
              verifyCode: "",
              verifyValue: "",
              isJson: true,
            })
            .then((res) => {
              if (res.data.code == "0") {
                // this.$store.state.register_form = {
                //   ...values,
                //   verifyType: this.verifyType,
                // };
                // this.canResend = false;
                // this.resendEmail();
                this.registerSubmit();
              } else {
                this.$notify({
                  type: "warning",
                  message: res.data.message,
                });
              }
            })
            .catch(() => {
              this.$notify({
                type: "danger",
                message: this.$t("lang.alert.network_anomaly1"),
                color: "#F22F33",
                background: "#FFE8E8",
              });
            });
        } else {
          this.$notify({
            title: this.$t("lang.alert.warning"),
            type: "warning",
            message: this.$t("lang.signup_main_02"),
          });
        }
      } else {
        this.emailCodedialog = true;
        // this.$notify({
        //   title: this.$t("lang.alert.warning"),
        //   type: "warning",
        //   message: this.$t("lang.emailCode_main_10"),
        // });
      }
    },

    /**
     * 请求邮箱验证码
     */
    resendEmail() {
      this.timeValue = 60;

      this.$api
        .reSendEmail({
          userEmail: this.userEmail,
          userPassword: this.userPassword,
          verifyType: "register",
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.timeEnd = this.getAfter10mins();
            this.reminder = this.$t("lang.emailCode_main_02").replace(
              "11:30",
              this.timeEnd
            );
            this.startTimerAction();
          } else {
            this.reminder = this.$t("lang.emailCode_main_13");
          }
        })
        .catch(() => {
          this.$notify({
            type: "danger",
            message: this.$t("lang.alert.network_anomaly1"),
            color: "#F22F33",
            background: "#FFE8E8",
          });
        });
    },
    /**
     * 获取10分钟以后
     */
    getAfter10mins() {
      let nowInt = parseInt(new Date().getTime());
      this.after10Values = nowInt + 10 * 60 * 1000;
      let after10mins = new Date(this.after10Values)
        .toTimeString()
        .substr(0, 5);
      // this.$store.commit('loginSetObjToState', {
      //     codeEndTime:this.after10mins,
      //     after10Values:this.after10Values
      // })
      return after10mins;
    },
    /**
     * 开始倒计时
     */
    startTimerAction() {
      this.canResend = false;
      this.countTimeValue();
    },
    /**
     * 执行倒计时方法
     */
    countTimeValue() {
      this.timer = setTimeout(() => {
        this.countTimeValue();
      }, 1000);
      if (this.timeValue != 0) {
        this.timeValue--;
      }
      if (this.timeValue === 0) {
        this.timeValue = 0;
        // this.canResend = true;
        this.btnTitle = this.$t("lang.emailCode_main_01");
        clearTimeout(this.timer);
      } else {
        this.btnTitle =
          this.$t("lang.emailCode_main_01") + " (" + this.timeValue + ") ";
      }
    },
    getmember() {
      let email =
        this.$route.query.email || sessionStorage.getItem("shareEmail");
      // this.experienceCardFlag = false
      // let token = localStorage.getItem("token");
      // if (token) {
      if (email) {
        this.$api.getshare({ email: email }).then((res) => {
          if (res.data.code == "0") {
            // this.experienceCardFlag = res.data.data.isExist;
            // this.isExist = res.data.data.isExist;
            // this.dialogVisible
            sessionStorage.removeItem("shareEmail");
            this.$notify({
              title: this.$t("lang.alert.succeed"),
              type: "success",
              message: this.$t("lang.successfully"),
            });
          } else if (res.data.code == "2017") {
            sessionStorage.removeItem("shareEmail");
            // message
            this.$notify({
              title: this.$t("lang.alert.warning"),
              type: "warning",
              message: res.data.message,
            });
          } else {
            this.$notify({
              title: this.$t("lang.alert.warning"),
              type: "warning",
              message: res.data.message,
            });
          }
        });
      }

      // } else {
      //   this.$notify({
      //     this.$t("lang.alert.warning")
      //     type: "warning",
      //     message: "账号未登录，请先完成登录后领取！",
      //   });
      //   this.$router.push("/login?email=" + this.$route.query.email);
      // }
    },
    //无验证码校验的注册
    registerSubmit() {
      // if (this.after10Values < parseInt(new Date().getTime())) {
      //   this.reminder = this.$t("lang.emailCode_main_04");
      //   this.uploadData.verifyCode = "";

      //   this.isSubmit = false;
      //   return;
      // }
      // if (this.verifyCode == "" && this.timeValue == 60) {
      //   this.resendEmail();
      // } else {
      //   if (this.verifyCode != "") {
      if (this.$route.query.register != "y") {
        this.$api
          .shareRegister({
            // userName: "",
            userEmail: this.userEmail,
            userPassword: this.userPassword,
            // verifyCode: this.verifyCode.toUpperCase(),
            verifyType: "register",
            shareEmail:
              this.shareEmail || sessionStorage.getItem("registerEmail"),
            invitationCode: this.invitationCode,
          })
          .then((res) => {
            if (res.data.code == "0") {
              // localStorage.setItem("token", res.data.data);
              sessionStorage.setItem("token", res.data.data);

              sessionStorage.removeItem("registerEmail");
              this.getmember();
              this.$notify({
                title: this.$t("lang.alert.succeed"),
                message: this.$t("lang.emailCode_main_12"),
                type: "success",
              });
              setTimeout(() => {
                // location.href = "/memberCenter";
                this.$router.push("/memberCenter");
              }, 500);
            } else if (res.data.code == "1023") {
              this.$notify({
                title: this.$t("lang.alert.Error"),
                message: res.data.message,
                type: "error",
              });
              this.verifyCode = "";
              this.isSubmit = false;
            } else {
              // this.$Notify({ type: "danger", message: res.data.message });
              this.reminder = res.data.message;
              this.$notify({
                title: this.$t("lang.alert.Error"),
                message: res.data.message,
                type: "error",
              });
              this.verifyCode = "";
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              title: this.$t("lang.alert.Error"),
              message: this.$t("lang.alert.Service_error"),
              type: "error",
            });
          });
      } else {
        console.log("正常注册----------");
        this.$api
          .verifyregister({
            // userName: "",
            userEmail: this.userEmail,
            userPassword: this.userPassword,
            // verifyCode: this.verifyCode.toUpperCase(),
            verifyType: "register",
            invitationCode: this.invitationCode,
            // shareEmail:
            //   this.shareEmail || sessionStorage.getItem("registerEmail"),
          })
          .then((res) => {
            if (res.data.code == "0") {
              // localStorage.setItem("token", res.data.data);
              sessionStorage.setItem("token", res.data.data);

              sessionStorage.removeItem("registerEmail");
              this.getmember();
              this.$notify({
                title: this.$t("lang.alert.succeed"),
                message: this.$t("lang.emailCode_main_12"),
                type: "success",
              });
              setTimeout(() => {
                // location.href = "/memberCenter";
                this.$router.push("/memberCenter");
              }, 500);
            } else if (res.data.code == "1023") {
              this.$notify({
                title: this.$t("lang.alert.Error"),
                message: res.data.message,
                type: "error",
              });
              this.verifyCode = "";
              this.isSubmit = false;
            } else {
              // this.$Notify({ type: "danger", message: res.data.message });
              this.reminder = res.data.message;
              this.$notify({
                title: this.$t("lang.alert.Error"),
                message: res.data.message,
                type: "error",
              });
              this.verifyCode = "";
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              title: this.$t("lang.alert.Error"),
              message: this.$t("lang.alert.Service_error"),
              type: "error",
            });
          });
      }

      // } else {
      //   this.$notify({
      //     title: this.$t("lang.alert.Error"),
      //     message: this.$t("lang.emailCode_main_11"),
      //     type: "error",
      //   });
      // }
      // }
    },
    // 有验证码校验的注册
    loginSubmit() {
      if (this.after10Values < parseInt(new Date().getTime())) {
        this.reminder = this.$t("lang.emailCode_main_04");
        this.uploadData.verifyCode = "";

        this.isSubmit = false;
        return;
      }
      if (this.verifyCode == "" && this.timeValue == 60) {
        this.resendEmail();
      } else {
        if (this.verifyCode != "") {
          this.$api
            .shareRegister({
              userName: "",
              userEmail: this.userEmail,
              userPassword: this.userPassword,
              verifyCode: this.verifyCode.toUpperCase(),
              verifyType: "register",
              shareEmail:
                this.shareEmail || sessionStorage.getItem("registerEmail"),
              invitationCode: this.invitationCode,
            })
            .then((res) => {
              if (res.data.code == "0") {
                // localStorage.setItem("token", res.data.data);
                sessionStorage.setItem("token", res.data.data);
                sessionStorage.removeItem("registerEmail");
                this.getmember();
                this.$notify({
                  title: this.$t("lang.alert.succeed"),
                  message: this.$t("lang.emailCode_main_12"),
                  type: "success",
                });
                setTimeout(() => {
                  // location.href = "/memberCenter";
                  this.$router.push("/memberCenter");
                }, 500);
              } else if (res.data.code == "1023") {
                this.$notify({
                  title: this.$t("lang.alert.Error"),
                  message: res.data.message,
                  type: "error",
                });
                this.verifyCode = "";
                this.isSubmit = false;
              } else {
                // this.$Notify({ type: "danger", message: res.data.message });
                this.reminder = res.data.message;
                this.$notify({
                  title: this.$t("lang.alert.Error"),
                  message: res.data.message,
                  type: "error",
                });
                this.verifyCode = "";
              }
            })
            .catch((error) => {
              console.log(error);
              this.$notify({
                title: this.$t("lang.alert.Error"),
                message: this.$t("lang.alert.Service_error"),
                type: "error",
              });
            });
        } else {
          this.$notify({
            title: this.$t("lang.alert.Error"),
            message: this.$t("lang.emailCode_main_11"),
            type: "error",
          });
        }
      }
    },
    gohome() {
      this.$router.push("/");
    },
    passChange() {
      this.password_type = this.password_type ? false : true;
    },
    surepassChange() {
      this.sure_password_type = this.sure_password_type ? false : true;
    },
    // register() {
    //   this.registerFlag = true;
    // },

    agreeTerms() {
      this.agreement_check = true;
      this.emailCodedialog = false;
    },
  },
  created() {
    if (this.$route.query.shareUserName) {
      this.shareUserName = this.$route.query.shareUserName;
    }
    if (this.$route.query.shareEmail) {
      this.shareEmail = this.$route.query.shareEmail;
    }
    if (this.$route.query.email) {
      this.shareEmail = this.$route.query.email;
    }
    console.log(this.$route.query);

    if (this.$route.query.invitationCode) {
      this.invitationCode = this.$route.query.invitationCode;
    }
    // this.resendEmail();
  },
};
</script>

<style lang="less">
.register_page {
  // padding: 110px;
  height: 100%;
  overflow: hidden;

  .login_flex {
    display: flex;
    box-shadow: 0 0 5px #ccc;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    // top: 150px;
    top: 50%;
    transform: translateY(-50%);
    .left_login_pic {
      width: 60%;
      position: relative;
      img {
        // width: 579px;
        width: 91%;
      }
      .reg_tit {
        position: absolute;
        left: 50px;
        // bottom: 190px;
        bottom: 150px;
        font-size: 40px;
        color: #fff;
        font-weight: bold;
        width: 64%;
      }
      .reg_tit_zh {
        bottom: 200px;
      }
    }

    .right_login_sub {
      width: 50%;
      padding: 55px 40px 0 10px;
      .app_top_step {
        display: none;
      }
      .top_log {
        text-align: right;
        cursor: pointer;
        .app_log {
          display: none;
        }
        img {
          width: 171px;
        }
      }
      .login_tit {
        margin: 50px 0 0px 0;
        h1 {
          font-size: 32px;
          margin-bottom: 15px;
          font-weight: bold;
        }
        p {
          font-size: 16px;
          color: #aaa8a8;
        }
      }
      .form_login {
        margin-top: 46px;
        .form_li {
          margin-bottom: 30px;
          position: relative;
          .form-label {
            color: #aaa8a8;
          }
          .login_input {
            width: 363px;
            height: 56px;
            padding-right: 43px;
          }
          .password_p {
            position: relative;
            .eye {
              position: absolute;
              width: 30px;
              right: 150px;
              top: 15px;
              cursor: pointer;
            }
          }
          .email_validate_style,
          .pass_validate_style,
          .sure_pass_validate_style {
            color: red;
          }
          .timeEnd {
            position: absolute;
            right: 150px;
            top: 47px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            color: #ccc;
            padding: 2px 14px;
            border-radius: 21px;
            font-size: 14px;
          }
          .timeEnd_origin {
            color: rgb(247, 164, 3);
            cursor: pointer;
          }
        }

        .reg_timeEnd {
          width: 360px;
          background: rgb(254, 246, 230);
          padding: 10px 38px;
          margin-bottom: 10px;
          color: rgba(0, 0, 0, 0.36);
          font-size: 13px;
          text-align: center;
        }
        .submit {
          // margin-top: 52px;
          margin-bottom: 10px;
          .el-checkbox {
            width: 363px;
            background: rgb(254, 246, 230);
            padding: 10px;
            margin-bottom: 10px;
            p {
              margin: 0;
              color: rgba(0, 0, 0, 0.36);
              white-space: break-spaces;
              font-size: 13px;
              span {
                color: rgb(247, 164, 3);
              }
            }
          }
          .el-checkbox__inner {
            border-radius: 10px;
            border-color: #f7a403;
            background: transparent;
          }
          // .el-checkbox__inner:hover {
          //   // background: #f7a403;
          //   border-color: #f7a403;
          // }
          .is-focus {
            border-color: #f7a403;
          }
          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background: #f7a403;
            border-color: #f7a403;
          }
          .el-checkbox__input.is-checked + .el-checkbox__label {
            color: rgba(247, 164, 3, 1);
          }
          button {
            width: 363px;
            padding: 10px 0;
            color: #fff;
            font-size: 26px;
            border-radius: 5px;
            background: rgba(247, 164, 3, 1);
            border: 0;
          }
          .btn_ccc {
            color: #fff;
            background: rgba(0, 0, 0, 0.2);
            // border-color: rgba(0, 0, 0, 0.2);
            border: none;
          }
          .btn_ccc:focus {
            border: 0;
            outline: none;
          }
        }
        .en_submit {
          .el-checkbox {
            // width: 384px;
            display: flex;
            align-items: center;
          }
        }
        .register {
          display: flex;
          justify-content: space-between;
          width: 362px;
          color: #aaa8a8;
          cursor: pointer;
          font-size: 14px;
        }
        .fot_email {
          margin-top: 30px;

          p {
            margin: 0;
            font-size: 13px;
            color: rgb(142, 142, 142);
          }
        }
      }
    }
    .agreement_box {
      padding: 55px 30px 0 0;
      .login_tit {
        margin: 40px 0 0 0;
        h1 {
          margin-bottom: 25px;
        }
      }
      .agreement_text {
        p {
          font-size: 14px;
          margin: 0;
          line-height: 30px;
          color: rgb(142, 142, 142);
          text-indent: 2em;
          .blank {
            color: #000;
            font-weight: bold;
          }
        }
        .tit_p {
          text-indent: 0;

          .blank {
            text-indent: 0;
          }
        }
      }
      .agreement {
        height: 500px;
        overflow-y: scroll;
        padding: 0 20px;
        .hed_tit {
          background: transparent;
          padding: 20px 0 0;
          .centerWidth {
            width: 100% !important;
          }
          h1 {
            font-size: 24px;
            color: #000;
          }
        }
        .terms_list {
          padding: 0;
          .centerWidth {
            width: 100% !important;
          }
          ul {
            li {
              margin: 20px 0;
              h2 {
                font-size: 20px;
              }
              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .fot_btn {
      text-align: center;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      button {
        border: 0;
        width: 200px;
        height: 56px;
        color: #fff;
        border-radius: 5px;
      }
      .no {
        background: rgba(0, 0, 0, 0.2);
      }
      .yes {
        background: rgba(247, 164, 3, 1);
      }
    }
    .resendEmail_sub {
      .form_login {
        margin-top: 50px;
      }
    }
  }
  .alert {
    position: fixed;
    top: 30px;
    right: 50px;
    // transform: translateX(-50%);
    min-width: 200px;
    background: #fef0f0;
    padding: 10px 20px;
    color: #f56c6c;
  }
  .login_dialog {
    ::v-deep .el-dialog__header {
      text-align: left;
      padding: 30px 35px;
      border-bottom: 1px solid #ccc;
      .el-dialog__title {
        font-size: 29px;
        font-weight: bold;
      }
    }
    ::v-deep .el-dialog--center {
      top: 15%;
    }
    .gohomebtn {
      background: #f7a403;
      width: 60%;
      color: #fff;
      padding: 17px 0;
      border: 0;
      font-size: 22px;
    }
    .register_hint {
      font-size: 22px;
      word-break: break-word;
    }
    .login_p {
      font-size: 16px;
      margin-top: 20px;
      span {
        color: rgb(77, 169, 255);
        cursor: pointer;
      }
    }
    .terms_dialog {
      .el-dialog {
        width: 622px;
        margin-top: 30vh !important;
        border-radius: 10px;
      }
      .el-dialog__header {
        padding: 50px 40px 30px;
        border-bottom: 2px solid #f1f1f1;
        .el-dialog__title {
          font-size: 28px;
          color: #000;
        }
      }
      .el-dialog__body {
        font-size: 22px;
        color: rgba(0, 0, 0, 0.64);
        padding: 30px 40px 90px;
        word-break: break-word;
      }
      .el-dialog__footer {
        text-align: center;
        padding-bottom: 50px;

        button {
          width: 180px;
          height: 55px;
          background: transparent;
          border: 0;
          font-size: 22px;
        }
        .terms_cancel {
          background: rgba(0, 0, 0, 0.06);
          color: #999;
          margin-right: 80px;
        }
        .agree_btn {
          background: rgb(247, 164, 3);
          color: #fff;
        }
      }
    }
  }
}
// PC--适用于13-15.6英寸的
@media screen and (max-width: 1800px) {
  .register_page .login_flex {
    width: 1095px;
    top: 50%;
    // left: 50px;
    transform: translateY(-50%);
    .left_login_pic img {
      width: 100%;
    }
    .right_login_sub {
      padding-top: 20px;
      padding-left: 50px;
      .form_login .form_li .password_p .eye {
        right: 111px;
      }
    }
  }
  .register_page .login_flex .left_login_pic {
    width: 50%;
  }
  .register_page .login_flex .right_login_sub .form_login .form_li .timeEnd {
    right: 114px;
  }
  .register_page .login_flex .right_login_sub .form_login .reg_timeEnd {
    width: 360px;
  }
  .register_page .login_flex .right_login_sub .login_tit {
    margin: 24px 0 0px 0;
  }
  .register_page .login_flex .left_login_pic .reg_tit {
    bottom: 140px;
    width: 74%;
  }
  .register_page .login_flex .left_login_pic .reg_tit_zh {
    bottom: 185px;
  }
}
@media screen and (max-width: 912px) {
  .register_page {
    margin: 0;
    .login_flex {
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      position: relative;
      top: 0;
      left: 0;
      transform: none;

      .left_login_pic {
        display: none;
      }
      .right_login_sub {
        width: 100%;
        padding: 70px 35px 100px;
        .form_login .reg_timeEnd {
          width: 100%;
          padding: 10px 16px;
        }
        .app_top_step {
          display: flex;
          justify-content: space-between;
          position: relative;
          top: -20px;
          font-size: 13px;
          color: #aaa8a8;
          .red {
            color: rgb(247, 164, 3);
          }
          .go_login {
            // border: ;
            background: rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            display: inline-block;
            width: 56px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            cursor: pointer;
          }
        }
        .top_log {
          .pc_log {
            display: none;
          }
          .app_log {
            display: block;
            width: 110px;
            margin: 0 auto;
          }
        }
        .login_tit {
          margin: 10px 0 50px 0;
          text-align: center;
          p {
            font-size: 14px;
          }
        }
        .form_login {
          .form_li {
            .form-label {
              display: none;
            }
            .login_input {
              width: 100%;
              height: 56px;
              border: 0;
              border-bottom: 1px solid #ccc;
              border-radius: 0;
              font-size: 14px;
            }
            .password_p {
              .eye {
                // left: 290px;
                right: 0;
              }
            }
          }
          .submit {
            margin-top: 20px;
            button {
              width: 100%;
              border-radius: 50px;
              font-size: 22px;
              padding: 12px 0;
              font-size: 16px;
            }
            .el-checkbox {
              width: 100%;
              display: flex;
              align-items: center;
              p {
                white-space: break-spaces;
              }
              .el-checkbox-button__inner,
              .el-checkbox__input {
                vertical-align: top;
              }
            }
          }
          .register {
            width: 100%;
            display: none;
          }
        }
      }

      .agreement_box {
        background: rgba(0, 0, 0, 0.3);
        padding: 0;
        position: fixed;
        top: 0;
        height: 100%;
        .agreement {
          height: 80%;
        }
        .agreement_text {
          padding: 0 20px;
          p {
            line-height: 25px;
          }
        }
        .none {
          display: none;
        }
        .agreement_content {
          background: #fff;
          height: 90%;
          position: absolute;
          bottom: 0;
          width: 100%;

          border-radius: 10px 10px 0 0;

          .login_tit {
            margin: 24px 0;
            h1 {
              font-size: 18px;
            }
          }
          .fot_btn {
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            background: #fff;
            button {
              background: transparent;
              height: auto;
              padding: 10px 0;
              width: 100%;
              height: 50px;
              color: rgba(0, 0, 0, 0.2);
            }
            .yes {
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              color: #f7a403;
            }
          }
        }
      }
      .resendEmail_sub {
        // padding-bottom: 100px;
        .form_login .form_li .timeEnd {
          top: 13px;
          right: 10px;
        }
      }
    }
    ::v-deep .login_dialog {
      .el-dialog--center {
        width: 95% !important;
        .el-dialog__body {
          padding-bottom: 0;
        }
        .el-dialog__header {
          padding: 15px 20px;
          .el-dialog__title {
            font-size: 18px;
          }
        }
        .register_hint {
          font-size: 16px;
        }
        .gohomebtn {
          width: 50%;
          font-size: 16px;
          padding: 13px 0;
        }
        .login_p {
          margin-bottom: 0;
        }
      }
    }
    .login_dialog .terms_dialog {
      .el-dialog {
        width: 90% !important;
        margin-top: 27vh !important;
        .el-dialog__header {
          padding: 35px 30px 20px;
          .el-dialog__title {
            font-size: 22px;
          }
        }
        .el-dialog__body {
          padding: 30px 30px 40px;
          font-size: 16px;
        }
        .el-dialog__footer {
          button {
            width: 120px;
            height: 40px;
            font-size: 16px;
          }
          .terms_cancel {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
// @media screen and (max-width: 375px) {
//   .register_page .login_flex .agreement_box .agreement{
//     height: 320px;
//   }
// }
</style>
