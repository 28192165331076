import { login, logout, search, register, shareRegister, verifyregister } from './userlogin'
import { versionNew, checkCoupon, deviceList, getinituser, offline, reSendEmail, verifyEmail, createOrder, paypalreturn, checkreturn, checkcancel, paycancel, getshare,getcount,getcountin } from './memberCenter'
export default {
    // 首页下载版本号
    versionNew,
    // 登录
    login,
    logout,
    search,
    register,
    shareRegister,
    checkCoupon,
    verifyregister,
    // 我的会员
    deviceList,
    getinituser,
    offline,
    // 创建订单支付
    createOrder,
    paypalreturn,
    checkreturn,
    checkcancel,
    // 设备管理
    reSendEmail,
    verifyEmail,
    // 取消支付
    paycancel,
    getshare,
    getcount,
    getcountin
}