<template>
  <div class="header" ref="header" id="header">
    <div :class="scroll_height == 0 ? '' : 'fixed_header'">
      <div
        class="centerWidth"
        :class="
          $route.path == '/memberCenter' ||
          $route.path == '/equipment' ||
          $route.path == '/purchaseHistory' ||
          $route.path == '/terminalRevenue'
            ? 'fullScreen'
            : ''
        "
      >
        <nav class="navbar navbar-expand-lg navbar-light bg-light head_con">
          <div class="container-fluid">
            <a class="navbar-brand logo" href="/">
              <img src="../../assets/image/top-logo.jpg" alt="" />
            </a>
            <a
              v-if="$route.path == '/memberCenter'"
              href="#/MacDownload"
              @click="navBar('MacDownload', 'download')"
              class="download_app"
            >
              <img src="@/assets/image/memberCenter/download.png" alt="" />
              {{ $t("lang.Downloading_APP") }}</a
            >

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
              ref="toggler"
            >
              <!-- <span class="navbar-toggler-icon"></span> -->
              <img src="@/assets/image/index/nav.png" alt="" />
            </button>
            <div
              class="collapse navbar-collapse flexgrow nav_bar"
              id="navbarText"
              ref="toggle"
            >
              <ul
                class="navbar-nav me-auto mb-2 mb-lg-0"
                v-if="
                  $route.path != '/memberCenter' &&
                  $route.path != '/equipment' &&
                  $route.path != '/purchaseHistory' &&
                  $route.path != '/terminalRevenue'
                "
              >
                <li class="nav-item" @click="navBar">
                  <a
                    class="nav-link"
                    :class="
                      scroll_height <= 490 && $route.path == '/' ? 'active' : ''
                    "
                    aria-current="page"
                    href="/"
                    >{{ $t("lang.navBar.Home") }}</a
                  >
                </li>
                <li class="nav-item" @click="navBar('select-hyperlink')">
                  <a
                    class="nav-link"
                    :class="
                      scroll_height >= 490 &&
                      scroll_height < 1914 &&
                      $route.path == '/'
                        ? 'active'
                        : ''
                    "
                    href="/#select-hyperlink"
                    >{{ $t("lang.navBar.Advantage") }}</a
                  >
                </li>
                <li class="nav-item" @click="navBar('MacDownload')">
                  <a
                    class="nav-link"
                    :class="
                      $route.name.includes('download') ||
                      $route.name.includes('commonProblem') ||
                      (scroll_height >= 1914 && scroll_height < 2610)
                        ? 'active'
                        : ''
                    "
                    href="/#MacDownload"
                    >{{ $t("lang.navBar.Download") }}</a
                  >
                </li>
                <!-- <li class="nav-item" @click="navBar">
                  <a
                    class="nav-link"
                    :class="
                      scroll_height >= 2856 &&
                      scroll_height < 3850 &&
                      $route.path == '/'
                        ? 'active'
                        : ''
                    "
                    href="/#PriceIndex"
                    >{{ $t("lang.navBar.Price") }}</a
                  >
                </li> -->
                <li class="nav-item" @click="navBar('UserEvaluation')">
                  <a
                    class="nav-link"
                    :class="
                      scroll_height >= 2610 && $route.path == '/'
                        ? 'active'
                        : ''
                    "
                    href="/#UserEvaluation"
                    >{{ $t("lang.navBar.Evaluate") }}</a
                  >
                </li>
              </ul>
            </div>
            <!-- ---------------------==================== -->
            <LangueSel :scrollHeight="scroll_height" />
            <!-- ---------------------==================== -->
            <div
              class="login"
              v-if="
                $route.path != '/memberCenter' &&
                $route.path != '/equipment' &&
                $route.path != '/purchaseHistory' &&
                $route.path != '/terminalRevenue'
              "
            >
              <router-link to="/login" v-if="!tokenFlag">{{
                $t("lang.login.Sign_in")
              }}</router-link>
              <router-link to="/memberCenter" v-if="tokenFlag" class="member">
                <img src="../../assets/image/icon-head.png" alt="" />
              </router-link>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <!-- <div>
      <HeaderModel />
    </div> -->
  </div>
</template>

<script>
// import HeaderModel from "../HeaderModel.vue";
import LangueSel from "./LangueSel.vue";
export default {
  data() {
    return {
      scroll_height: 0,
      tokenFlag: false,
    };
  },
  watch: {
    $route() {
      if (!sessionStorage.getItem("token")) {
        this.tokenFlag = false;
      }
    },
  },
  methods: {
    handleScroll() {
      this.scroll_height = Math.floor(
        document.documentElement.scrollTop || document.body.scrollTop
      );
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;

      var Agents = [
        "Android",
        "iPhone",

        "SymbianOS",
        "Windows Phone",

        "iPad",
        "iPod",
      ];

      var flagPc = true;

      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flagPc = false;

          break;
        }
      }

      return flagPc;
    },

    //console.log(flag) //true为PC端，false为手机端
    navBar(url, key) {
      this.$router.push("/#" + url);
      let flagZt = this.IsPC();
      if (!flagZt && key != "download") {
        this.$refs.toggler.click();
      }
    },
  },
  created() {
    if (this.$route.hash == "#MacDownload") {
      // this.navBar("MacDownload");
      location.href = "#MacDownload";
    }
  },

  mounted() {
    if (this.$route.query.lang != undefined) {
      this.$i18n.locale = this.$route.query.lang;
      localStorage.setItem("lang", this.$route.query.lang);
    }
    if (sessionStorage.getItem("token") != undefined) {
      this.tokenFlag = true;
    } else {
      this.tokenFlag = false;
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  components: { LangueSel },
};
</script>

<style lang="less" scoped>
.fixed_header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
}
.header {
  .logo {
    img {
      width: 180px;
    }
  }
  .bg-light {
    background: transparent !important;
  }
  .container-fluid {
    position: relative;
    padding: 0;
  }
  .d-flex {
    align-items: center;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #fff9ee;
  }
  .activeLang {
    background: #fff9ee;
  }
  .navbar-collapse {
    z-index: 999;
    background: #fff;
  }
  .nav_bar {
    .nav-item {
      a {
        color: #000;
        display: inline-block;
        width: 100px;
        text-align: center;
      }
      a:hover {
        color: #000;
        font-weight: bold;
      }
    }
  }
  .login {
    margin-left: 10px;
    a {
      // color: #000;
      font-size: 14px;
      color: #ffc107;
    }
    .member {
      margin-left: 10px;
      img {
        width: 20px;
        vertical-align: sub;
      }
    }
  }
  .download_app {
    position: absolute;
    top: 12px;
    right: 62px;
    display: flex;
    align-items: center;
    color: #000;
    font-weight: bold;
    img {
      width: 20px;
    }
  }
}
// PC端--start
@media screen and (min-width: 1400px) {
  .fixed_header {
    height: 80px;
  }
  .header {
    height: 80px;
    .head_con {
      position: relative;
      top: 4px;
    }
    .logo {
      img {
        width: 180px;
      }
    }
    .nav_bar {
      .nav-item {
        font-size: 18px;
        margin: 0 12px;
        a {
          color: #000;
        }
        .active {
          font-weight: bold;
          position: relative;
        }
        .active::after {
          content: "";
          width: 25px;
          height: 3px;
          background: #f7a403;
          position: absolute;
          bottom: 0;
          right: 50%;
          display: inline-block;
          transform: translateX(50%);
        }
      }
    }
    .download_app {
      right: 127px;
    }
  }
}
@media screen and(min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    position: relative;
  }
  .navbar-expand-lg .navbar-nav {
    position: absolute;
    right: 40px;
  }
}
// PC端--end
// PC--适用于13-15.6英寸的
@media screen and (max-width: 1800px) {
  .header .download_app {
    right: 120px;
  }
}
// 移动端--start
@media screen and (max-width: 820px) {
  .header {
    height: 60px;
    .logo {
      margin-left: 10px;

      img {
        width: 130px;
      }
    }
    .bg-light {
      // padding-bottom: 0;`
    }
    .navbar-collapse {
      position: relative;
      top: 10px;
    }
    .download_app {
      right: 62px;
    }
  }

  .nav_bar {
    .nav-item {
      width: 100%;
      text-align: center;
      line-height: 45px;
      border-bottom: 1px solid #ccc;
      font-weight: bold;
    }
    .mb-2 {
      margin: 0 !important;
    }
  }
  .login {
    position: fixed;
    right: 158px;
    top: 15px;
    .member {
      img {
        width: 30px;
      }
    }
  }
}

.navbar-toggler {
  border: 0;
  padding: 0;
  outline: none;
  img {
    width: 30px;
    margin-right: 5px;
  }
}
.navbar-toggler:focus {
  box-shadow: none;
}
// 移动端--end
</style>
