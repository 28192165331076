import axios from "axios";
// import qs from 'qs'
// axios.defaults.baseURL = "http://192.168.31.249:9999/"
const request = axios.create({
  // baseURL: 'http://192.168.31.249:9999/'
  // baseURL: 'http://192.168.31.44:9999/'
  // baseURL: "https://server.hyperlinkvpn.com/v1/",
  baseURL: 'https://api.apphyper.com/' //正式环境
  // baseURL: 'http://192.168.31.175:9999/'
  //  baseURL: 'http://192.168.31.200:8/'
});
// request拦截器
request.interceptors.request.use(
  (config) => {
    let equipment = "";
    let token = sessionStorage.getItem("tokenKey");

    if (token) {
      equipment = token;
    } else {
      equipment = sessionStorage.getItem("token");
    }
    config.headers["Authorization"] = equipment;
    config.headers["language"] = localStorage.getItem("lang") || 'zh-CN';
    return config;
  },
  (error) => {
    // Do something with request error
    console.log("请求错误信息为：" + error); // for debug
    return Promise.reject(error);
  }
);
export default request;