import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/common.less";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import api from './api/index.js';

Vue.prototype.$api = api
//引入中英文切换插件vue-i18n
import VueI18n from "vue-i18n";

Vue.use(VueI18n); // 挂载
const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "zh-CN", // 语言标识
  messages: {
    "zh-CN": require("./lang/zh"), // 通过require引入中文语言包
    "en-US": require("./lang/en"), // 通过require引入英文语言包
  },
});

Vue.config.productionTip = false;
// router.beforeEach((to, from, next) => {
//   console.log(to);
//   const token = localStorage.getItem("token")
//   if (token) {
//     next()
//   } else {
//     if (to.path === '/') {  // 在为空的情况下，我们路由跳转的是'/' 就放行。'/'代表我们登录页
//       next()
//     } else if (to.path === '/login' || to.path === "/register" || to.path === '/MacDownload' || to.path === '/select-hyperlink' || to.path === '/UserEvaluation') {  // 在为空的情况下，我们路由跳转的是'/' 就放行。'/'代表我们登录页
//       next()
//     } else {           // 在为空的情况下，访问的不是'/'， 就直接跳转到'/'
//       next({
//         path: '/login'
//       })
//     }

//   }
// })
import {
  Dialog,
  Checkbox,
  CheckboxGroup,
  Button,
  Icon,
  Loading,
  MessageBox,
  Message,
  Notification,
  Popconfirm,
  DatePicker,
  Input
} from 'element-ui';
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Popconfirm);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Icon);
Vue.use(DatePicker);
import locale from 'element-ui/lib/locale'
import en from 'element-ui/lib/locale/lang/en'
// 设置语言
locale.use(i18n.locale == 'en-US' ? en : '')
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
