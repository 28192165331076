<template>
  <!-- 语言切换 -->
  <div
    class="nav-item dropdown langue_sel"
    :class="$route.path == '/memberCenter' ? 'mem_lang' : ''"
    v-show="!$route.path.includes('downloadExplain')"
  >
    <div
      :class="
        $route.path == '/memberCenter' ||
        $route.path == '/equipment' ||
        $route.path == '/purchaseHistory' ||
        $route.path == '/terminalRevenue'
          ? 'langue_pc'
          : ''
      "
    >
      <a
        class="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="../../assets/image/Vector.jpg" alt="" />
        {{ langKey == "en-US" ? "English" : "繁体中文" }}
      </a>
      <!-- :style="scrollHeight > 0 ? 'display:none' : ''" -->
      <ul class="dropdown-menu langue_ul" aria-labelledby="navbarDropdown">
        <li>
          <a
            class="dropdown-item"
            :class="langKey == 'zh-CN' ? 'activeLang' : ''"
            href="JavaScript:;"
            @click="langChange('zh-CN')"
            >繁体中文</a
          >
        </li>
        <li>
          <a
            class="dropdown-item"
            href="JavaScript:;"
            :class="langKey == 'en-US' ? 'activeLang' : ''"
            @click="langChange('en-US')"
            >English</a
          >
        </li>
      </ul>
    </div>
    <div
      :class="
        $route.path == '/memberCenter' ||
        $route.path == '/equipment' ||
        $route.path == '/purchaseHistory' ||
        $route.path == '/terminalRevenue'
          ? 'langue_app'
          : 'hide'
      "
    >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
        data-bs-target="#exampleModal"
        @click="modelChange"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <!-- <transition-group name="lyric"> -->
    <div class="mode_memberCenter" v-show="mode_memberCenter_flag">
      <div class="mode" @click="mode_memberCenter_flag = false"></div>
      <div class="mode_select">
        <ul>
          <li @click="langueChange">
            <span class="tit">{{ $t("lang.langSel.languageChoice") }}</span>
            <span class="size_text">
              {{ langKey == "en-US" ? "English" : "繁体中文" }}></span
            >
          </li>
          <router-link
            to="/equipment"
            v-if="
              $route.path == '/memberCenter' ||
              $route.path == '/purchaseHistory' ||
              $route.path == '/terminalRevenue'
            "
            tag="li"
          >
            <span class="tit">{{
              $t("lang.langSel.lang_Device_management")
            }}</span>
            <span class="size_text">
              <span v-if="deviceList_length != 0"
                >{{ deviceList_length }} {{ $t("lang.langSel.sets") }}</span
              >
              ></span
            >
          </router-link>
          <router-link
            to="/memberCenter"
            v-if="
              $route.path == '/equipment' ||
              $route.path == '/purchaseHistory' ||
              $route.path == '/terminalRevenue'
            "
            tag="li"
          >
            <span class="tit">{{ $t("lang.langSel.Mymember") }}</span>
            <span class="size_text">></span>
          </router-link>
          <router-link
            to="/purchaseHistory"
            v-if="
              $route.path == '/equipment' ||
              $route.path == '/memberCenter' ||
              $route.path == '/terminalRevenue'
            "
            tag="li"
          >
            <span class="tit"> {{ $t("lang.purchaseHistory.Purchase") }} </span>
            <span class="size_text">></span>
          </router-link>
          <router-link
            to="/terminalRevenue"
            v-if="
              ($route.path == '/equipment' ||
                $route.path == '/memberCenter' ||
                $route.path == '/purchaseHistory') &&
              userDatas.invitationCode
            "
            tag="li"
          >
            <span class="tit">
              {{ $t("lang.terminalRevenue.Invitation")
              }}{{ userDatas.invitationCode }}
            </span>
            <span class="size_text">></span>
          </router-link>
        </ul>
        <div class="out_login_li" @click="logout">
          <button>{{ $t("lang.langSel.sign_out") }}</button>
        </div>
      </div>
    </div>
    <!-- </transition-group> -->
    <div class="mode_memberCenter" v-show="langue_flag" :key="1">
      <div class="mode" @click="langue_flag = false"></div>
      <div class="mode_select">
        <ul>
          <li @click="langChange('zh-CN')">
            <div class="form-check">
              <label class="form-check-label" for="flexRadioDefault1">
                繁體中文
              </label>
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                :checked="langKey == 'en-US' ? false : true"
              />
            </div>
          </li>
          <li @click="langChange('en-US')">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                :checked="langKey == 'en-US' ? true : false"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                English
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      langKey: localStorage.getItem("lang"),
      mode_memberCenter_flag: false,
      langue_flag: false,
      //   activeRoute: "/",
      deviceList_length: 0,
      userDatas: "",
    };
  },
  props: ["scrollHeight"],
  watch: {
    $route: {
      handler() {
        this.mode_memberCenter_flag = false;
      },
      deep: true,
    },
  },
  methods: {
    // 退出登录
    logout() {
      this.$api
        .logout()
        .then((res) => {
          if (res.data.code == 0) {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userData");

            this.$notify({
              title: this.$t("lang.alert.succeed"),
              message: this.$t("lang.alert.Exit_successfully"),
              type: "success",
            });
            setTimeout(() => {
              this.$router.push("/");
            }, 500);
          } else {
            this.$notify({
              title: this.$t("lang.alert.Error"),
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          sessionStorage.removeItem("token");
          setTimeout(() => {
            this.$router.push("/");
          }, 500);
          console.log(error);
        });
    },
    langChange(lang) {
      // this.$router.push(this.$route.fullPath);
      // location.href = this.$route.fullPath;
      this.langKey = lang;
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
      if (
        this.$route.name == "memberCenter" ||
        this.$route.name == "purchaseHistory"||
        this.$route.name == "terminalRevenue"
      ) {
        // this.$router.go(0);
        if (
          this.$route.query.token != undefined &&
          this.$route.query.typePc != undefined
        ) {
          this.$router.push(
            "/memberCenter?token=" +
              this.$route.query.token +
              "&typePc=" +
              this.$route.query.typePc +
              "&language=" +
              lang
          );
          this.$router.go(0);
        } else {
          this.$router.go(0);
        }
      }
    },
    modelChange() {
      this.mode_memberCenter_flag = true;
      this.deviceList_length = Number(
        localStorage.getItem("deviceList_length")
      );
    },
    langueChange() {
      this.langue_flag = true;
      this.mode_memberCenter_flag = false;
    },
  },
  mounted() {
    if (this.$route.query.lang != undefined) {
      this.langKey = this.$route.query.lang;
    }
    setTimeout(() => {
      if (sessionStorage.getItem("userData")) {
        this.userDatas = JSON.parse(sessionStorage.getItem("userData"));
      }
    }, 500);
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.header {
  .langue_sel {
    .nav-link {
      font-weight: bold;
      color: rgb(139, 139, 139);
      img {
        width: 18px;
        vertical-align: sub;
      }
    }
  }
  .hide {
    display: none;
  }
}
// PC端
@media screen and(min-width: 992px) {
  .langue_sel {
    display: flex;
    .langue_pc {
      display: block;
    }
    .langue_app {
      display: none;
    }
    .langue_ul {
      width: 171px;
      padding: 0;
      left: -50px;
      top: 38px;

      li {
        line-height: 48px;
        // height: 48px;
        margin: 15px 0;
        a {
          padding: 0;
          text-align: center;
        }
      }
    }
  }
  .langue_sel::before {
    content: "";
    width: 2px;
    height: 17px;
    background: #ccc;
    display: inline-block;
    position: relative;
    top: 5px;
    right: 28px;
  }
  .mode_memberCenter {
    display: none;
  }
}
// 移动端
@media screen and (max-width: 820px) {
  .langue_sel {
    position: absolute;
    right: 45px;
    top: 10px;
    font-size: 14px;
    z-index: 99;

    .navbar-toggler:focus {
      box-shadow: none;
    }
    .langue_ul {
      padding: 0;
      left: -20px;
      top: 38px;
      position: fixed;
      left: 0;
      top: 58px;
      width: 100%;
      border: 0;
      border-radius: 0;

      li {
        line-height: 48px;
        padding: 15px 0;
        border-bottom: 1px solid #ccc;
        a {
          padding: 0;
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
  .langue_pc {
    display: none;
  }
  .langue_app {
    display: block;
    position: relative;
    right: -30px;
    .navbar-toggler {
      display: block;
      border: 0;
      padding: 0;
      outline: none;
    }
    // .langue_ul {
    //   padding: 0;
    //   left: -20px;
    //   top: 38px;
    //   position: fixed;
    //   left: 0;
    //   top: 58px;
    //   width: 100%;
    //   border: 0;
    //   border-radius: 0;

    //   li {
    //     line-height: 48px;
    //     padding: 15px 0;
    //     border-bottom: 1px solid #ccc;
    //     a {
    //       padding: 0;
    //       text-align: center;
    //       font-size: 14px;
    //     }
    //   }
    // }
  }
  .mode_memberCenter {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all 1s;

    .mode {
      position: fixed;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
    }

    .mode_select {
      position: absolute;
      bottom: 0;
      width: 95%;
      margin: 0 2.5%;
      font-size: 18px;
      .size_text {
        font-size: 14px;
        color: #666;
      }
      ul {
        padding: 0 10px;
        background: #fff;
        border-radius: 5px;
        li {
          padding: 22px 18px;
          border-bottom: 1px solid #ccc;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .form-check {
            width: 100%;
            padding: 0;
            .form-check-input {
              float: right;
            }
            .form-check-label {
              width: 100%;
            }
          }
        }
      }
      .out_login_li {
        margin: 20px 0;
        width: 100%;

        button {
          width: 100%;
          padding: 18px 0;
          background: #fff;
          outline: none;
          border: 0;
          color: rgb(247, 164, 3);
          border-radius: 5px;
        }
      }
    }
  }
}
.mem_lang.langue_sel::before {
  display: none;
}
</style>
