<template>
  <div class="terminalRevenue">
    <!-- <div class="loading_t" v-if="initloading" v-loading="initloading"></div> -->

    <div class="revenue_con">
      <!-- <LeftMember :userData="userData" /> -->
      <div class="right_payment_method">
        <div class="date-picker">
          <el-date-picker
            v-model="value2"
            type="daterange"
            align="right"
            unlink-panels
            :range-separator="$t('lang.terminalRevenue.to')"
            :start-placeholder="$t('lang.terminalRevenue.start_date')"
            :end-placeholder="$t('lang.terminalRevenue.ending_date')"
            :picker-options="pickerOptions"
            popper-clas="date_picker_box"
            :default-time="['00:00:00', '23:59:59']"
            @change="getdatepicker"
          >
          </el-date-picker>
          <el-input
            style="width: 300px; margin-left: 20px"
            v-model="invitationCode"
            :placeholder="$t('lang.InvitationLookup.PleaseCode')"
          ></el-input>
          <el-button @click="getinvitation">{{
            $t("lang.InvitationLookup.search")
          }}</el-button>
        </div>
        <div class="earnings_box">
          <div class="top_all_num">
            <div class="left_number_people">
              <div class="top_logo">
                <img src="@/assets/image/memberCenter/icon_people.png" alt="" />
              </div>
              <div>
                <p class="recommend_num">
                  {{ $t("lang.terminalRevenue.Recommended") }}
                </p>
                <h4 class="peop_num">
                  {{ terminalData.all }}{{ $t("lang.terminalRevenue.people") }}
                </h4>
              </div>
              <p class="all_text">All</p>
            </div>
            <div class="right_money_all">
              <div class="bck_money">
                <img
                  src="@/assets/image/memberCenter/bg_money_all.png"
                  alt=""
                />
              </div>
              <p class="all_text">
                {{ $t("lang.terminalRevenue.Accumulated_income") }}
              </p>
              <h4 class="all_money">
                {{ terminalData.income }}{{ $t("lang.terminalRevenue.RMB") }}
              </h4>
            </div>
          </div>
          <div class="bottom_terminal">
            <ul>
              <li>
                <div class="left_img">
                  <img src="@/assets/image/memberCenter/Android.png" alt="" />
                </div>
                <div class="right_num">
                  <p class="peop_num">
                    {{ terminalData.android
                    }}{{ $t("lang.terminalRevenue.people") }}
                  </p>
                  <p class="eq">Android</p>
                </div>
              </li>
              <li>
                <div class="left_img">
                  <img src="@/assets/image/memberCenter/IOS.png" alt="" />
                </div>
                <div class="right_num">
                  <p class="peop_num">
                    {{ terminalData.ios
                    }}{{ $t("lang.terminalRevenue.people") }}
                  </p>
                  <p class="eq">Ios</p>
                </div>
              </li>
              <li>
                <div class="left_img">
                  <img src="@/assets/image/memberCenter/Windows.png" alt="" />
                </div>
                <div class="right_num">
                  <p class="peop_num">
                    {{ terminalData.windows
                    }}{{ $t("lang.terminalRevenue.people") }}
                  </p>
                  <p class="eq">Windows</p>
                </div>
              </li>
              <li>
                <div class="left_img">
                  <img src="@/assets/image/memberCenter/Mac.png" alt="" />
                </div>
                <div class="right_num">
                  <p class="peop_num">
                    {{ terminalData.mac
                    }}{{ $t("lang.terminalRevenue.people") }}
                  </p>
                  <p class="eq">Mac</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LeftMember from "@/components/LeftMember.vue";

export default {
  // components: { LeftMember },
  data() {
    return {
      userData: {},
      invitationCode: "",
      pickerOptions: {
        shortcuts: [
          {
            text: this.$t("lang.terminalRevenue.one_week"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t("lang.terminalRevenue.one_month"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t("lang.terminalRevenue.three_months"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      terminalData: "",
    };
  },
  methods: {
    getdatepicker() {
      this.startTime = this.value2[0].getTime();
      this.endTime = this.value2[1].getTime();
    },
    getinvitation() {
      this.getcountin();
    },
    getcountin() {
      this.$api
        .getcountin({
          start: JSON.stringify(this.startTime) || "",
          end: JSON.stringify(this.endTime) || "",
          invitationCode: this.invitationCode || "",
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.terminalData = res.data.data;
          }
        });
    },
  },
  mounted() {
    this.getcountin();
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
    }
  },
};
</script>

<style lang="less" scoped>
// PC端
@media screen and(min-width: 911px) {
  .terminalRevenue {
    height: calc(100% - 80px);
    .revenue_con {
      .right_payment_method {
        //   width: 800px;
        display: inline-block;
        vertical-align: top;
        padding: 0 70px;
        position: relative;
        // left: 354px;
        // top: 60px;
        position: relative;
        left: calc(50% + 200px);
        top: 60px;
        transform: translateX(-50%);
        .date-picker {
          display: flex;
          button {
            color: #fff;
            background: #f7a403;
            margin-left: 20px;
            border: 0;
          }
          input:hover {
            border-color: #f7a403;
          }
        }
        .earnings_box {
          .top_all_num {
            display: flex;
            margin-top: 60px;
            .left_number_people {
              width: 194px;
              height: 225px;
              box-shadow: 0 0 5px#d1d1d1;
              padding: 24px;
              border-radius: 24px;
              .top_logo {
                img {
                  width: 42px;
                }
                margin-bottom: 24px;
              }
              p,
              h4 {
                margin: 0;
              }
              .recommend_num {
                font-size: 14px;
                color: #ccc;
              }
              .peop_num {
                font-size: 24px;
                color: #000;
                font-weight: bold;
              }
              .all_text {
                margin-top: 16px;
                width: 57px;
                height: 32px;
                text-align: center;
                border-radius: 20px;
                line-height: 32px;
                font-size: 14px;
                background: rgba(247, 164, 3, 0.21);
                color: rgb(247, 164, 3);
              }
            }
            .right_money_all {
              width: 398px;
              margin-left: 20px;
              position: relative;
              img {
                width: 100%;
              }
              .all_text {
                position: absolute;
                top: 24px;
                left: 24px;
                color: #fff;
                font-size: 14px;
              }
              .all_money {
                position: absolute;
                left: 24px;
                bottom: 20px;
                font-size: 36px;
                font-weight: bold;
                color: #fff;
              }
            }
          }
          .bottom_terminal {
            margin-top: 57px;
            ul {
              display: flex;
              justify-content: space-between;
              padding: 0;
              li {
                display: flex;
                padding: 24px;
                box-shadow: 0 0 5px #d1d1d1;
                width: 225px;
                border-radius: 24px;
                margin-right: 15px;
                align-items: center;
                p {
                  margin: 0;
                }
                .left_img {
                  img {
                    width: 35px;
                  }
                }
                .right_num {
                  margin-left: 10px;
                }
                .peop_num {
                  font-weight: bold;
                  color: #000;
                }
                .eq {
                  font-size: 12px;
                  color: #ccc;
                }
              }
            }
          }
        }
      }
    }
  }
}
//手机端
@media screen and(max-width:1000px) {
  .terminalRevenue {
    height: calc(100% - 80px);

    .revenue_con {
      .right_payment_method {
        //   width: 800px;
        display: inline-block;
        vertical-align: top;
        padding: 0 20px;
        position: relative;
        left: 0;
        top: 10px;
        width: 100%;
        .earnings_box {
          .top_all_num {
            //   display: flex;
            margin-top: 15px;
            .left_number_people {
              width: 100%;
              height: 100px;
              box-shadow: 0 0 5px #d1d1d1;
              padding: 24px;
              border-radius: 24px;
              display: flex;
              justify-content: space-between;
              .top_logo {
                img {
                  width: 42px;
                }
                margin-bottom: 24px;
              }
              p,
              h4 {
                margin: 0;
              }
              .recommend_num {
                font-size: 14px;
                color: #ccc;
              }
              .peop_num {
                font-size: 24px;
                color: #000;
                font-weight: bold;
              }
              .all_text {
                margin-top: 16px;
                width: 57px;
                height: 32px;
                text-align: center;
                border-radius: 20px;
                line-height: 32px;
                font-size: 14px;
                background: rgba(247, 164, 3, 0.21);
                color: rgb(247, 164, 3);
              }
            }
            .right_money_all {
              width: 100%;
              position: relative;
              top: 20px;
              img {
                width: 100%;
              }
              .all_text {
                position: absolute;
                top: 24px;
                left: 24px;
                color: #fff;
                font-size: 14px;
              }
              .all_money {
                position: absolute;
                left: 24px;
                bottom: 20px;
                font-size: 36px;
                font-weight: bold;
                color: #fff;
              }
            }
          }
          .bottom_terminal {
            margin-top: 34px;
            ul {
              //   display: flex;
              //   justify-content: space-between;
              padding: 0;
              overflow-x: scroll;
              width: 100%;
              li {
                display: inline-flex;
                padding: 24px 20px;

                box-shadow: 0 0 5px #d1d1d1;
                width: 48%;
                border-radius: 24px;
                box-sizing: border-box;
                margin: 1%;
                align-items: center;
                p {
                  margin: 0;
                }
                .left_img {
                  margin-right: 10px;
                  img {
                    width: 35px;
                  }
                }
                .peop_num {
                  font-weight: bold;
                  color: #000;
                }
                .eq {
                  font-size: 12px;
                  color: #ccc;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-date-editor .el-range-separator {
  width: 15%;
}
// PC--适用于13-15.6英寸的
@media screen and (max-width: 1800px) {
  .terminalRevenue
    .revenue_con
    .right_payment_method
    .earnings_box
    .bottom_terminal
    ul
    li {
    width: 176px !important;
  }
}
// 移動端
@media screen and(max-width:1000px) {
  .el-date-editor {
    width: 100% !important;
  }
  .el-date-range-picker__content {
    float: none !important;
    margin: 0;
    width: 100%;
  }
  .el-date-range-picker .el-picker-panel__body {
    min-width: 200px;
  }
  .el-date-range-picker.has-sidebar {
    width: 100%;
  }
  .terminalRevenue
    .revenue_con
    .right_payment_method
    .earnings_box
    .bottom_terminal
    ul
    li {
    width: 48% !important;
  }
}
</style>
