<template>
  <div class="terms">
    <div class="hed_tit">
      <div class="centerWidth">
        <h1>HyperlinkVPN</h1>
        <h1>Terms of Service</h1>
      </div>
    </div>
    <div class="terms_list">
      <div class="centerWidth">
        <ul>
          <li>
            <p>
              This Terms of Service document outlines the terms and conditions
              of use of the VPN service provided by HyperlinkVPN. These terms
              also govern the use and access of HyperlinkVPN content, including
              the HyperlinkVPN application and any software provided by
              HyperlinkVPN.
            </p>
            <p>
              By agreeing to these terms, you also agree to the privacy policy.
            </p>
          </li>
          <li>
            <h2>1. Acceptance</h2>
            <p>
              By accessing content or services, it means that you, on behalf of
              your individual or virtual individual, agree to fully comply with
              these terms and be bound by their laws. These terms constitute a
              legally binding agreement between you and HyperlinkVPN. If you do
              not agree to any part of these terms, you must not use our
              services.
            </p>
            <p>
              By creating an account to use our services, you mean that you are
              eighteen (18) years of age or older or that you are a valid legal
              entity and that the registration information you provide is
              accurate and complete.
            </p>
          </li>
          <li>
            <h2>2. Privacy Policy</h2>
            <p>
              HyperlinkVPN is committed to protecting your privacy and will not
              collect or record the browsing history, traffic destination, data
              content, or DNS queries of subscribers connected to our VPN.
              During your registration, we may collect some sensitive personal
              information, such as your email address. We only collect necessary
              transmission information for websites and services.
            </p>
          </li>
          <li>
            <h2>3. Changes to the terms of service</h2>
            <p>
              HyperlinkVPN may update the terms from time to time without
              notice. If you continue to use HyperlinkVPN's services, content,
              sites, applications, or software after these changes take effect,
              it means that you agree to the revised terms and accept our
              privacy policy.
            </p>
          </li>
          <li>
            <h2>4. Language and content translation</h2>
            <p>
              All our content was originally written in English. Any translation
              of our content is done on the basis of our best efforts. We cannot
              guarantee the accuracy of the translated content. If there is any
              difference between the translated content and the English content,
              the English content shall prevail.
            </p>
          </li>

          <li>
            <h2>5. Acceptable Use Policy</h2>
            <p>
              The HyperlinkVPN service can be accessed from multiple
              countries/regions, so it is your responsibility to evaluate
              whether the use of this website, application, software or service
              complies with local laws and regulations. Whenever you use this
              website, applications, software or services, you should abide by
              these terms and applicable laws, regulations and policies.
            </p>
            <p>
              It is your responsibility to keep your HyperlinkVPN account
              information confidential. You are responsible for all activities
              under your account. If you find or suspect that someone has
              accessed your account without your authorization, we recommend
              that you notify us immediately so that we can revoke your account
              credentials and issue new credentials.
            </p>
            <p>
              HyperlinkVPN aims to provide the best service to all our users. In
              this sense, we ask you not to abuse our content or services. Abuse
              refers to any use, access, or interference with content or
              services that violates terms or applicable laws and regulations.
            </p>
            <p>
              In order to protect the service from being abused or used to harm
              others, HyperlinkVPN reserves the right to take appropriate
              measures. If you abuse the service, HyperlinkVPN can terminate
              your account without any explanation.
            </p>
            <p>When using our services, you agree that you will not:</p>
            <ul class="terms_ul">
              <li>
                Send or transmit unsolicited advertisements or content through
                the Service (ie "spam").
              </li>
              <li>
                Send, post or transmit any illegal, hateful, threatening,
                insulting or defamatory content through the Service;
                infringement of intellectual property rights; infringement of
                privacy; or incitement to violence.
              </li>
              <li>
                Upload, download, publish, copy or distribute any content
                protected by copyright or any other proprietary rights without
                the prior permission of the copyright owner.
              </li>
              <li>
                Upload, download, post, copy or distribute any content that
                contains sexual or explicit descriptions of minors.
              </li>
              <li>
                Engage in any behavior that restricts or prohibits other
                subscribers from using or enjoying the service.
              </li>
              <li>
                Attempts to access, probe, or connect to computing devices
                without proper authorization (ie any form of "hacking").
              </li>
              <li>
                Try to compile, utilize, or distribute a list of IP addresses
                that HyperlinkVPN operates with the service.
              </li>
              <li>
                Use the service for any purpose other than legitimate purposes.
              </li>
            </ul>
          </li>
          <li>
            <h2>6. Disclaimer</h2>
            <p>
              We will work hard to prevent disruption of the website and
              services. However, these are provided on an "as is" and
              "available" basis, and we do not guarantee, whether express or
              implied, the accuracy of any material or information provided
              through this website or service, or its suitability for any
              specific purpose . We do not guarantee that the service will meet
              your requirements, or that the service will be uninterrupted,
              timely, safe or error-free, or that the defect (if any) will be
              corrected. You acknowledge that you are solely responsible for the
              risks of accessing this website and services and make your own
              decisions.
            </p>
            <p>
              VPN service coverage, speed, server location and quality may vary.
              HyperlinkVPN will always try to make the service available.
              However, the service may be unavailable due to various factors
              beyond our control. We are not responsible for the loss,
              non-delivery, delay or misleading of data, messages or pages
              caused by interruption or performance problems of the service,
              communication service or network . We may at our discretion impose
              restrictions on usage or service, suspend service, terminate VPN
              accounts, or block certain types of use to protect subscribers or
              services. The accuracy and timeliness of the data received is not
              guaranteed; delays or omissions may occur.
            </p>
            <p>
              HyperlinkVPN reserves the right to investigate matters that we
              believe violate these terms. We can, but are not obligated, to
              delete, block, filter or restrict in any way any material or
              information that we believe to actually or may violate the
              restrictions stipulated in these terms and any other materials or
              information that may cause HyperlinkVPN without notice Or
              activities that our customers are responsible for. HyperlinkVPN
              does not assume any and all responsibilities for our failure to
              prevent the transmission of such materials or information through
              the service and/or transmission to your computing device.
            </p>
          </li>

          <li>
            <h2>7. Limitation of Liability</h2>
            <p>
              For any loss or damage you suffer, HyperlinkVPN does not assume
              any form of responsibility to any subscribers or other
              individuals. In any case, HyperlinkVPN, members, managers or
              employees shall not be liable for any direct, special, indirect,
              consequential or incidental damages or any other types of loss or
              damage, even if they have been informed of the possibility. The
              above regulations do not apply to the scope prohibited by
              applicable laws.
            </p>
          </li>
          <li>
            <h2>8. Applicable law</h2>
            <p>
              This agreement shall be governed by and interpreted in accordance
              with the laws of the Singapore, excluding its governing conflict
              of laws rules.
            </p>
          </li>
          <li>
            <h2>9. Final clauses</h2>
            <p>
              If any clause in this agreement is found to be invalid or
              unenforceable, the clause shall be interpreted in a manner
              consistent with applicable laws to reflect the original intent of
              the clause, and the remaining clauses of this agreement shall
              still be fully valid. Failure to exercise or enforce any rights or
              provisions of this agreement does not constitute a waiver of such
              rights or provisions.
            </p>
          </li>
          <li>
            <h2>10. Contact us</h2>
            <p>
              If you have any questions about our privacy policy and how we
              handle your information, please feel free to contact us via
              Hyperlink's official email address.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style>
</style>