<template>
  <div class="select_hyperlink">
    <div class="centerWidth">
      <h1 class="app_h1">{{ $t("lang.selectHyperlink.whyChoose") }}</h1>
      <div class="hyperlink_benefit">
        <ul class="row">
          <li class="col-md-4">
            <div
              class="card"
              :class="$i18n.locale == 'zh-CN' ? '' : 'card_en'"
              style="width: 18rem"
            >
              <div>
                <img
                  src="../../../assets/image/index/Ad-1.png"
                  class="card-img-top"
                />
              </div>
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("lang.selectHyperlink.Choose_tit1") }}
                </h5>
                <p class="card-text">
                  {{ $t("lang.selectHyperlink.Choose_text1") }}
                </p>
              </div>
            </div>
          </li>
          <li class="col-md-4">
            <div
              class="card"
              :class="$i18n.locale == 'zh-CN' ? '' : 'card_en'"
              style="width: 18rem"
            >
              <img
                src="../../../assets/image/index/Ad-2.png"
                class="card-img-top"
              />
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("lang.selectHyperlink.Choose_tit2") }}
                </h5>
                <p class="card-text">
                  {{ $t("lang.selectHyperlink.Choose_text2") }}
                </p>
              </div>
            </div>
          </li>
          <li class="col-md-4">
            <div
              class="card"
              :class="$i18n.locale == 'zh-CN' ? '' : 'card_en'"
              style="width: 18rem"
            >
              <img
                src="../../../assets/image/index/Ad-3.png"
                class="card-img-top"
              />
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("lang.selectHyperlink.Choose_tit3") }}
                </h5>
                <p class="card-text">
                  {{ $t("lang.selectHyperlink.Choose_text3") }}
                </p>
              </div>
            </div>
          </li>
          <li class="col-md-4">
            <div
              class="card"
              :class="$i18n.locale == 'zh-CN' ? '' : 'card_en'"
              style="width: 18rem"
            >
              <img
                src="../../../assets/image/index/Ad-4.png"
                class="card-img-top"
              />
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("lang.selectHyperlink.Choose_tit4") }}
                </h5>
                <p class="card-text">
                  {{ $t("lang.selectHyperlink.Choose_text4") }}
                </p>
              </div>
            </div>
          </li>
          <li class="col-md-4">
            <div
              class="card"
              :class="$i18n.locale == 'zh-CN' ? '' : 'card_en'"
              style="width: 18rem"
            >
              <img
                src="../../../assets/image/index/Ad-5.png"
                class="card-img-top"
              />
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("lang.selectHyperlink.Choose_tit5") }}
                </h5>
                <p class="card-text">
                  {{ $t("lang.selectHyperlink.Choose_text5") }}
                </p>
              </div>
            </div>
          </li>
          <li class="col-md-4">
            <div
              class="card"
              :class="$i18n.locale == 'zh-CN' ? '' : 'card_en'"
              style="width: 18rem"
            >
              <img
                src="../../../assets/image/index/Ad-6.png"
                class="card-img-top"
              />
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("lang.selectHyperlink.Choose_tit6") }}
                </h5>
                <p class="card-text">
                  {{ $t("lang.selectHyperlink.Choose_text6") }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.select_hyperlink {
  margin-top: 100px;

  h1 {
    font-size: 40px;
    text-align: center;
  }

  .hyperlink_benefit {
    margin-top: 96px;

    .row {
      padding: 0;
    }

    ul {
      li {
        box-sizing: border-box;
        border-radius: 10px;
        .card {
          width: 100% !important;
          margin: 22px 0;
          // height: 598px;
          height: 534px;
          border-radius: 30px;
          overflow: hidden;

          img {
            border-bottom: 1px solid #ccc;
          }

          .card-body {
            margin-top: 20px;
            padding: 0 30px;

            .card-title {
              text-align: center;
              font-size: 26px;
              font-weight: bold;
              margin-bottom: 30px;
            }

            .card-text {
              font-size: 16px;
            }
          }
        }
        .card_en {
          height: 560px;
        }
      }
    }
  }
}
</style>
