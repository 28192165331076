<template>
  <div class="downloadCourseThirdly">
    <!-- <div class="centerWidth"> -->
    <div class="down_warning">
      <h1>
        <span class="red">註意！ 防止“手機變磚”!</span> 千萬別在 iCloud
        頁面和設置頁面登錄!
      </h1>
      <div class="centerWidth">
        <p class="warning_p">
          <span class="red center">
            <img
              src="../../assets/image/downloadCourseThirdly/warning.jpg"
              alt=""
            />
            注意！！！</span
          >
          <span class="warning_tit"
            >千萬別在 iCloud 頁面和設置頁面登錄! 這會導致您的 App Store 和
            iTunes Store被停用 或 鎖定，最嚴重會導致
            <span class="red">“手機會變磚”</span> ；</span
          >
        </p>
        <p class="warning_p">
          <span class="red center">
            <img
              src="../../assets/image/downloadCourseThirdly/warning.jpg"
              alt=""
            />注意！！！</span
          >

          <span class="warning_tit">
            若提示需要 <span class="red">“電話號碼驗證”</span> ，<span
              class="red"
              >代表您操作錯誤</span
            >
            ，請仔細查看教程的第三 步與第四步
          </span>
        </p>
        <div class="course_pic">
          <img
            src="../../assets/image/downloadCourseThirdly/03-01.png"
            alt=""
          />
          <img
            src="../../assets/image/downloadCourseThirdly/03-02.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="htd_tit centerWidth">
      <h1>使用公開賬號下載hyperlinkVPN</h1>
      <!-- <p>
        僅需 1 分鐘，將您的 Apple ID 切換到香港地區下載hyperlinkVPN，
        <span class="red"
          >請註意，若您的賬號中存在訂閱內容，請您選擇其他方式！</span
        >
      </p> -->
    </div>
    <div class="course_list">
      <ul>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">01</span>打開 App Store ，點擊
              <span class="red"> “右上角圖標”</span>；
              <span class="left_pad">
                千萬別在 iCloud 頁面和設置頁面登錄！ 否則
                <span class="red"> “手機會變磚”</span>；
              </span>
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseThirdly/03-03.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">02</span>

              <span class="tit"
                >選擇
                <span class="red">“退出登錄”</span>
                按鈕，記得一定要滑到頁面底部才能看到這個按鈕。</span
              >
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseThirdly/03-04.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">03</span>

              <span class="tit"
                >登錄ID後，會彈出Apple ID安全頁面，切記請點擊
                <span class="red">“其他選項”</span>
                ，若沒有此提示請忽略；</span
              >
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseThirdly/03-05.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">04</span>

              <span class="tit"
                >在彈出的彈窗中選擇 <span class="red">“不升級”</span>。
                ，若沒有此提示請忽略；</span
              >
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseThirdly/03-06.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">05</span>

              <span class="tit"
                >使用下方的 “公共香港 Apple ID”
                <span class="red">按照前面的四個步驟進行登錄</span> ，
                註意密碼大小寫， 建議直接復製粘貼；
              </span>
            </h4>
            <p class="red clear_red">
              (若公共 Apple ID 不可用，請清除瀏覽器緩存後重新刷新頁面獲取!)
            </p>
            <div class="account_number">
              <div class="appleId">
                <p class="id_tit">公共 Apple ID：</p>
                <p class="account_p">
                  <span class="id">haxq57382@163.com</span>
                  <!-- 復製 -->
                  <span class="copy"></span>
                </p>
              </div>
              <div class="appleId">
                <p class="id_tit">密碼：</p>
                <p class="account_p">
                  <span class="id">Qh112211</span>
                  <span class="copy"></span>
                </p>
              </div>
            </div>
            <p class="red clear_red">
              註意：如果顯示為空或賬號無法使用，請清除緩存重新刷新頁面
            </p>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseThirdly/03-07.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num">06</span>

              <span class="tit"
                >當您發現商店內容字體變為<span class="red">繁體字</span
                >後證明您已經切換到香港地區了。此時您可以點擊下 方按鈕直接下載
                <span class="red">hyperlinkVPN</span> ，或在商店中自行搜索<span
                  class="red"
                  >hyperlinkVPN</span
                >
                進行下載。
              </span>
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseThirdly/03-08.png"
                alt=""
              />
            </div>
          </div>
        </li>
        <li>
          <div class="centerWidth">
            <h4>
              <span class="num center">最后一步：</span>
              <span class="tit last_tit">
                下載完成後，在 App Store 中點擊
                <span class="red"> “右上角圖標”</span> 然後
                <span class="red">“滑倒底部”</span>
                登出 賬號；登出公共 ID
                後，登錄回自己的蘋果ID，商店內容即可變回簡體內 容。
                <span class="red">hyperlinkVPN</span>進行下載。
              </span>
            </h4>
            <div class="course_pic">
              <img
                src="../../assets/image/downloadCourseThirdly/03-09.png"
                alt=""
              />
              <img
                src="../../assets/image/downloadCourseThirdly/03-10.png"
                alt=""
              />
            </div>
          </div>
        </li>
      </ul>
      <div class="down_foot centerWidth">
        <p class="blue">
          如果您在下載過程中有遇到任何問題，請您隨時聯系在線客服，我們會有專業的客服團隊幫助您解決任何問題。
        </p>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.downloadCourseThirdly {
  .red {
    color: rgb(245, 75, 75);
  }

  .down_warning {
    background: rgba(255, 249, 199, 0.1);
    padding-bottom: 150px;
    h1 {
      font-size: 40px;
      text-align: center;
      background: rgb(255, 249, 199);
      padding: 40px 0;
      font-weight: bold;
    }
    .warning_p {
      font-size: 30px;
      margin: 100px 0;
      .red {
        font-weight: bold;
      }
      img {
        width: 35px;
      }
      .warning_tit {
        display: inline-block;
        width: 80%;
        vertical-align: top;
      }
    }
    .course_pic {
      text-align: center;
      img {
        width: 388px;
      }
    }
  }
  .htd_tit {
    text-align: center;
    h1 {
      font-size: 50px;
      margin: 80px 0 38px;
    }
    p {
      font-size: 20px;
      color: rgb(139, 139, 139);
      padding: 0 30px;
      text-align: left;
    }
  }
  .course_list {
    margin-top: 155px;
    ul {
      padding: 0;
      li {
        padding: 40px 20px;
        h4 {
          font-size: 28px;
          font-weight: 500;
          .num {
            color: rgb(245, 75, 75);
            font-style: italic;
            font-size: 50px;
            font-weight: bold;
            margin-right: 10px;
          }
          .last_tit {
            display: inline-block;
            width: 78%;
            vertical-align: top;
          }
        }
        .clear_red {
          font-size: 30px;
          margin: 48px 0;
        }
        .annotation_p {
          font-size: 20px;
          color: #000;
        }
        .annotation_p::before {
          content: "";
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #000;
          display: inline-block;
          margin-right: 5px;
        }
        .annotation_last_p {
          font-size: 20px;
          color: #000;
        }
        .last_btn {
          text-align: center;
          margin-top: 70px;
          button {
            color: #fff;
            padding: 15px 54px;
            font-size: 26px;
            background: rgba(247, 164, 3, 1);
          }
        }
        .course_pic {
          text-align: center;
          padding: 115px 0;
          img {
            width: 360px;
            vertical-align: top;
            margin-right: 60px;
          }
        }
        .account_number {
          .appleId {
            font-size: 36px;
            width: 60%;
            padding: 40px;
            background: rgb(251, 252, 253);
            margin-bottom: 20px;
            border-radius: 30px;
            line-height: 60px;
            .id_tit {
              color: rgb(139, 139, 139);
            }
            .account_p {
              display: flex;
              justify-content: space-between;
              .id {
              }
              .copy {
                color: rgb(58, 161, 255);
                font-weight: bold;
              }
            }
          }
        }
      }
      li:nth-of-type(even) {
        background: rgba(251, 252, 253, 1);
      }
    }
    .down_foot {
      text-align: center;
      font-size: 30px;
      line-height: 40px;
      .hint_p {
      }
      .blue {
        color: rgb(58, 161, 255);
        font-weight: bold;
        margin-top: 30px;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .center {
    display: block;
    text-align: center;
    margin: 20px 0 0;
  }
  .downloadCourseThirdly {
    .down_warning {
      padding-bottom: 40px;
      h1 {
        font-size: 12px;
        padding: 20px 0;
      }
      .warning_p {
        font-size: 30px;
        margin: 25px 0;
        padding: 0 38px;
        .red {
          font-weight: bold;
        }
        .center {
          font-size: 20px;
        }
        img {
          width: 20px;
        }
        .warning_tit {
          font-size: 10px;
          width: 100%;
        }
      }
      .course_pic {
        text-align: center;
        padding: 0 20px;
        img {
          width: 50%;
        }
      }
    }
    .htd_tit {
      h1 {
        font-weight: bold;
        font-size: 20px;
        margin: 40px 0 11px;
      }
      p {
        font-size: 12px;
      }
    }
    .course_list {
      margin-top: 40px;
      ul {
        li {
          h4 {
            font-size: 14px;
            line-height: 26px;
            font-weight: bold;
            margin-bottom: 31px;
            .num {
              font-size: 24px;
            }
            .tit {
              display: inline-block;
              width: 85%;
              vertical-align: top;
              padding-left: 10px;
            }
            a {
              margin-left: 40px;
              color: rgb(58, 161, 255);
            }
            .left_pad {
              margin-left: 40px;
              display: inline-block;
            }
          }
          .clear_red {
            font-size: 12px;
            margin: 17px 0;
            font-weight: bold;
          }
          .account_number {
            .appleId {
              width: 100%;
              padding: 20px;
              border-radius: 12px;
              line-height: 18px;
              .id_tit,
              .account_p {
                font-size: 13px;
              }
            }
          }
          .annotation_p {
            font-size: 12px;
            padding: 0 10px;
            margin-bottom: 10px;
            span {
              display: inline-block;
              width: 90%;
              vertical-align: top;
              padding-left: 5px;
            }
          }
          .annotation_last_p {
            font-size: 12px;
            padding: 0px 23px;
          }
          .last_btn {
            margin-top: 40px;
            button {
              font-size: 14px;
              padding: 10px 30px;
            }
          }
          .annotation_p::before {
            width: 5px;
            height: 5px;
          }
          .course_pic {
            padding: 22px 0;

            img {
              width: 80%;
              margin: 0;
            }
          }
          .course_pic:nth-child(2) img {
            margin-top: 20px;
          }
        }
      }
      .down_foot {
        font-size: 12px;
        line-height: 20px;
        padding: 0 30px;
        .blue {
          color: rgb(58, 161, 255);
          font-weight: bold;
          margin-top: 0;
        }
      }
    }
  }
}
</style>