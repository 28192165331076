<template>
  <div>
    <!-- 中文服务隐私 -->
    <IndexZH v-if="$i18n.locale == 'zh-CN'" />
    <!-- 英文服务隐私 -->
    <IndexEN v-else />
  </div>
</template>
  
  <script>
import IndexZH from "./privacyLang/privacyZH.vue";
import IndexEN from "./privacyLang/privacyEN.vue";
export default { components: { IndexZH, IndexEN } };
</script>
  
  <style lang="less">
@import url("@/assets/css/clauseComm.less");
</style>