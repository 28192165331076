import request from "@/utils/request"
let headers = {
    'language': localStorage.getItem("lang") || 'zh-CN'
}
export function versionNew() {
    return request({
        method: 'post',
        url: '/api/version/new',
        headers: {
            'Authorization': sessionStorage.getItem("token"),
            ...headers
        },

    })
}
export function deviceList() {
    return request({
        method: 'post',
        url: '/api/device/list',
        headers: {
            'Authorization': sessionStorage.getItem("token"),
            ...headers
        },

    })
}
export function getinituser() {
    return request({
        method: 'post',
        url: '/api/pay/init',
        headers: {
            'Authorization': sessionStorage.getItem("token"),
            ...headers

        },

    })
}

export function offline(params = {}) {
    return request({
        method: 'post',
        url: '/api/device/offline',
        data: params,
        headers: {
            'Authorization': sessionStorage.getItem("token"),
            ...headers
        },

    })
}
export function reSendEmail(params = {}) {
    return request({
        method: 'post',
        url: '/api/user/reSendEmail',
        data: params,
        headers: {
            'Authorization': sessionStorage.getItem("token"),
            ...headers
        },

    })
}
export function verifyEmail(params = {}) {
    return request({
        method: 'post',
        url: '/api/user/verifyEmail',
        data: params,
        headers: {
            'Authorization': sessionStorage.getItem("token"),
            ...headers
        },

    })
}
export function createOrder(params = {}) {
    return request({
        method: 'post',
        url: '/api/order/create',
        data: params,
        headers: {
            'Authorization': sessionStorage.getItem("token"),
            ...headers
        },

    })
}
export function paypalreturn(params = {}) {
    // iostokenChange()
    return request({
        method: 'post',
        url: '/api/pay/paypal/return',
        data: params,
        headers: {
            ...headers
        }

    })
}
export function checkreturn(params = {}) {
    // iostokenChange()
    return request({
        method: 'post',
        url: '/api/check/return',
        data: params,
        headers: {
            ...headers
        }

    })
}
export function checkcancel(params = {}) {
    return request({
        method: 'post',
        url: '/api/check/cancel',
        data: params,
        headers: {
            ...headers
        }

    })
}
export function paycancel(params = {}) {
    return request({
        method: 'post',
        url: '/api/pay/cancel',
        data: params,
        headers: {
            ...headers
        }

    })
}
export function getshare(params = {}) {
    return request({
        method: 'post',
        url: '/api/user/receive/share',
        data: params,
        headers: {
            ...headers
        }

    })
}
export function checkCoupon(params = {}) {
    return request({
        method: 'post',
        url: '/api/check/coupon',
        data: params,
        headers: {
            ...headers
        }

    })
}
export function getcount(params = {}) {
    return request({
        method: 'post',
        url: '/api/user/coupon/count',
        data: params,
        headers: {
            ...headers
        }

    })
}
export function getcountin(params = {}) {
    return request({
        method: 'post',
        url: '/api/user/coupon/count/in',
        data: params,
        headers: {
            ...headers
        }

    })
}
