<template>
  <div class="footer">
    <div class="centerWidth">
      <div class="row foot_row">
        <div class="col-md-4">
          <div class="card foot_card">
            <div class="card-body foot_card_body">
              <ul>
                <li>
                  <h3 class="about">{{ $t("lang.footerBar.About_Us") }}</h3>
                </li>
                <li>
                  <router-link
                    :to="'/privacyPolicy?lang=' + $i18n.locale"
                    target="_blank"
                    >{{ $t("lang.footerBar.Privacy_Policy") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="'/serviceTerms?lang=' + $i18n.locale"
                    target="_blank"
                    >{{ $t("lang.footerBar.Terms") }}</router-link
                  >
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100081286898220"
                    target="_blank"
                    >{{ $t("lang.footerBar.Facebook") }}</a
                  >
                </li>
                <li>
                  <a href="https://twitter.com/HyperlinkVPN" target="_blank">{{
                    $t("lang.footerBar.Twitter")
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card foot_card">
            <div class="card-body foot_card_body">
              <ul>
                <li>
                  <h3 class="about">{{ $t("lang.footerBar.download") }}</h3>
                </li>

                <li @click="navBar('MacDownload')">
                  <a href="/#MacDownload">Windows</a>
                </li>
                <li @click="navBar('MacDownload')">
                  <a href="/#MacDownload">Mac</a>
                </li>
                <li @click="navBar('MacDownload')">
                  <a href="/#MacDownload">iOS</a>
                </li>
                <li @click="navBar('MacDownload')">
                  <a href="/#MacDownload">Android</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 app_help">
          <div class="card foot_card">
            <div class="card-body foot_card_body">
              <ul>
                <li>
                  <h3 class="about">{{ $t("lang.footerBar.help") }}</h3>
                </li>

                <li>
                  <a href="javascript:;">support@hyperlink.com</a>
                </li>
                <li>
                  <a href="https://t.me/HyperlinkVPN_group" target="_blank"
                    >Telegaram</a
                  >
                </li>
                <!-- <li>
                  <h3 class="about">{{ $t("lang.footerBar.Payment") }}</h3>
                </li>
                <li>
                  <a href="javascript:;">
                    <img src="../../assets/image/foot-Payment.png" alt="" />
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p class="fot_vpn">{{ $t("lang.footerBar.footer") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tokenFlag: true,
    };
  },
  methods: {
    navBar(url) {
      this.$router.push("/#" + url);
    },
  },
};
</script>

<style lang="less">
.footer {
  background: rgb(30, 33, 36);
  .foot_row {
    .foot_card {
      background: transparent;
      border: 0;
      .foot_card_body {
        padding: 150px 0;
        ul {
          padding-left: 0;
          .about {
            color: #fff;
            font-size: 30px;
          }
          li {
            line-height: 50px;
            a {
              color: rgb(139, 139, 139);
              text-decoration: none;
              img {
                width: 350px;
              }
            }
          }
        }
      }
    }
  }
  .fot_vpn {
    text-align: center;
    color: rgb(136, 136, 136);
    padding-bottom: 24px;
    margin: 0;
  }
}
// 移动端--start
@media screen and (max-width: 820px) {
  .footer .foot_row .foot_card .foot_card_body {
    padding: 35px 0;
  }
  .footer .foot_row .foot_card .foot_card_body ul li a img {
    width: 300px;
  }
  .footer {
    .foot_row {
      .col-md-4 {
        width: 50%;
      }
      .app_help {
        width: 100%;
      }
    }
  }
  .footer .foot_row .foot_card .foot_card_body ul .about {
    font-size: 16px;
  }
  .footer .foot_row .foot_card .foot_card_body ul li a {
    font-size: 14px;
  }
  .footer .foot_row .foot_card .foot_card_body ul {
    padding-left: 2rem;
  }
  .footer .foot_row .foot_card .foot_card_body ul li {
    line-height: 30px;
  }
}
</style>
