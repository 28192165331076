<template>
  <div class="purchaseHistory">
    <LeftMember :userData="userData" />
    <div class="right_purchaseHistory">
      <h3>{{ $t("lang.purchaseHistory.Purchase") }}</h3>
      <div class="purch_box">
        <ul>
          <li v-for="(item, i) in order" :key="i">
            <h5>{{ item.name }}</h5>
            <p>
              {{ $t("lang.purchaseHistory.Purchase_Time") }}{{ item.dateTime }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMember from "@/components/LeftMember.vue";

export default {
  components: { LeftMember },
  data() {
    return {
      userData: JSON.parse(sessionStorage.getItem("userData")),
      order: [],
    };
  },
  methods: {
    getorder() {
      this.$api.deviceList().then((res) => {
        this.order = res.data.data.order;
      });
    },
  },
  mounted() {
    this.getorder();
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
    }
  },
};
</script>

<style lang="less" scoped>
.purchaseHistory {
  height: calc(100% - 80px);
  border-top: 1px solid #ccc;
  .right_purchaseHistory {
    width: calc(100% - 354px);
    display: inline-block;
    vertical-align: top;
    padding: 0 48px;
    position: relative;
    left: 354px;
    h3 {
      padding: 52px 0 30px 0;
      font-weight: bold;
    }
    .purch_box {
      background: rgba(250, 250, 253, 1);
      padding: 28px 30px 18px;
      ul {
        padding: 0;
        li {
          margin: 20px 0;
          border-bottom: 1px solid #ccc;
          h5 {
            font-size: 24px;
            color: #000;
            position: relative;
            padding-left: 7px;
            margin-bottom: 15px;
          }
          h5::before {
            content: "";
            position: absolute;
            left: 0;
            top: 3px;
            width: 5px;
            height: 20px;
            background: rgb(247, 164, 3);
          }
          p {
            font-size: 16px;
            margin-bottom: 20px;
          }
        }
        :last-child {
          border: 0;
        }
      }
    }
  }
}
//移动端
@media screen and(max-width: 820px) {
  .purchaseHistory {
    .right_purchaseHistory {
      width: 100%;
      padding: 0;
      left: 0;
      h3 {
        padding: 20px 18px 0;
        font-weight: bold;
      }
      .purch_box {
        padding: 0;
        margin: 20px 15px;
        border-radius: 5px;
        ul {
          //   background: #fff;
          padding: 10px 24px;
          li {
            h5 {
              font-size: 18px;
            }
            p{
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
