<template>
  <div class="terms">
    <div class="hed_tit">
      <div class="centerWidth">
        <h1>Privacy Policy</h1>
      </div>
    </div>
    <div class="terms_list">
      <div class="centerWidth">
        <ul>
          <li>
            <p>
              We want you to understand what information we collect, what
              information we don't collect, and how we collect, use and store
              information. We do not collect your activity logs, including
              browsing history, traffic destinations, data content or DNS
              queries. We also never store connection logs, which means that
              your IP address, your outgoing VPN IP address, connection
              timestamp, or session duration will not be recorded.
            </p>
            <p>
              Our guiding principle for data collection is to collect only the
              minimum data required for operations. The system we designed does
              not contain sensitive data about our customers; even under forced
              circumstances, we cannot provide data that we do not own. This
              privacy policy will help you understand how HyperlinkVPN collects,
              uses and stores information.
            </p>
          </li>
          <li>
            <h2>1. General information</h2>
            <p>HyperlinkVPN collects three types of information:</p>
            <p>
              1. Personal information related to your account. This information
              is collected to manage your HyperlinkVPN subscription.
            </p>
            <p>
              2. Aggregate application and VPN connection summary statistics.
              HyperlinkVPN collects minimal information about usage to maintain
              excellent customer support and service quality.
            </p>
            <p>
              3. Anonymous application diagnosis includes crash reports.
              Application diagnostic data, including crash reports, availability
              diagnostics, and VPN connection diagnostics, are anonymous and
              cannot be associated with a single HyperlinkVPN user. This feature
              is similar to the "Send Error Report" option. Users can specify
              whether to send these data to us in the setting menu of any
              HyperlinkVPN App.
            </p>
          </li>

          <li>
            <h2>2. Personal Information</h2>
            <p>
              HyperlinkVPN collects the personal information you provide to us
              directly through registration. We ask you to provide personal
              information, such as an email address, in order to establish a
              HyperlinkVPN account so that we can send you emails and respond to
              support inquiries you initiate.
            </p>
            <p>
              HyperlinkVPN uses your email address for the following reasons:
            </p>
            <ul class="terms_ul">
              <li>
                Provide a link to our website, including password reset emails.
              </li>
              <li>Send you updates and announcements.</li>
              <li>
                Communicate with you about your VPN service or respond to your
                communication.
              </li>
              <li>
                To send marketing information, you can choose not to receive
                marketing emails.
              </li>
            </ul>
            <p>
              HyperlinkVPN only uses your personal information for the purposes
              listed in this privacy policy, and we will not sell your personal
              information to third parties.
            </p>
          </li>

          <li>
            <h2>
              3. Aggregate application and VPN connection summary statistics
            </h2>
            <p>
              We ensure that browsing history, traffic destinations, data
              content, IP addresses or DNS queries are never recorded. and so:
            </p>
            <ul class="terms_ul">
              <li>
                We do not know which user has ever visited a particular website
                or service.
              </li>
              <li>
                We don't know which user connected to the VPN at a specific time
                or which VPN server IP address they used.
              </li>
              <li>
                We do not know the original IP address set of the user's
                computer.
              </li>
            </ul>
            <p>
              If someone tries to force HyperlinkVPN to release user information
              based on any of the above, we will not be able to provide this
              information because the data does not exist.
            </p>
            <p>
              In order to maintain excellent customer support and service
              quality, HyperlinkVPN collects the following information related
              to your VPN usage.
            </p>
          </li>

          <li>
            <h2>4. Application and application version</h2>
            <p>
              We collect information about the apps and app versions you have
              activated. Knowing your current application version allows our
              support team to work with you to resolve technical issues.
            </p>
          </li>

          <li>
            <h2>5. Successfully connected</h2>
            <p>
              We collect information about whether you successfully established
              a VPN connection on a specific date. This minimal information can
              help us provide technical support, such as identifying connection
              problems, providing country-specific recommendations on how to
              best use our services, and making HyperlinkVPN engineers can
              identify and repair network problems.
            </p>
          </li>
          <li>
            <h2>6. Total amount of data transferred (in MB)</h2>
            <p>
              We collect information about the total amount of data transmitted
              by users. Although we provide unlimited data transmission, if we
              find that the traffic pushed by a single user exceeds the sum of
              hundreds of other users, thereby affecting the quality of service
              of other HyperlinkVPN users, we may contact the user for
              explanation.
            </p>
          </li>

          <li>
            <h2>
              7. Anonymous application diagnosis, including crash reports (can
              be closed by the user)
            </h2>
            <p>
              With your permission, we will collect anonymous application
              diagnostic data, including crash reports, availability
              diagnostics, and VPN connection diagnostics. We use this data in
              our network operation tools to help optimize network speed and
              allow us to identify issues and areas for improvement related to
              specific applications, VPN servers or ISPs. The information we
              receive is completely anonymous. We do not store which data is
              sent by which user, nor do we store the user's IP address.
            </p>
            <p>
              If you choose to share this information with HyperlinkVPN, we will
              collect the following anonymous information:
            </p>
            <ul class="terms_ul">
              <li>
                Diagnostic information about whether and how the VPN connection
                attempt failed.
              </li>
              <li>Speed test data.</li>
              <li>
                Application diagnostics, including crash reports and
                availability diagnostics. These are processed anonymously by
                these third parties.
              </li>
            </ul>
          </li>
          <li>
            <h2>8. Jurisdiction and applicable law</h2>
            <p>
              The core mission of HyperlinkVPN is to protect your information
              privacy. HyperlinkVPN's headquarters and registered place of
              business are located in the Singapore.
            </p>
          </li>
          <li>
            <h2>9. Store information related to emails and feedback</h2>
            <p>
              HyperlinkVPN will record any complaints, questions, suggestions,
              etc. that you submit to us through our service. Depending on how
              you contact HyperlinkVPN, we may collect your email address and
              any other information you provide to us. Having a complete
              communication record enables our employees to provide the best
              customer support experience.
            </p>
          </li>
          <li>
            <h2>10. Security measures to protect your information</h2>
            <p>
              HyperlinkVPN uses advanced procedures and technologies and other
              security measures to prevent any information loss, misuse,
              unauthorized access, disclosure or modification. But we also need
              to understand that no data security measures in the world can
              provide 100% protection, but we will do our best to ensure the
              security of user privacy.
            </p>
          </li>
          <li>
            <h2>11.Cookies and mobile identifiers</h2>
            <p>
              A cookie is a small text file used to store information about your
              visit to this website. Cookies help us provide certain functions,
              such as website login and language settings, so that HyperlinkVPN
              can optimize and improve the user experience of the website. As we
              continue to update and improve our website, the cookies we use may
              change over time.
            </p>
            <p>
              A mobile identifier is an identifier provided by an Android or iOS
              device. It does not contain your name or email address.
              HyperlinkVPN uses mobile identifiers to generate statistical data
              related to marketing channels and advertising partners, through
              which users learn about and register for the HyperlinkVPN mobile
              application.
            </p>
          </li>
          <li>
            <h2>12.Consent and age restrictions</h2>
            <p>
              By using this application, software or service, you agree to the
              processing of your information in accordance with the instructions
              in our terms of service and privacy policy.
            </p>
            <p>
              Services are for adults 18 years and older. If you think your
              child has provided us with information, please notify us
              immediately.
            </p>
          </li>
          <li>
            <h2>13.Changes to the Privacy Policy</h2>
            <p>
              In accordance with applicable privacy laws and principles, we may
              change our privacy policy from time to time without notice to you.
              Your continued use of this website or service means that you
              accept our privacy policy.
            </p>
          </li>
          <li>
            <h2>14.How to contact HyperlinkVPN</h2>
            <p>
              If you have any questions about our privacy policy and how we
              handle your information, please feel free to call the HyperlinkVPN
              official website email address.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style>
</style>