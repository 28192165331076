var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"header",staticClass:"header",attrs:{"id":"header"}},[_c('div',{class:_vm.scroll_height == 0 ? '' : 'fixed_header'},[_c('div',{staticClass:"centerWidth",class:_vm.$route.path == '/memberCenter' ||
        _vm.$route.path == '/equipment' ||
        _vm.$route.path == '/purchaseHistory' ||
        _vm.$route.path == '/terminalRevenue'
          ? 'fullScreen'
          : ''},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-light head_con"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),(_vm.$route.path == '/memberCenter')?_c('a',{staticClass:"download_app",attrs:{"href":"#/MacDownload"},on:{"click":function($event){return _vm.navBar('MacDownload', 'download')}}},[_c('img',{attrs:{"src":require("@/assets/image/memberCenter/download.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("lang.Downloading_APP")))]):_vm._e(),_c('button',{ref:"toggler",staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarText","aria-controls":"navbarText","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('img',{attrs:{"src":require("@/assets/image/index/nav.png"),"alt":""}})]),_c('div',{ref:"toggle",staticClass:"collapse navbar-collapse flexgrow nav_bar",attrs:{"id":"navbarText"}},[(
                _vm.$route.path != '/memberCenter' &&
                _vm.$route.path != '/equipment' &&
                _vm.$route.path != '/purchaseHistory' &&
                _vm.$route.path != '/terminalRevenue'
              )?_c('ul',{staticClass:"navbar-nav me-auto mb-2 mb-lg-0"},[_c('li',{staticClass:"nav-item",on:{"click":_vm.navBar}},[_c('a',{staticClass:"nav-link",class:_vm.scroll_height <= 490 && _vm.$route.path == '/' ? 'active' : '',attrs:{"aria-current":"page","href":"/"}},[_vm._v(_vm._s(_vm.$t("lang.navBar.Home")))])]),_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.navBar('select-hyperlink')}}},[_c('a',{staticClass:"nav-link",class:_vm.scroll_height >= 490 &&
                    _vm.scroll_height < 1914 &&
                    _vm.$route.path == '/'
                      ? 'active'
                      : '',attrs:{"href":"/#select-hyperlink"}},[_vm._v(_vm._s(_vm.$t("lang.navBar.Advantage")))])]),_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.navBar('MacDownload')}}},[_c('a',{staticClass:"nav-link",class:_vm.$route.name.includes('download') ||
                    _vm.$route.name.includes('commonProblem') ||
                    (_vm.scroll_height >= 1914 && _vm.scroll_height < 2610)
                      ? 'active'
                      : '',attrs:{"href":"/#MacDownload"}},[_vm._v(_vm._s(_vm.$t("lang.navBar.Download")))])]),_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.navBar('UserEvaluation')}}},[_c('a',{staticClass:"nav-link",class:_vm.scroll_height >= 2610 && _vm.$route.path == '/'
                      ? 'active'
                      : '',attrs:{"href":"/#UserEvaluation"}},[_vm._v(_vm._s(_vm.$t("lang.navBar.Evaluate")))])])]):_vm._e()]),_c('LangueSel',{attrs:{"scrollHeight":_vm.scroll_height}}),(
              _vm.$route.path != '/memberCenter' &&
              _vm.$route.path != '/equipment' &&
              _vm.$route.path != '/purchaseHistory' &&
              _vm.$route.path != '/terminalRevenue'
            )?_c('div',{staticClass:"login"},[(!_vm.tokenFlag)?_c('router-link',{attrs:{"to":"/login"}},[_vm._v(_vm._s(_vm.$t("lang.login.Sign_in")))]):_vm._e(),(_vm.tokenFlag)?_c('router-link',{staticClass:"member",attrs:{"to":"/memberCenter"}},[_c('img',{attrs:{"src":require("../../assets/image/icon-head.png"),"alt":""}})]):_vm._e()],1):_vm._e()],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../../assets/image/top-logo.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }